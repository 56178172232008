export default function getExtension(path: string) {
  // extract file name from full path ... (supports `\\` and `/` separators)
  const basename = path.split(/[\\/]/).pop() || '';

  // get last position of `.`
  const pos = basename.lastIndexOf('.');

  if (basename === '' || pos < 1)
    // if file name is empty or ...
    return ''; //  `.` not found (-1) or comes first (0)

  return basename.slice(pos + 1); // extract extension ignoring `.`
}
