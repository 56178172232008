export const getCurrentPathWithQueryString = (noTrimAuthPath?: boolean) => {
  // FIXME(AC-1130): This is a temporary fix for the issue where the auth path is not trimmed from the URL.
  const currentHref = location.href || '';
  const replacedOrigin = currentHref.replace(location.origin, '');
  if (noTrimAuthPath) {
    return replacedOrigin;
  }
  return `${replacedOrigin.replace(/^\/auth\/sign(in|out)\?next=/, '')}`;
};

export const getNextPath = (nextPathQuery = '?next=') => {
  const currentPath = getCurrentPathWithQueryString(true).replace(location.pathname, '');
  if (
    !currentPath.includes(nextPathQuery) ||
    (currentPath.includes('http') && !currentPath.includes(location.origin))
  ) {
    return '/';
  }
  return `${currentPath.replace(nextPathQuery, '')}`;
};
