import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { TicketsActions } from '@desk/redux/actions/tickets';

import { DeskActions } from '../actions/desk';

const defaultRefreshItem = {
  label: '10 Seconds',
  seconds: 10,
};

interface TicketsState {
  refresh: {
    isAutomatic: boolean;
    automaticItem: {
      label: string;
      seconds: number;
    };
  };
}

const initialState: TicketsState = {
  refresh: {
    isAutomatic: false,
    automaticItem: defaultRefreshItem,
  },
};

export const ticketsReducer = createReducer<TicketsState>(initialState, (builder) => {
  // TicketsActions
  builder
    .addCase(TicketsActions.ticketsRefreshAutomaticSet, (state, { payload }) => {
      state.refresh.isAutomatic = payload;
    })
    .addCase(TicketsActions.ticketsRefreshAutomaticItemSet, (state, { payload }) => {
      state.refresh.automaticItem = payload;
    });

  // Other actions
  builder.addMatcher(isAnyOf(AuthenticationActions.unauthenticated, DeskActions.resetDesk), () => initialState);
});
