type SafeParseNumberOptions = {
  as?: 'int' | 'float';
  fallbackNumber?: number;
};

const DEFAULT_PARSE_NUMBER_OPTIONS = {
  as: 'int',
  fallbackNumber: 0,
};

/**
 * Safely parse a string to a number.
 * @param value The string to parse.
 * @param options The options to use when parsing. Defaults to 'int' and 0.
 *
 * @returns The parsed number or the fallback number if the value is not a number.
 *
 * @example safeParseNumber('123') => 123
 * @example safeParseNumber('123.45', { as: 'float' }) => 123.45
 * @example safeParseNumber('abc') => 0
 */
const safeParseNumber = (value: string | number | undefined, options?: SafeParseNumberOptions): number => {
  const guardedOptions = {
    as: options?.as || DEFAULT_PARSE_NUMBER_OPTIONS.as,
    fallbackNumber: options?.fallbackNumber || DEFAULT_PARSE_NUMBER_OPTIONS.fallbackNumber,
  };

  let parsedValue: number = guardedOptions.fallbackNumber;

  if (typeof value === 'undefined') {
    return parsedValue;
  }

  if (guardedOptions.as === 'int') {
    parsedValue = parseInt(value.toString(), 10);
  } else if (guardedOptions.as === 'float') {
    parsedValue = parseFloat(value.toString());
  }

  return isNaN(parsedValue) ? guardedOptions.fallbackNumber : parsedValue;
};

export default safeParseNumber;
