import { createReducer } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { TicketHistoryActions } from '@desk/redux/actions/ticketHistory';

export interface TicketHistoryState {
  isFetching: boolean;
  isFetchingInitialMessages: boolean;
  isFetchingPrevMessages: boolean;
  current: Ticket | null;
  tickets: Ticket[];
  messages: PlatformAPITicketMessage[];
}

const initialState: TicketHistoryState = {
  isFetching: false,
  isFetchingInitialMessages: false,
  isFetchingPrevMessages: false,
  tickets: [],
  current: null,
  messages: [],
};

export const ticketHistoryReducer = createReducer<TicketHistoryState>(initialState, (builder) => {
  // TicketHistoryActions
  builder
    .addCase(TicketHistoryActions.currentTicketHistorySet, (state, { payload }) => {
      state.current = payload;
    })
    .addCase(TicketHistoryActions.fetchTicketHistoryMessages.pending, (state, { meta }) => {
      state.isFetchingInitialMessages = meta.arg.types === 'initial';
      state.isFetchingPrevMessages = meta.arg.types === 'prev';
    })
    .addCase(TicketHistoryActions.fetchTicketHistoryMessages.fulfilled, (state, { payload }) => {
      if (payload) {
        state.isFetchingInitialMessages = false;
        state.isFetchingPrevMessages = false;
        state.messages = payload.messages;
      }
    })
    .addCase(TicketHistoryActions.fetchTicketHistoryMessages.rejected, (state) => {
      state.isFetchingInitialMessages = false;
      state.isFetchingPrevMessages = false;
    })
    .addCase(TicketHistoryActions.currentTicketHistoryReset, (state) => {
      state.current = null;
      state.messages = [];
    });

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
