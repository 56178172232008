import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useRef } from 'react';

import { CDN_ASSETS_PREFIX } from '@constants';
import { toast } from '@feather/components/notification';
import useAppIntl from '@hooks/useAppIntl';
import logException from '@utils/logException';

type Payload = {
  title?: string;
  body?: string;
  icon?: string;
  hasSound?: boolean;
  callback?: () => void;
  noAutoClose?: boolean;
};

type Context = {
  requestPermission: () => Promise<boolean>;
  show: (payload: Payload) => void;
};

const DesktopNotificationContext = createContext<Context>({
  requestPermission: async () => false,
  show: () => {},
});

type Props = {
  children: ReactNode;
};

// https://developer.chrome.com/blog/notifying-you-of-changes-to-notifications/#android-notifications
export const isDesktopNotificationSupported = () => {
  if (!('Notification' in window) || !window.Notification || !Notification.requestPermission) {
    return false;
  }

  if (!('constructor' in window.Notification) || typeof window.Notification.constructor !== 'function') {
    return false;
  }

  return true;
};

export const isDesktopNotificationGranted = () => {
  if (isDesktopNotificationSupported()) {
    const notificationPermission = Notification.permission || Notification.prototype['permission'];
    return notificationPermission === 'granted';
  }
  return false;
};

export const DesktopNotificationProvider = ({ children }: Props) => {
  const intl = useAppIntl();

  const audioRef = useRef<HTMLAudioElement>(null);

  const value = useMemo<Context>(
    () => ({
      requestPermission: async () => {
        if (!isDesktopNotificationSupported()) {
          toast.warning({
            message: intl.formatMessage({ id: 'core.notification.warning.unsupported' }),
          });
          return false;
        }

        const notificationPermission = await new Promise((resolve) => {
          try {
            Notification.requestPermission().then(resolve);
          } catch {
            Notification.requestPermission(resolve);
          }
        });
        if (notificationPermission === 'denied') {
          toast.warning({
            message: intl.formatMessage({ id: 'core.notification.warning.denied' }),
          });
          return false;
        }

        return true;
      },
      show: (payload) => {
        if (!isDesktopNotificationSupported()) {
          return;
        }

        const {
          title = '',
          body = '',
          icon = `${CDN_ASSETS_PREFIX}/images/favicon/apple-icon-180x180.png`,
          hasSound = false,
          noAutoClose = false,
        } = payload;

        try {
          const notification = new Notification(title, { body, icon });

          notification.onclick = () => {
            try {
              window.parent.focus();
              payload.callback?.();
            } catch (error) {
              logException(error);
            }
          };

          if (hasSound && audioRef.current) {
            audioRef.current.muted = false;
            // TODO: Determine whether show a toast when playing the sound gets failed because of the permission
            audioRef.current.play().catch(() => {});
          }

          if (!noAutoClose) {
            setTimeout(() => {
              notification?.close?.();
            }, 5000);
          }
        } catch {
          /* empty */
        }
      },
    }),
    [intl],
  );

  return (
    <DesktopNotificationContext.Provider value={value}>
      <>
        {children}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          preload="auto"
          muted={true}
          ref={audioRef}
          src={`${CDN_ASSETS_PREFIX}/sounds/desk-notification-sound.mp3`}
        />
      </>
    </DesktopNotificationContext.Provider>
  );
};

export const useDesktopNotification = () => useContext(DesktopNotificationContext);
