import { useOrganizations } from '@/redux/hooks/organizations';
import type { RootState } from '@/store';

type LocalState = RootState['organizations']['current'];
type LocalSelector<T> = (state: LocalState) => T;

function useOrganization(): LocalState;
function useOrganization<T>(selector: LocalSelector<T>): T;

function useOrganization<T>(selector?: LocalSelector<T>) {
  return useOrganizations((state) => (selector ? selector(state.current) : state.current));
}

export default useOrganization;
