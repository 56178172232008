import { ChatFeatureName } from '@constants';

const convertEnabledFeaturesObjectToFeatureKeys = (enabledFeatures: EnabledFeatures) =>
  Object.entries(enabledFeatures)
    .filter(([key, value]) => {
      if (key === ChatFeatureName.MessageSearch) {
        return false;
      }
      return !!value;
    })
    .map(([key]) => key);

export default convertEnabledFeaturesObjectToFeatureKeys;
