import type { AxiosResponse } from 'axios';

import { axios, getGateURL } from '@api/shared';
import { LIST_LIMIT } from '@constants';
import { fixedEncodeURIComponent } from '@utils';
import { snakeCaseKeys } from '@utils/ObjectKeyConverter';

import { getEditChannelRequestPayload } from '../utils/EditChannelUtils';

export type InviteAsGroupChannelMemberParams = {
  appId: string;
  channelUrl: string;
  userIds: string[];
};

export type InviteAsGroupChannelMemberError = {
  code: number;
  error: boolean;
  message: string;
};

export const createOpenChannel: CreateOpenChannelAPI = ({ appId, data }) => {
  const payload = new FormData();
  Object.keys(data)
    .filter((key) => !!data[key])
    .forEach((key) => {
      payload.append(key, data[key]);
    });
  return axios.post(`${getGateURL()}/platform/v3/open_channels`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const createGroupChannel: CreateGroupChannelAPI = ({ appId, params }) => {
  const payload = new FormData();
  Object.keys(params)
    .filter((key) => !!params[key])
    .forEach((key) => {
      payload.append(key, params[key]);
    });

  return axios.post(`${getGateURL()}/platform/v3/group_channels`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchMetadata: FetchMetadataAPI = ({ appId, channelType, channel_url }) => {
  return axios.get(`${getGateURL()}/platform/v3/${channelType}/${channel_url}/metadata`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const setMetadata: SetMetadataAPI = async ({ appId, channelType, channel_url, deletes, payload }) => {
  const responses: AxiosResponse<Record<string, string>>[] = [];
  const errors: AxiosResponse[] = [];

  if (Object.entries(deletes).length > 0) {
    responses.push(
      ...((
        await Promise.all(
          deletes.map((key) =>
            axios
              .delete(`${getGateURL()}/platform/v3/${channelType}/${channel_url}/metadata/${key}`, {
                headers: {
                  'App-Id': appId,
                },
              })
              .catch((error) => {
                if (error?.response?.data?.code === 400201) {
                  // Resource not found. Because it's a DELETE request, ignore this error
                  return null;
                }
                errors.push(error);
                return null;
              }),
          ),
        )
      ).filter((response) => response) as ReadonlyArray<AxiosResponse>),
    );
  }

  const upsertMetadataResponse = await axios
    .put(
      `${getGateURL()}/platform/v3/${channelType}/${channel_url}/metadata`,
      {
        metadata: payload,
        upsert: true,
      },
      {
        headers: {
          'App-Id': appId,
        },
      },
    )
    .catch((error) => {
      errors.push(error);
      return null;
    });

  if (upsertMetadataResponse) {
    responses.push(upsertMetadataResponse);
  }

  return { responses, errors };
};

export const editChannel: EditChannelAPI = ({ appId, channelUrl, channelType, data }) => {
  const payload = getEditChannelRequestPayload(data);

  return axios.put(`${getGateURL()}/platform/v3/${channelType}/${channelUrl}`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const deleteChannel: DeleteChannelAPI = ({ appId, channelType, channel_url }) => {
  return axios.delete(`${getGateURL()}/platform/v3/${channelType}/${channel_url}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchOpenChannels: FetchOpenChannelsAPI = ({ appId, listToken }) => {
  return axios.get(`${getGateURL()}/platform/v3/open_channels?token=${listToken}&limit=${LIST_LIMIT}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const searchOpenChannels: SearchOpenChannelsAPI = ({ appId, queryOptions = '', ...params }) => {
  return axios.get(`${getGateURL()}/platform/v3/open_channels${queryOptions}`, {
    params,
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchOpenChannel: FetchOpenChannelAPI = ({ appId, channel_url }) => {
  return axios.get(`${getGateURL()}/platform/v3/open_channels/${channel_url}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchClassicOpenChannelMigrationStatus: FetchClassicOpenChannelMigrationStatusAPI = ({ appId }) => {
  return axios.get(`${getGateURL()}/platform/v3/open_channels/migrate_classic_open_channels`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const startClassicOpenChannelMigration: StartClassicOpenChannelMigrationAPI = ({ appId }) => {
  return axios.post(
    `${getGateURL()}/platform/v3/open_channels/migrate_classic_open_channels`,
    {},
    { headers: { 'App-Id': appId } },
  );
};

export const fetchGroupChannels: FetchGroupChannelsAPI = ({
  appId,
  listToken: token,
  limit,
  customType,
  order,
  showEmpty,
  superMode,
}) => {
  return axios.get(`${getGateURL()}/platform/v3/group_channels`, {
    headers: { 'App-Id': appId },
    params: snakeCaseKeys({ token, limit, customType, showEmpty, order, superMode }),
  });
};

export const searchGroupChannels: SearchGroupChannelsAPI = ({
  appId,
  queryOptions = '',
  listToken: token,
  limit,
  showEmpty,
  order,
  superMode,
}) => {
  return axios.get(`${getGateURL()}/platform/v3/group_channels${queryOptions}`, {
    headers: {
      'App-Id': appId,
    },
    params: snakeCaseKeys({ token, limit, showEmpty, order, superMode }),
  });
};

export const fetchMyGroupChannels: FetchMyGroupChannelsAPI = ({
  appId,
  userId,
  listToken: token,
  limit,
  showEmpty,
  membersExactlyIn,
  channelUrls,
  order,
  superMode,
}) => {
  return axios.get(`${getGateURL()}/platform/v3/users/${fixedEncodeURIComponent(userId)}/my_group_channels`, {
    headers: {
      'App-Id': appId,
    },
    params: {
      token,
      limit,
      order,
      show_empty: showEmpty,
      super_mode: superMode,
      members_exactly_in: membersExactlyIn?.map((userId) => fixedEncodeURIComponent(userId)).join(','),
      channel_urls: channelUrls?.map((url) => fixedEncodeURIComponent(url)).join(','),
    },
  });
};

export const fetchGroupChannel: FetchGroupChannelAPI = ({ appId, channel_url }) => {
  return axios.get(`${getGateURL()}/platform/v3/group_channels/${channel_url}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const inviteAsGroupChannelMember = ({ appId, channelUrl, userIds }: InviteAsGroupChannelMemberParams) =>
  axios.post<SendbirdChatChannel.GroupChannel>(
    `${getGateURL()}/platform/v3/group_channels/${channelUrl}/invite`,
    { user_ids: userIds },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
