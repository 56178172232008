import { configureStore } from '@reduxjs/toolkit';

import { groupChannelsReducer } from '@chat/redux/reducers/groupChannels';
import { openChannelsReducer } from '@chat/redux/reducers/openChannels';
import { accountReducer } from '@common/redux/reducers/account';
import { applicationUpdateReducer } from '@common/redux/reducers/applicationUpdate';
import { authenticationReducer } from '@common/redux/reducers/authentication';
import { billingReducer } from '@common/redux/reducers/billing';
import { dialogsReducer } from '@common/redux/reducers/dialogs';
import { imagePreviewReducer } from '@common/redux/reducers/imagePreview';
import { lnbReducer } from '@common/redux/reducers/lnb';
import { organizationsReducer } from '@common/redux/reducers/organizations';
import { isProduction } from '@constants';
import { applicationStateReducer } from '@core/redux/reducers/applicationState';
import { sdkUserReducer } from '@core/redux/reducers/sdkUser';
import { settingsReducer } from '@core/redux/reducers/settings';
import { agentGroupsReducer } from '@desk/redux/reducers/agentGroups';
import { conversationReducer } from '@desk/redux/reducers/conversation';
import { deskReducer } from '@desk/redux/reducers/desk';
import { integrationsReducer } from '@desk/redux/reducers/integrations';
import { ticketDetailReducer } from '@desk/redux/reducers/ticketDetail';
import { ticketHistoryReducer } from '@desk/redux/reducers/ticketHistory';
import { ticketsReducer } from '@desk/redux/reducers/tickets';

export const store = configureStore({
  reducer: {
    // common
    auth: authenticationReducer,
    applicationUpdate: applicationUpdateReducer,
    dialogs: dialogsReducer,
    imagePreview: imagePreviewReducer,
    account: accountReducer,
    organizations: organizationsReducer,
    billing: billingReducer,
    lnb: lnbReducer,

    // core
    applicationState: applicationStateReducer,
    sdkUser: sdkUserReducer,
    settings: settingsReducer,

    // chat
    openChannels: openChannelsReducer,
    groupChannels: groupChannelsReducer,

    // desk
    desk: deskReducer,
    ticketDetail: ticketDetailReducer,
    conversation: conversationReducer,
    ticketHistory: ticketHistoryReducer,
    tickets: ticketsReducer,
    agentGroups: agentGroupsReducer,
    integrations: integrationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      actionCreatorCheck: false,
    }),
  devTools: !isProduction && {
    serialize: {
      replacer: (_, value: any) => {
        if (typeof value === 'object' && value !== null) {
          if (value instanceof Event) {
            return 'Event';
          }
          if ('_reactName' in value) {
            return `React Component: ${value._reactName}`;
          }
          if ('elementType' in value) {
            return `Element: ${value.elementType}`;
          }
          if (value.render) {
            return `Component: ${value.render.displayName}`;
          }
          if (value.$$typeof) {
            return value.$$typeof;
          }
        }
        return value;
      },
    },
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
