import { getLocalTimeZone } from '@internationalized/date';

import { useMatch } from '@/router';

import useDeskProject from './useDeskProject';

const useIsDesk = () => {
  try {
    return !!useMatch('/:appId/desk/*');
  } catch {
    return false;
  }
};

const useDeskOrLocalTimeZone = () => {
  const isDesk = useIsDesk();
  const timeZone = useDeskProject((project) => project.timezone ?? getLocalTimeZone());
  return isDesk ? timeZone : getLocalTimeZone();
};

export default useDeskOrLocalTimeZone;
