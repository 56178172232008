export enum SubscriptionName {
  Free = 'free',
  FreeTrial = 'free_trial',

  Developer = 'developer',
  PlanA1K = 'plan_a_1k',
  PlanA5K = 'plan_a_5k',
  PlanA10K = 'plan_a_10k',
  PlanA25K = 'plan_a_25k',
  PlanA50K = 'plan_a_50k',
  PlanA100K = 'plan_a_100k',

  PlanB5K = 'plan_b_5k',
  PlanB10K = 'plan_b_10k',
  PlanB25K = 'plan_b_25k',
  PlanB50K = 'plan_b_50k',
  PlanB100K = 'plan_b_100k',

  // Community = 'community',
  // SupportL0 = 'support_l0',
  // SupportL1 = 'support_l1',
  // SupportL2 = 'support_l2',
  // SupportL3 = 'support_l3',

  AiStarter = 'ai_chatbot_a',
  AiPro = 'ai_chatbot_b',

  AiStarterShopify = 'ai_chatbot_a_shopify',
  AiProShopify = 'ai_chatbot_b_shopify',

  Enterprise = 'enterprise',
  AiEnterprise = 'enterprise',
  // AwsMarketplace = 'aws_marketplace',
}

export const freePlans = [SubscriptionName.Free, SubscriptionName.FreeTrial];

export const starterPlans = [
  SubscriptionName.PlanA1K,
  SubscriptionName.PlanA5K,
  SubscriptionName.PlanA10K,
  SubscriptionName.PlanA25K,
  SubscriptionName.PlanA50K,
  SubscriptionName.PlanA100K,
];

export const proPlans = [
  SubscriptionName.PlanB5K,
  SubscriptionName.PlanB10K,
  SubscriptionName.PlanB25K,
  SubscriptionName.PlanB50K,
  SubscriptionName.PlanB100K,
];

export const chatPlans = [...starterPlans, ...proPlans];

export const aiPlans = [SubscriptionName.AiStarter, SubscriptionName.AiPro];

export enum SubscriptionProduct {
  Chat = 'CHAT',
  // Calls = 'CALLS',
  Desk = 'DESK',
  Support = 'SUPPORT',
  AiBots = 'AI_BOTS',
  BusinessMessaging = 'NOTIFICATIONS',
}

export enum PrimaryContacts {
  OwnerOnly = 'OWNER_ONLY',
  OwnerAdmin = 'OWNER_ADMIN',
  OwnerAdminBilling = 'OWNER_ADMIN_BILLING',
  // All = 'ALL',
}
