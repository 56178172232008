import { createReducer } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { ImagePreviewActions } from '@common/redux/actions/imagePreview';

interface ImagePreviewState {
  images: ImagePreview[];
}

const initialState: ImagePreviewState = {
  images: [],
};

export const imagePreviewReducer = createReducer<ImagePreviewState>(initialState, (builder) => {
  // ImagePreviewActions
  builder
    .addCase(ImagePreviewActions.showImagePreview, (state, { payload }) => {
      state.images = payload as unknown as ImagePreview[];
    })
    .addCase(ImagePreviewActions.hideImagePreview, () => initialState);

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
