import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import {
  deleteSamlConfiguration,
  exportOrganizationMembersList,
  transferOwner,
  updateOrganization,
  updateSamlConfiguration,
} from '@common/api/organizations';
import { DialogType } from '@common/containers/dialogs/DialogType';
import { toast } from '@feather/components/notification';
import getErrorMessage from '@utils/getErrorMessage';
import logException from '@utils/logException';
import saveAs from '@utils/saveAs';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { DialogsActions } from './dialogs';

type SSOConfigurationFormParams = {
  entity_id: string;
  idp_cert: string;
  idp_url: string;
  sso_enforcing: boolean;
  jit_provisioning: boolean;
  sso_default_role: string;
};

export const OrganizationsActions = {
  organizationCreated: createAction<{ organizations: Organization[] }>('common/organizations/organizationCreated'),
  organizationUpdated: createAction<Partial<Organization>>('common/organizations/organizationUpdated'),

  updateOrganizationName: createAsyncThunk<void, { name: Organization['name'] }, { rejectValue: string | null }>(
    'common/organizations/updateOrganizationName',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { uid } = getState().organizations.current;

      try {
        const { data } = await updateOrganization(uid, { name: payload.name });

        dispatch(OrganizationsActions.organizationUpdated(data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return rejectWithValue(getErrorMessage(error));
        }

        logException(error);
        return rejectWithValue(getErrorMessage(error));
      }
    },
  ),
  updateOrganizationSlugName: createAsyncThunk<
    Organization,
    SSOConfigurationFormParams & { slug_name: string },
    { rejectValue: string | null }
  >('common/organizations/updateOrganizationSlugName', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { uid } = getState().organizations.current;
    const { slug_name, ...samlConfigurationPayload } = payload;

    try {
      const { data } = await updateOrganization(uid, { slug_name });

      dispatch(OrganizationsActions.updateSamlConfiguration(samlConfigurationPayload));
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const detail = (error.response?.data as { detail: string })?.detail;
        if (detail) {
          return rejectWithValue(detail);
        }
        return rejectWithValue(getErrorMessage(error));
      }

      logException(error);
      return rejectWithValue(getErrorMessage(error));
    }
  }),
  updateSamlConfiguration: createAsyncThunk<
    UpdateSamlConfigurationResponse,
    SSOConfigurationFormParams,
    { rejectValue: string | null }
  >('common/organizations/updateSamlConfiguration', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { uid, slug_name } = getState().organizations.current;

    try {
      const { data } = await updateSamlConfiguration(uid, payload);

      dispatch(
        DialogsActions.showDialog({
          dialogTypes: DialogType.SamlOneMoreStepToGo,
          dialogProps: {
            organizationKey: slug_name,
          },
        }),
      );
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(
          window.intl.formatMessage({ id: 'common.authentication.sso.error.samlConfigurationFail' }),
        );
      }

      logException(error);
      return rejectWithValue(getErrorMessage(error));
    }
  }),
  updateSamlConfigurationReset: createAction('common/organizations/updateSamlConfigurationReset'),

  deleteSamlConfiguration: createAsyncThunk<void>(
    'common/organizations/deleteSamlConfiguration',
    async (_, { dispatch, getState }) => {
      const { uid } = getState().organizations.current;

      try {
        await deleteSamlConfiguration(uid);

        dispatch(DialogsActions.hideDialog());
        toast.success({ message: window.intl.formatMessage({ id: 'alerts.samlConfigurationDeleted' }) });
      } catch (error) {
        toastBadRequestWarning(error);
      }
    },
  ),

  transferOwner: createAsyncThunk<void, TransferOwnerPayload>(
    'common/organizations/transferOwner',
    async (payload, { dispatch, rejectWithValue }) => {
      try {
        await transferOwner({
          organization_uid: payload.organization_uid,
          email: payload.email,
        });

        dispatch(DialogsActions.hideDialog());
        toast.success({ message: 'Transferring Owner is completed. You should sign in again.' });
        location.href = '/auth/signout';
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  exportOrganizationMembers: createAsyncThunk<void>(
    'common/organizations/exportOrganizationMembers',
    async (_, { getState }) => {
      const { uid } = getState().organizations.current;

      try {
        const { data } = await exportOrganizationMembersList(uid);

        saveAs({ data, filename: 'organization_member_list.csv' });
      } catch (error) {
        logException(error);
      }
    },
  ),
};
