import type { RootState } from '@/store';

import { useShallowEqualSelector } from '.';

type LocalState = RootState['organizations'];
type LocalSelector<T> = (state: LocalState) => T;

export function useOrganizations(): LocalState;
export function useOrganizations<T>(selector: LocalSelector<T>): T;

export function useOrganizations<T>(selector?: LocalSelector<T>) {
  return useShallowEqualSelector<T | LocalState>((state) =>
    selector ? selector(state.organizations) : (state.organizations as LocalState),
  );
}
