import { Time, ZonedDateTime, parseAbsolute, toCalendarDateTime, toZoned } from '@internationalized/date';
import { CalendarDate, parseDate } from '@internationalized/date';

export type DatePropValue<T> = T | string | null;

export const safeParseDate = (value: string) => {
  try {
    return parseDate(value);
  } catch {
    return undefined;
  }
};

const safeParseAbsolute = (value: string, timeZone: string) => {
  try {
    return parseAbsolute(value, timeZone);
  } catch {
    return undefined;
  }
};

export const convertToCalendarDate = (value: DatePropValue<CalendarDate> | Date | undefined) => {
  if (value instanceof CalendarDate) {
    return value;
  }
  if (value instanceof Date) {
    return new CalendarDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
  }
  if (typeof value === 'string') {
    return safeParseDate(value.substring(0, 10));
  }
  return undefined;
};

export const convertToZonedDateTime = (
  value: DatePropValue<ZonedDateTime> | CalendarDate | Date | number | undefined,
  timeZone: string,
) => {
  if (value instanceof ZonedDateTime) {
    return value;
  }
  if (value instanceof CalendarDate) {
    return toZoned(value, timeZone);
  }
  if (value instanceof Date) {
    return safeParseAbsolute(value.toISOString(), timeZone);
  }
  if (typeof value === 'string') {
    return safeParseAbsolute(value, timeZone);
  }
  if (typeof value === 'number') {
    const date = new Date(value);
    return safeParseAbsolute(date.toISOString(), timeZone);
  }
  return undefined;
};

const MILLISECONDS_IN_SECOND = 1000;
const MILLISECONDS_IN_MINUTE = 60 * MILLISECONDS_IN_SECOND;
const MILLISECONDS_IN_HOUR = 60 * MILLISECONDS_IN_MINUTE;
const MILLISECONDS_IN_DAY = 24 * MILLISECONDS_IN_HOUR;
export const getZonedDateTimeDifference = (
  a: ZonedDateTime,
  b: ZonedDateTime,
  unit?: 'day' | 'hour' | 'minute' | 'second',
) => {
  const diff = a.compare(b);
  switch (unit) {
    case 'day': {
      return diff > 0 ? Math.floor(diff / MILLISECONDS_IN_DAY) : Math.ceil(diff / MILLISECONDS_IN_DAY);
    }
    case 'hour': {
      return diff > 0 ? Math.floor(diff / MILLISECONDS_IN_HOUR) : Math.ceil(diff / MILLISECONDS_IN_HOUR);
    }
    case 'minute': {
      return diff > 0 ? Math.floor(diff / MILLISECONDS_IN_MINUTE) : Math.ceil(diff / MILLISECONDS_IN_MINUTE);
    }
    case 'second': {
      return diff > 0 ? Math.floor(diff / MILLISECONDS_IN_SECOND) : Math.ceil(diff / MILLISECONDS_IN_SECOND);
    }
    default: {
      return diff;
    }
  }
};

export const getZonedDateStartOfDay = (date: CalendarDate, timeZone: string) => {
  return toZoned(toCalendarDateTime(date, new Time(0, 0)), timeZone);
};

export const getZonedDateEndOfDay = (date: CalendarDate, timeZone: string) => {
  return toZoned(toCalendarDateTime(date, new Time(23, 59, 59, 999)), timeZone);
};

export const getCalendarDateYearMonthString = (date: CalendarDate) => {
  return date.toString().substring(0, 7);
};
