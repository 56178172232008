import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ZIndexes } from '@ui';

interface PortalProps extends React.HTMLProps<HTMLDivElement> {
  zIndex?: number;
}

export const Portal = ({ children, zIndex }: PortalProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>();

  useEffect(() => {
    const portalRoot = document.getElementById('portal_root');

    const element = document.createElement('div');
    element.style.position = 'absolute';
    element.style.top = '0';
    element.style.right = '0';
    element.style.left = '0';
    element.style.zIndex = `${zIndex ?? ZIndexes.portal}`;
    portalRoot?.appendChild(element);
    setContainer(element);

    return () => {
      element.parentNode?.removeChild(element);
    };
  }, [zIndex]);

  return container ? createPortal(children, container) : null;
};
