import type { AxiosError, AxiosInstance } from 'axios';
import axios from 'axios';
import qs from 'qs';

import { getCurrentPathWithQueryString } from '@utils';
import logException from '@utils/logException';

const truncate = (text: string) =>
  text.replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/g, '{UUID}').slice(0, 200);

/**
 *
 * null or undefined → undefined
 * '/users/d3aa88e2-c754-41e0-8ba6-4198a34aa0a2' → '/users/{UUID}'
 * { team: 'frontend', name: 'Roy' } → 'team=frontend&name=Roy'
 */
function format(input: any) {
  if (!input) return;
  if (typeof input === 'string') return truncate(input);
  if (typeof input === 'object') return truncate(qs.stringify(input));
  return;
}

function logAxiosException(error: unknown) {
  if (!axios.isAxiosError(error)) throw error;
  const { config, response } = error as AxiosError<{ message: string | object }>;

  logException(error, {
    reason: 'Axios',
    tags: {
      method: config.method?.toUpperCase(),
      url: format(config.url),
      params: format(config.params),
      message: format((response?.data as any)?.message),
      status: response?.status,
    },
    context: {
      request: {
        url: config.url,
        method: config.method,
        params: config.params,
        data: config.data,
      },
      response: {
        status: response?.status,
        data: response?.data,
      },
    },
  });
}

export function axiosResponseRejectedInterceptor(error: any) {
  if (axios.isCancel(error)) {
    // Ignore a rejection by a request cancellation
    // axios support validator function for error object whether isCancel or not
    // axios.isCancel(error);
    return null;
  }

  if (!error) {
    logException(new Error('Caught undefined error in app/api/cancellableAxios'), {
      reason: 'Axios - undefined error',
      context: { error, isErrorNullOrUndefined: error == null },
    });
    return Promise.reject(error);
  }

  if (error.status === 401) {
    logException(error, { reason: 'Axios - error.status is 401' });
    location.href = '/auth/signout';
  } else if (error.response?.status === 401) {
    // The request was made, but the server responded with a status code
    // that falls out of the range of 2xx
    location.href = `/auth/signout?next=${getCurrentPathWithQueryString()}`;
  } else if (error.response?.status >= 500) {
    logAxiosException(error);
  }

  // can be either Error or AxiosError object
  return Promise.reject(error);
}

export default function setupAxiosInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use((response) => {
    // Do something with response data
    return response;
  }, axiosResponseRejectedInterceptor);
}
