import { CategoryScale, Chart, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-date-fns';
import AnnotationPlugin from 'chartjs-plugin-annotation';

import cssColors from '@feather/theme/cssColors';
import { typeface } from '@feather/typography';

Chart.defaults.color = cssColors('neutral-7') as string;
Chart.defaults.font.family = typeface.system;
Chart.defaults.font.size = 12;

Chart.register(TimeScale, CategoryScale, LinearScale, Tooltip, AnnotationPlugin);
