// DO NOT destructure import.meta.env! https://github.com/mrsteele/dotenv-webpack/issues/70
declare const SB_DASHBOARD_APP_VERSION: string;
declare const SB_SENTRY_TAGS_BRANCH: string;
declare const SB_SENTRY_RELEASE: string;
declare const SB_SENTRY_DIST: string;

const config: EnvironmentConfig = {
  GATE_URL: import.meta.env.SB_GATE_URL,
  GATE_URL_REGION: import.meta.env.SB_GATE_URL_REGION,
  BUILD_MODE: import.meta.env.SB_BUILD_MODE,
  NODE_ENV: import.meta.env.SB_NODE_ENV,
  DASHBOARD_API_HOST: import.meta.env.SB_DASHBOARD_API_HOST,
  E2E: import.meta.env.SB_E2E,
  DASHBOARD_APP_VERSION: SB_DASHBOARD_APP_VERSION,
  SENTRY_TAGS_BRANCH: SB_SENTRY_TAGS_BRANCH,
  SENTRY_RELEASE: SB_SENTRY_RELEASE,
  SENTRY_DIST: SB_SENTRY_DIST,
  USE_DEV_CERT: import.meta.env.SB_USE_DEV_CERT,
};

export default config;
