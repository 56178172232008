import type { CancellableAxiosPromise } from '@api/cancellableAxios';
import { axios, getGateURL, getGateURLWithRegion } from '@api/shared';
import { fixedEncodeURIComponent } from '@utils';
import { snakeCaseKeys } from '@utils/ObjectKeyConverter';

export const sendAdminMessageWithModeratorInfo: SendAdminMessageWithModeratorInfoAPI = ({
  appId,
  channelUrls,
  channelType,
  message,
  sendPush,
  moderator,
  // FIXME: this is temporary solution for Kakao Ent. This should be removed when BE will work on it and send correct sdkUser data .
  // following issue: https://sendbird.atlassian.net/browse/DBP-1281
}) => {
  return axios.post(
    `${getGateURLWithRegion()}/platform/dapi/channels/${channelType}/admin_message/`,
    {
      message,
      channel_urls: channelUrls,
      send_push: sendPush,
      moderator,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const editMessage: EditMessageAPI = ({ appId, channelType, channelURL, messageId, payload }) => {
  return axios.put(
    `${getGateURLWithRegion()}/platform/v3/${channelType}/${channelURL}/messages/${messageId}`,
    payload,
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const fetchChannelThreadMessages: FetchChannelThreadMessagesAPI = ({
  appId,
  channelType,
  channelUrl,
  parentMessageId,
  messageTs,
  include = true, // if include is true, the response will include the message whose `createdAt` equals `messageTs`.
  prevLimit,
  nextLimit,
  operatorFilter,
  includeRemoved = false,
  withSortedMetaArray = true,
}) =>
  axios.get(`${getGateURLWithRegion()}/platform/dapi/${channelType}/${channelUrl}/messages`, {
    headers: {
      'App-Id': appId,
    },
    params: {
      ...snakeCaseKeys({
        prevLimit,
        nextLimit,
        parentMessageId,
        messageTs,
        operatorFilter,
        withSortedMetaArray,
      }),
      include,
      include_reply_type: 'ALL',
      include_thread_info: true,
      presigned_file_url: true,
      including_removed: includeRemoved,
    },
  });

export const fetchChannelMessages: FetchChannelMessagesAPI = ({
  appId,
  channelUrl,
  channelType,
  messageId,
  senderIds,
  userId,
  ts,
  prevLimit,
  nextLimit,
  operatorFilter = 'all',
  include,
  includeReplyType,
  includeParentMessageInfo,
  includeThreadInfo,
  includeRemoved = false,
  withSortedMetaArray = true,
}) => {
  // Use dapi to bypass Soda's premium feature check logic about Message Retrieval
  return axios.get(`${getGateURLWithRegion()}/platform/dapi/${channelType}/${channelUrl}/messages`, {
    headers: {
      'App-Id': appId,
    },
    params: {
      ...snakeCaseKeys({
        messageId,
        senderIds,
        prevLimit,
        nextLimit,
        operatorFilter,
        includeReplyType,
        includeParentMessageInfo,
        includeThreadInfo,
        withSortedMetaArray,
        userId,
      }),
      message_ts: ts,
      include,
      presigned_file_url: true,
      including_removed: includeRemoved,
    },
  });
};

export const fetchGroupChannelsMessagesTotalCount: FetchGroupChannelsMessagesTotalCountAPI = ({ appId, channelUrl }) =>
  axios.get(`${getGateURL()}/platform/v3/group_channels/${channelUrl}/messages/total_count`, {
    headers: {
      'App-Id': appId,
    },
  });

export const fetchGroupChannelMembers: FetchGroupChannelMembersAPI = ({ appId, channelUrl, params }) => {
  return axios.get(`${getGateURL()}/platform/v3/group_channels/${channelUrl}/members`, {
    params,
    headers: { 'App-Id': appId },
  });
};

export const fetchOpenChannelParticipants = ({
  appId,
  channelUrl,
  params,
}: {
  appId: string;
  channelUrl: string;
  params: { token?: string; limit?: number };
}) => {
  return axios.get<{ next: string; participants: SendbirdChatChannel.OpenChannelParticipant[] }>(
    `${getGateURL()}/platform/v3/open_channels/${channelUrl}/participants`,
    {
      params,
      headers: { 'App-Id': appId },
    },
  );
};

export const fetchChannelBannedUsers = ({
  appId,
  channelUrl,
  token,
  limit,
  channelType,
  showTotalBanCount,
}: {
  appId: string;
  channelUrl: string;
  token: string;
  limit: number;
  channelType: SendbirdChatChannel.ChannelType;
  showTotalBanCount: boolean;
}) => {
  return axios.get<{
    banned_list: BannedUserListItem[];
    total_ban_count?: number;
    next: string;
  }>(`${getGateURL()}/platform/v3/${channelType}/${channelUrl}/ban`, {
    params: snakeCaseKeys({ token, limit, showTotalBanCount }),
    headers: { 'App-Id': appId },
  });
};

export const fetchChannelMutedUsers = ({
  appId,
  channelUrl,
  token,
  limit,
  channelType,
  showTotalMuteCount,
}: {
  appId: string;
  channelUrl: string;
  token: string;
  limit: number;
  channelType: SendbirdChatChannel.ChannelType;
  showTotalMuteCount: boolean;
}) => {
  return axios.get<{
    muted_list: MutedUserListItem[];
    total_mute_count?: number;
    next: string;
  }>(`${getGateURL()}/platform/v3/${channelType}/${channelUrl}/mute`, {
    params: snakeCaseKeys({ token, limit, showTotalMuteCount }),
    headers: { 'App-Id': appId },
  });
};

export const muteUser = ({ appId, channelType, channelUrl, userId, seconds, description }: MuteUserParams) => {
  return axios.post(
    `${getGateURL()}/platform/v3/${channelType}/${channelUrl}/mute`,
    { user_id: userId, seconds, description },
    { headers: { 'App-Id': appId } },
  );
};

export const unmuteUser = ({ appId, channelType, channelUrl, userId }: UnmuteUserParams) => {
  return axios.delete<{}>(
    `${getGateURL()}/platform/v3/${channelType}/${channelUrl}/mute/${fixedEncodeURIComponent(userId)}`,
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const banUser = ({ appId, channelUrl, payload, channelType }: BanUserParams) => {
  return axios.post<BannedUserListItem>(`${getGateURL()}/platform/v3/${channelType}/${channelUrl}/ban`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const unbanUser = ({ appId, channelUrl, userId, channelType }: UnbanUserParams) => {
  return axios.delete<{}>(
    `${getGateURL()}/platform/v3/${channelType}/${channelUrl}/ban/${fixedEncodeURIComponent(userId)}`,
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const freezeChannel = <T extends SendbirdChatChannel.ChannelType>({
  appId,
  channelUrl,
  channelType,
  freeze,
}: CoreAPIPayload<{
  channelType: T;
  channelUrl: string;
  freeze: boolean;
}>): CancellableAxiosPromise<
  T extends 'open_channels' ? SendbirdChatChannel.OpenChannel : SendbirdChatChannel.GroupChannel
> => {
  return axios.put(
    `${getGateURL()}/platform/v3/${channelType}/${channelUrl}/freeze`,
    {
      freeze,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const updateOperators = ({ appId, channelType, channelUrl, userIds }: UpdateOperatorsParams) => {
  return axios.post(
    `${getGateURL()}/platform/v3/${channelType}/${channelUrl}/operators`,
    { operator_ids: userIds },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const getBanDetail = ({ appId, channelUrl, userId, channelType }: GetBanDetailParams) => {
  return axios
    .get<GetBanDetailResponse>(
      `${getGateURL()}/platform/v3/${channelType}/${channelUrl}/ban/${fixedEncodeURIComponent(userId)}`,
      {
        headers: {
          'App-Id': appId,
        },
      },
    )
    .then(
      (response) =>
        'user' in response.data
          ? { ...response, data: { is_banned: true, data: response.data } }
          : { ...response, data: { is_banned: false } },
      (error) => {
        if (error.response) {
          if (
            error.response.status === 400 &&
            error.response.data?.code === 400201 &&
            error.response.data?.message === '"User" not found.'
          ) {
            error.response.status = 200;
            error.response.data = { is_banned: false };
            return error.response;
          }
        }
        throw error;
      },
    );
};

export const getMuteDetail = ({ appId, channelUrl, userId, channelType }: GetMuteDetailParams) => {
  return axios
    .get<ViewAMuteResponse>(
      `${getGateURL()}/platform/v3/${channelType}/${channelUrl}/mute/${fixedEncodeURIComponent(userId)}`,
      {
        headers: {
          'App-Id': appId,
        },
      },
    )
    .catch((error) => {
      if (error.response) {
        if (
          error.response.status === 400 &&
          error.response.data?.code === 400201 &&
          error.response.data?.message === '"User" not found.'
        ) {
          error.response.status = 200;
          error.response.data = { is_muted: false, description: '', start_at: 0, end_at: 0, remaining_duration: 0 };
          return error.response;
        }
      }
      throw error;
    });
};
