import React from 'react';

import { parseAbsolute, toCalendarDate } from '@internationalized/date';
import type { AdminMessage, BaseMessage, FileMessage, UserMessage } from '@sendbird/chat/message';
import { MessageType } from '@sendbird/chat/message';
import compareAsc from 'date-fns/compareAsc';

import { EMAIL_PARSE_REGEX, URL_PARSE_REGEX } from '@constants';

import convertNodeArrayToReactFragment from './convertNodeArrayToReactFragment';
import getExtension from './getExtension';
import guessTimezone from './guessTimezone';

const CMD_MESG = 'MESG';
const CMD_FILE = 'FILE';
const CMD_ADMM = 'ADMM';
const CMD_BRDM = 'BRDM';

export const getBaseMessageTypeFromPlatformAPIMessage = (
  message: Pick<SendbirdChatPlatformAPI.AllMessageType, 'type'>,
): MessageType => {
  return {
    [CMD_FILE]: MessageType.FILE,
    [CMD_ADMM]: MessageType.ADMIN,
    [CMD_BRDM]: MessageType.ADMIN,
    [CMD_MESG]: MessageType.USER,
  }[message.type];
};

export const sdkMessageTypeChecker = {
  isUserMessage: (message: Pick<BaseMessage, 'messageType'>): message is UserMessage => {
    return message.messageType === MessageType.USER;
  },
  isFileMessage: (message: Pick<BaseMessage, 'messageType'>): message is FileMessage => {
    return message.messageType === MessageType.FILE;
  },
  isAdminMessage: (message: Pick<BaseMessage, 'messageType'>): message is AdminMessage => {
    return message.messageType === MessageType.ADMIN;
  },
};

export const shouldRenderImage = (
  type: NonNullable<SendbirdChatModeration.BaseMessage['file']>['type'],
  url: string | null,
): boolean => {
  if (!url) {
    // it prevent `url` null runtime error
    return false;
  }
  const fileExtension = getExtension(url);

  const imageExtensions = [
    'bmp',
    'cod',
    'gif',
    'ief',
    'jpe',
    'jpeg',
    'jpg',
    'jfif',
    'svg',
    'tif',
    'tiff',
    'ras',
    'cmx',
    'ico',
    'pnm',
    'pbm',
    'pgm',
    'ppm',
    'rgb',
    'xbm',
    'xpm',
    'xwd',
    'png',
  ];

  return (
    !!type?.match(/^image.+$/i) || (type === 'application/octet-stream' && imageExtensions.includes(fileExtension))
  );
};

/**
 * Check whether a dateline must be rendered between two dates.
 *
 * @param previousDate UNIX timestamp or ISO String of the former message
 * @param nextDate UNIX timestamp or ISO String of the latter message
 * @param timeZone Time zone the dates are displayed in
 */
export function shouldRenderDateLine({
  previousDate,
  nextDate,
  timeZone = guessTimezone(),
}: {
  previousDate: number | string;
  nextDate: number | string;
  timeZone?: string;
}) {
  try {
    const prev = parseAbsolute(new Date(previousDate).toISOString(), timeZone);
    const next = parseAbsolute(new Date(nextDate).toISOString(), timeZone);
    return toCalendarDate(prev).compare(toCalendarDate(next)) < 0;
  } catch {
    return false;
  }
}

const convertURLToLink = (token: string): React.ReactNode =>
  token.match(URL_PARSE_REGEX) ? (
    <a href={token} target="_blank">
      {token}
    </a>
  ) : (
    token
  );

const convertEmailToLink = (token: string) =>
  token.match(EMAIL_PARSE_REGEX) ? <a href={`mailto:${token}`}>{token}</a> : token;

export const convertURLsAndEmailsToLinks = (message: string) => {
  if (!message) {
    return null;
  }
  if (!message.match(URL_PARSE_REGEX)) {
    // no URLs or emails found from the message
    return message;
  }

  return convertNodeArrayToReactFragment(
    message
      .split(new RegExp(`(${URL_PARSE_REGEX.source})`)) // add parentheses to capture delimiters (URLs)
      .map(convertURLToLink)
      .map((token) => {
        if (typeof token !== 'string') {
          return token;
        }
        return convertNodeArrayToReactFragment(
          token
            .split(new RegExp(`(${EMAIL_PARSE_REGEX.source})`)) // add parentheses to capture delimiters (emails)
            .map(convertEmailToLink),
        );
      }),
  );
};

export const convertMessageWithCustomRule = (
  message: string,
  customRule: { regex: RegExp; converter: (token: string) => React.ReactNode },
) => {
  if (!message) {
    return null;
  }

  const convertedEmailToLink = message
    .split(new RegExp(`(${EMAIL_PARSE_REGEX.source})`)) // add parentheses to capture delimiters (URLs)
    .map(convertEmailToLink);

  let convertedByCustomRule: React.ReactNode[] = [];
  convertedEmailToLink.forEach((token) => {
    if (typeof token === 'string') {
      convertedByCustomRule = convertedByCustomRule.concat(token.split(customRule.regex).map(customRule.converter));
    } else {
      convertedByCustomRule.push(token);
    }
  });

  return convertNodeArrayToReactFragment(
    convertedByCustomRule.map((token) => {
      if (typeof token !== 'string') {
        return token;
      }
      return convertNodeArrayToReactFragment(
        token
          .split(new RegExp(`(${URL_PARSE_REGEX.source})`)) // add parentheses to capture delimiters (emails)
          .map(convertURLToLink),
      );
    }),
  );
};

export const isTicketNoteMessage = (
  message: TicketMessage | ProactiveChatMessage | TicketNote | SendbirdChatMessage.SendableMessageWithIsRemoved,
): message is TicketNote => 'note' in message;

export const convertToRichTicketNote = (ticketNote: TicketNote): RichTicketNote => ({
  ...ticketNote,
  sender: { userId: String(ticketNote.createdBy?.id ?? ''), nickname: ticketNote.createdBy?.displayName ?? '' },
  messageType: 'note',
  isRemoved: false,
});

export const getCreatedAtFromMessage = (message: TicketMessage | ProactiveChatMessage | TicketNote) => {
  if (isTicketNoteMessage(message)) {
    return message.createdAt;
  }
  if ('timestamp' in message) {
    return message.timestamp;
  }
  if ('instagramComment' in message) {
    return message.instagramComment.timestamp;
  }
  return message.createdAt;
};

export const mergeTicketNotesAndMessages = <
  TMessage extends TicketMessage | ProactiveChatMessage,
  TTicketNote extends TicketNote | RichTicketNote,
>(
  messages: TMessage[],
  ticketNotes: TTicketNote[],
) =>
  [...messages, ...ticketNotes].sort((a, b) =>
    compareAsc(new Date(getCreatedAtFromMessage(a)), new Date(getCreatedAtFromMessage(b))),
  );
