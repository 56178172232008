import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { OrganizationStatus } from '@constants';

import { useAuth } from '../redux/hooks/auth';
import useOrganization from './useOrganization';

const useAuthentication = () => {
  const { authenticated } = useAuth();
  const organization = useOrganization();
  const isOrganizationDeactivated = !isEmpty(organization) && organization?.status !== OrganizationStatus.Active;

  return useMemo(() => ({ authenticated, isOrganizationDeactivated }), [authenticated, isOrganizationDeactivated]);
};

export default useAuthentication;
