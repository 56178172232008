import { useDesk } from '@/redux/hooks/desk';
import type { RootState } from '@/store';

type LocalState = RootState['desk']['project'];
type LocalSelector<T> = (state: LocalState) => T;

function useDeskProject(): LocalState;
function useDeskProject<T>(selector: LocalSelector<T>): T;

function useDeskProject<T>(selector?: LocalSelector<T>) {
  return useDesk((state) => (selector ? selector(state.project) : state.project));
}

export default useDeskProject;
