import { useApplicationState } from '@/redux/hooks/applicationState';
import type { RootState } from '@/store';

type LocalState = RootState['applicationState']['data'];
type LocalSelector<T> = (state: LocalState) => T;

function useApplication(): LocalState;
function useApplication<T>(selector: LocalSelector<T>): T;

function useApplication<T>(selector?: LocalSelector<T>) {
  return useApplicationState((state) => (selector ? selector(state.data) : state.data));
}

export default useApplication;
