import type { ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import { LinkVariant } from '@feather';
import { EmptyState, EmptyStateSize } from '@feather/components/EmptyState';
import { Button } from '@feather/components/button';
import * as Icons from '@feather/components/icons';
import { Link } from '@feather/components/link';

type Props = { title?: ReactNode; description?: ReactNode; className?: string } & (
  | { action: ReactNode; onRetry: never; isRetrying: never }
  | { action?: never; onRetry?: () => void; isRetrying?: boolean }
);

export default function ErrorState({
  title = 'Something went wrong',
  description,
  className,
  action,
  onRetry,
  isRetrying,
}: Props) {
  return (
    <div
      className={className}
      css={`
        display: grid;
        grid-template-rows: 1fr 8%;
        justify-content: center;
        height: 100%;
      `}
    >
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 24px;
          text-align: center;
        `}
      >
        <EmptyState
          size={EmptyStateSize.Large}
          icon={Icons.Error}
          title={title}
          withoutMarginBottom
          description={
            description ?? (
              <FormattedMessage
                id="errorState.body"
                defaultMessage="Try again or if you’re continuing to have problems, <a>contact us</a> for support."
                values={{
                  a: (text) => (
                    <Link variant={LinkVariant.Inline} href="/settings/contact_us">
                      {text}
                    </Link>
                  ),
                }}
              />
            )
          }
        />
        {action ?? (
          <Button
            size="small"
            buttonType="primary"
            onClick={onRetry ?? (() => window.location.reload())}
            isLoading={isRetrying}
            icon={Icons.Refresh}
          >
            Try again
          </Button>
        )}
      </div>
    </div>
  );
}
