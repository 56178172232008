import { createReducer } from '@reduxjs/toolkit';

import { LNBActions } from '@common/redux/actions/lnb';
import type { LeftNavigationBarItemInterface, LeftNavigationBarMenuItem } from '@feather';

export interface LNBState {
  menuItems: LeftNavigationBarMenuItem[];
  activeItemKey: string | undefined;
  activeGroup: string | undefined;
}

const initialState: LNBState = {
  menuItems: [],
  activeItemKey: undefined,
  activeGroup: undefined,
};

export const lnbReducer = createReducer<LNBState>(initialState, (builder) => {
  builder
    .addCase(LNBActions.setLNBMenuItems, (state, { payload }) => {
      state.menuItems = payload.items;
    })
    .addCase(LNBActions.setActiveLNBMenuItem, (state, { payload }) => {
      state.activeItemKey = payload.activeItemKey;
      state.activeGroup = payload.activeGroup;
    })
    .addCase(LNBActions.updateLNBMenuItem, (state, { payload }) => {
      state.menuItems = state.menuItems.map((item: LeftNavigationBarItemInterface) =>
        item.key === payload.key
          ? { ...item, ...payload.option }
          : {
              ...item,
              subMenus: item.subMenus?.map((subMenu) =>
                subMenu.key === payload.key ? { ...subMenu, ...payload.option } : subMenu,
              ),
            },
      );
    });
});
