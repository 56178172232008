import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { browserRouter } from '@/browserRouter';
import createAsyncThunk from '@/redux/createAsyncThunk';
import { setGateRegionSubdomain } from '@api/shared';
import { DialogsActions } from '@common/redux/actions/dialogs';
import { getApplication } from '@core/api/application';
import { getAPIToken } from '@core/api/overview';
import { changeAppName } from '@core/api/settings';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import { toast } from '@feather/components/notification';
import getErrorMessage from '@utils/getErrorMessage';
import logException from '@utils/logException';
import { ALERT_APPLICATION_NAME_CHANGED } from '@utils/text';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

export const ApplicationActions = {
  applicationSet: createAction<Application>('core/application/applicationSet'),
  applicationReset: createAction('core/application/applicationReset'),
  applicationCreated: createAction<Application>('core/application/applicationCreated'),
  applicationUpdated: createAction<Partial<Application>>('core/application/applicationUpdated'),
  applicationAttributesUpdated: createAction<Partial<Application['attrs']>>(
    'core/application/applicationAttributesUpdated',
  ),

  applicationFetched: createAction<Application>('core/application/applicationFetched'),
  fetchApplication: createAsyncThunk<void, { app_id: string; applicationSummary?: ApplicationSummary }>(
    'core/application/fetchApplication',
    async (payload, { dispatch, rejectWithValue }) => {
      try {
        const { data } = await getApplication({ app_id: payload.app_id });

        setGateRegionSubdomain(data.region);
        dispatch(ApplicationActions.applicationFetched(data));
      } catch (error) {
        if (!axios.isAxiosError(error)) {
          logException(error);
        }
        const message = getErrorMessage(error);
        toast.error({ message });
        browserRouter.navigate('/');
        return rejectWithValue(message);
      }
    },
  ),

  changeAppName: createAsyncThunk<{ app_id: string; app_name: string }, { app_id: string; app_name: string }>(
    'core/application/changeAppName',
    async (payload, { dispatch, rejectWithValue }) => {
      const { app_id, app_name } = payload;
      if (app_name.length === 0) {
        const error = new Error(window.intl.formatMessage({ id: 'core.settings.general.app.error_required' }));
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }
      if (app_name.length > 128) {
        const error = new Error(window.intl.formatMessage({ id: 'core.settings.general.app.error_limit' }));
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      try {
        dispatch(DialogsActions.setIsFetching(true));
        const { data } = await changeAppName({ appId: app_id, appName: app_name });

        toast.success({ message: ALERT_APPLICATION_NAME_CHANGED });
        dispatch(DialogsActions.hideDialog());
        return { app_id, app_name: data.app_name };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      } finally {
        dispatch(DialogsActions.setIsFetching(false));
      }
    },
  ),

  getAPIToken: createAsyncThunk<string, { password?: string }>(
    'core/application/getAPIToken',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;

      try {
        const { data } = await getAPIToken({ appId, password: payload.password });

        dispatch(DialogsActions.hideDialog());
        return data.api_token;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
};
