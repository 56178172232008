import logException from '@utils/logException';

const getErrorMessage = (error: any, fallbackMessage: string = 'Something went wrong. Please try again.'): string => {
  /**
   * FIXME:
   * The return of Empty string when there is no error is a temporary solution.
   * let's fix this to return `null` value and refactor all of its dependency.
   */
  if (!error) return '';

  let errorMessage;
  const status = error?.response?.status;
  const data = error?.response?.data;

  if (typeof error === 'string') {
    errorMessage = error;
  } else if (status && (String(status).startsWith('4') || String(status).startsWith('5')) && data) {
    errorMessage = data.message || data.detail || data.error?.message;
  } else if (data?.message) {
    errorMessage = data.message;
  } else if (error?.message) {
    errorMessage = error.message;
  } else {
    logException(new Error('Unclassified error type in getBadRequestNotificationAction'), {
      reason: 'Unclassified error in getErrorMessage',
      context: { error },
    });
  }

  return errorMessage || fallbackMessage;
};

export default getErrorMessage;
