import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import {
  fetchFacebookFeeds,
  fetchFacebookMessages,
  fetchInstagramComments,
  fetchInstagramMessages,
  fetchTwitterDirectMessageEvents,
  fetchTwitterStatuses,
  fetchWhatsAppMessages,
} from '@desk/api/conversation';
import { fetchTicketMessages } from '@desk/api/ticketDetail';
import { fetchTicket } from '@desk/api/tickets';
import { getGeneralizedTicketChannelType } from '@desk/utils';
import {
  convertTwitterStatusTicketToMergedTwitterStatus,
  parseTwitterDirectMessageEventAttachments,
} from '@desk/utils/twitterUtils';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { selectDeskProject } from '../selectors/desk';

type FetchTicketDetailMessagesType = 'initial' | 'prev' | 'next';

export const TicketDetailActions = {
  fetchTicketDetailTicket: createAsyncThunk<Ticket, number>(
    'desk/ticketDetail/fetchTicketDetailTicket',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await fetchTicket(pid, region, { ticketId: payload });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  fetchTicketDetailHeader: createAsyncThunk<Ticket, number>(
    'desk/ticketDetail/fetchTicketDetailHeader',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await fetchTicket(pid, region, { ticketId: payload });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  fetchTicketDetailMessages: createAsyncThunk<
    {
      types: FetchTicketDetailMessagesType;
      messages: PlatformAPITicketMessage[];
      initialOrNextFetchedTimestamp?: number;
    },
    {
      channelType: Ticket['channelType'];
      types: FetchTicketDetailMessagesType;
      ticketId: number;
      channelUrl?: Ticket['channelUrl'];
      messageTs: number;
      prevLimit: number;
      nextLimit: number;
      presignedFileUrl: boolean;
    }
  >(
    'desk/ticketDetail/fetchTicketDetailMessages',
    async (payload, { getState, rejectWithValue }) => {
      const { app_id, region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());
      const { channelType, ticketId, channelUrl, messageTs, prevLimit = 0, nextLimit = 0, presignedFileUrl } = payload;

      const getResultReturnValue = (messages: PlatformAPITicketMessage[]) => {
        const currentMessages = getState().ticketDetail.messages;
        if (payload.types === 'prev') {
          return {
            types: payload.types,
            messages: messages.concat(currentMessages),
          };
        }
        if (payload.types === 'next') {
          return {
            types: payload.types,
            messages: messages.length > 0 ? currentMessages.concat(messages) : currentMessages,
            /**
             * Updating `initialOrNextFetchedTimestamp` will scroll the messages to the bottom.
             * That's why we pass undefined to `initialOrNextFetchedTimestamp` below for empty messages.
             */
            initialOrNextFetchedTimestamp: messages.length > 0 ? Date.now() : undefined,
          };
        }
        return {
          types: payload.types,
          messages,
          initialOrNextFetchedTimestamp: Date.now(),
        };
      };

      try {
        switch (channelType) {
          case 'FACEBOOK_CONVERSATION': {
            const params = `prevLimit=${prevLimit}&nextLimit=${nextLimit}&ts=${messageTs}`;
            const { data } = await fetchFacebookMessages(pid, region, { ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'FACEBOOK_FEED': {
            const params = `prevLimit=${prevLimit}&nextLimit=${nextLimit}&ts=${messageTs}`;
            const { data } = await fetchFacebookFeeds(pid, region, { ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'TWITTER_DIRECT_MESSAGE_EVENT': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchTwitterDirectMessageEvents(pid, region, { ticketId, params });

            const messages = data.results.map(parseTwitterDirectMessageEventAttachments);
            return getResultReturnValue(messages);
          }
          case 'TWITTER_STATUS': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchTwitterStatuses(pid, region, { ticketId, params });

            const messages = data.results.map(convertTwitterStatusTicketToMergedTwitterStatus);
            return getResultReturnValue(messages);
          }
          case 'INSTAGRAM_COMMENT': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchInstagramComments(pid, region, { ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'INSTAGRAM_MESSAGE': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchInstagramMessages(pid, region, { ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'WHATSAPP_MESSAGE': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchWhatsAppMessages(pid, region, { ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'SENDBIRD_JAVASCRIPT':
          case 'SENDBIRD_IOS':
          case 'SENDBIRD_ANDROID':
          case 'SENDBIRD':
          default: {
            const { data } = await fetchTicketMessages({
              appId: app_id,
              channelUrl: channelUrl!,
              params: { messageTs, prevLimit, nextLimit, presignedFileUrl },
            });

            const { messages } = data;
            return getResultReturnValue(messages);
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
    {
      condition: (arg, { getState }) => {
        return (
          (getGeneralizedTicketChannelType(arg.channelType) !== 'sendbird' || !!arg.channelUrl) &&
          getState().ticketDetail.ticket?.id === arg.ticketId
        );
      },
    },
  ),

  ticketDetailUpdated: createAction<Partial<Ticket>>('desk/ticketDetail/ticketDetailUpdated'),
  ticketDetailSendbirdAPIMessageUpdated: createAction<SendbirdChatPlatformAPI.AllMessageType>(
    'desk/ticketDetail/ticketDetailSendbirdAPIMessageUpdated',
  ),
  ticketDetailReset: createAction('desk/ticketDetail/ticketDetailReset'),
};
