import type { FC } from 'react';

import 'intersection-observer';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { install as installResizeObserver } from 'resize-observer';

/**
 * @sbfe/notifications-template-preview-react must be imported before feather to
 * avoid TailwindCSS Preflight overriding feather styles (e.g. primary button
 * background color becoming white instead of purple.)
 */
import '@sbfe/notifications-template-preview-react/dist/main.css';

import { RouterProvider } from '@/router';
import { AuthorizationContextProvider } from '@authorization';
import { IconButton } from '@feather/components/button';
import { toast } from '@feather/components/notification';
import { FeatherProvider } from '@feather/theme/FeatherProvider';
import { GlobalStyles } from '@ui/styles';
import { FeatureFlagContextProvider } from '@utils/featureFlags';
import initializeSentry from '@utils/initializeSentry';

import { DesktopNotificationProvider } from './DesktopNotification';
import { SendbirdChatProvider } from './SendbirdChat';
import { browserRouter } from './browserRouter';
import './chartjsDefault';
import { ConnectedIntlProvider } from './intl';
import { queryClient } from './queryClient';
import { store } from './store';

IconButton.setTooltipPortalId('portal_popup');
toast.rootElementID = 'portal_toast';
initializeSentry();

const container = document.getElementById('root');
const root = createRoot(container as Element);

if (!root) throw new Error('Failed to find the root element');

const TypeFixedFeatherProvider = FeatherProvider as FC<{ children?: React.ReactNode }>;

if (!('ResizeObserver' in window)) {
  installResizeObserver();
}

root.render(
  <QueryClientProvider client={queryClient}>
    <Helmet>
      <meta name="viewport" content="" />
    </Helmet>
    <RecoilRoot>
      <GlobalStyles />
      <TypeFixedFeatherProvider>
        <Provider store={store}>
          <FeatureFlagContextProvider>
            <AuthorizationContextProvider>
              <ConnectedIntlProvider>
                <SendbirdChatProvider instanceKey="app">
                  <DesktopNotificationProvider>
                    <RouterProvider router={browserRouter} />
                  </DesktopNotificationProvider>
                </SendbirdChatProvider>
              </ConnectedIntlProvider>
            </AuthorizationContextProvider>
          </FeatureFlagContextProvider>
        </Provider>
      </TypeFixedFeatherProvider>
    </RecoilRoot>
  </QueryClientProvider>,
);
