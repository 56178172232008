import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useQuery, useQueryClient } from 'react-query';

import QueryKeyPrefix from '@constants/queryKey';
import { fetchEnabledFeaturesQueryFn } from '@core/api/queryFn';
import { toast } from '@feather/components/notification';
import useApplication from '@hooks/useApplication';
import useIsAuthenticated from '@hooks/useIsAuthenticated';
import useOrganization from '@hooks/useOrganization';
import getErrorMessage from '@utils/getErrorMessage';

import convertEnabledFeaturesObjectToFeatureKeys from './convertEnabledFeaturesObjectToFeatureKeys';

/**
 * @FIXME: https://sendbird.atlassian.net/browse/DFE-1504
 * @description Multiple enabledFeatures in different places
 */
const useSelfServiceFeatures = () => {
  const { isSelfService, isOrgActive } = useOrganization((current) => ({
    isSelfService: !!current.is_self_serve,
    isOrgActive: !isEmpty(current) && current.status === 'ACTIVE',
  }));
  const authenticated = useIsAuthenticated();
  const application = useApplication();

  const appId = application?.app_id;

  const queryClient = useQueryClient();
  const { isLoading: isLoadingFeatures, data: selfServiceFeatures = [] } = useQuery(
    [QueryKeyPrefix.AppFetchEnabledFeatures, appId],
    fetchEnabledFeaturesQueryFn,
    {
      enabled: !!(authenticated && isOrgActive && isSelfService && appId),
      select: (data) => convertEnabledFeaturesObjectToFeatureKeys(data),
      onError: (error) => {
        toast.error({ message: getErrorMessage(error) });
        queryClient.setQueryData([QueryKeyPrefix.AppFetchEnabledFeatures, appId], {});
      },
    },
  );

  const isLoading = !authenticated || !application || isLoadingFeatures;

  return useMemo(() => ({ isLoading, result: selfServiceFeatures }), [isLoading, selfServiceFeatures]);
};

export default useSelfServiceFeatures;
