import { snakeCaseKeys } from '@utils/ObjectKeyConverter';

import DeskApiAxios from './DeskApiAxios';

export const fetchAgentGroups: FetchAgentGroupsAPI = (pid, region, { offset, limit, query, isBotOnly }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('projects/groups/', { params: snakeCaseKeys({ offset, limit, query, isBotOnly }) });
};

export const fetchAgentGroup: FetchAgentGroupAPI = (pid, region, { groupId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`groups/${groupId}/`);
};

export const createAgentGroup: CreateAgentGroupAPI = (pid, region, { name, key, description, agents }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('groups/', { name, key, description, agents });
};

export const updateAgentGroup: UpdateAgentGroupAPI = (pid, region, { groupId, name, agents, description }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`groups/${groupId}/`, { name, agents, description });
};

export const deleteAgentGroup: DeleteAgentGroupAPI = (pid, region, { groupId, transferTargetGroupId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`groups/${groupId}/inactive/`, { transferTargetGroupId });
};

export const checkAgentGroupKeyDuplicate: CheckAgentGroupKeyDuplicateAPI = (pid, region, params) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('groups/key_duplicate_check', { params });
};

export const checkAgentGroupNameDuplicate: CheckAgentGroupNameDuplicateAPI = (pid, region, params) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('groups/name_duplicate_check', { params });
};

export const searchAgentGroups: SearchAgentGroupsAPI = (pid, region, { name }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('groups/search/', { params: { name } });
};
