import type { BlockerFunction } from '@remix-run/router';
import * as Sentry from '@sentry/react';
import type { RouteProps } from 'react-router-dom';
import { createBrowserRouter as _createBrowserRouter } from 'react-router-dom';

import type { AllPremiumFeatures } from '@constants';

export {
  Link,
  MemoryRouter,
  Router,
  matchPath,
  useLocation,
  useParams,
  BrowserRouter,
  Route,
  Routes,
  useMatch,
  RouterProvider,
  useNavigate,
  useRoutes,
  Navigate,
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createMemoryRouter,
  Outlet,
  useSearchParams,
  redirect,
} from 'react-router-dom';

const createBrowserRouter = Sentry.wrapCreateBrowserRouter(_createBrowserRouter) as typeof _createBrowserRouter;

export { createBrowserRouter };
export type { BlockerFunction };

export type { RouteProps } from 'react-router-dom';

export type RoutePropsWithPermission = Pick<RouteProps, 'path' | 'index' | 'element'> & {
  allowedPermissions: PermissionKey[];
  premiumFeature?: AllPremiumFeatures;
  fallbackPath?: RouteProps['path'];
};
