import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';

import { DeskActions } from '../actions/desk';

export interface DeskStoreState {
  isFetching: boolean;
  isUpdating: boolean;
  authenticated: boolean;
  connected: boolean; // SDK connected (Open Channel)
  id: number;
  project: Project;
  agent: AuthAgent;
  apiTokens: {
    items: ApiToken[];
    isFetching: boolean;
    pagination: LimitOffsetPagination;
  };
}

const initialState: DeskStoreState = {
  isFetching: false,
  isUpdating: false,
  authenticated: false,
  connected: false,
  id: 0,
  project: {} as Project,
  agent: {} as AgentDetail,
  apiTokens: {
    items: [],
    isFetching: false,
    pagination: {
      limit: 25,
      offset: 0,
      count: 0,
      page: 1,
    },
  },
};

export const deskReducer = createReducer<DeskStoreState>(initialState, (builder) => {
  // DeskActions
  builder
    .addCase(DeskActions.deskAuthentication.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(DeskActions.deskAuthenticated, (state, { payload }) => {
      state.isFetching = false;
      state.authenticated = true;
      state.id = payload.id;
      state.agent = payload.agent;
      state.project = payload.project;
    })
    .addCase(DeskActions.setDeskConnected, (state, { payload }) => {
      state.connected = payload;
    })
    .addCase(DeskActions.setDeskProject, (state, { payload }) => {
      state.project = {
        ...state.project,
        ...payload,
      };
    })
    .addCase(DeskActions.setDeskAgent, (state, { payload }) => {
      state.agent = {
        ...state.agent,
        ...payload,
      };
    })
    .addCase(DeskActions.setAgentConnection, (state, { payload }) => {
      state.agent.connection = payload;
    })
    .addCase(DeskActions.fetchApiTokens.pending, (state) => {
      state.apiTokens.isFetching = true;
    })
    .addCase(DeskActions.fetchApiTokens.fulfilled, (state, { payload }) => {
      state.apiTokens = {
        items: payload.results,
        pagination: {
          ...state.apiTokens.pagination,
          count: payload.count,
        },
        isFetching: false,
      };
    })
    .addCase(DeskActions.fetchApiTokens.rejected, (state) => {
      state.apiTokens.isFetching = false;
    })
    .addCase(DeskActions.apiTokenPaginationSet, (state, { payload }) => {
      state.apiTokens.pagination.limit = payload.limit;
      state.apiTokens.pagination.offset = payload.offset;
      state.apiTokens.pagination.page = payload.offset / payload.limit + 1;
    })
    .addCase(DeskActions.resetDesk, () => initialState);

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);

  // DeskActions
  builder
    .addMatcher(isAnyOf(DeskActions.updateProject.pending, DeskActions.updateOperationHours.pending), (state) => {
      state.isUpdating = true;
    })
    .addMatcher(
      isAnyOf(
        DeskActions.updateProject.fulfilled,
        DeskActions.updateProject.rejected,
        DeskActions.updateOperationHours.fulfilled,
        DeskActions.updateOperationHours.rejected,
      ),
      (state) => {
        state.isUpdating = false;
      },
    );
});
