import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { ConversationActions } from '@desk/redux/actions/conversation';

import { DeskActions } from '../actions/desk';

export interface ConversationState {
  isFetching: boolean;
  isFetchingMessages: boolean;

  /**
   * In-app chat tickets' messages can be read using Sendbird SDK or Platform API.
   */
  messages: SendbirdChatMessage.AllMessage[] | SendbirdChatPlatformAPI.AllMessageType[];

  facebookMessages: FacebookPageMessage[];
  facebookFeeds: FacebookFeedType[];
  twitterDirectMessages: AttachmentParsedTwitterDirectMessageEvent[];
  twitterStatuses: MergedTwitterStatus[];
  instagramComments: InstagramCommentTicket[];
  instagramMessages: InstagramMessageTicket[];
  whatsAppMessages: WhatsAppMessageType[];
  ticket: Ticket | null;
  typingStatus: TypingStatus;
  initialOrNextFetchedTimestamp?: number;
}

const initialState: ConversationState = {
  isFetching: true,
  isFetchingMessages: false,
  messages: [],
  facebookMessages: [],
  facebookFeeds: [],
  twitterDirectMessages: [],
  twitterStatuses: [],
  instagramComments: [],
  instagramMessages: [],
  whatsAppMessages: [],
  ticket: null,
  typingStatus: {
    typingMembers: [],
    agentTyping: false,
    othersTyping: false,
  },
};

export const conversationReducer = createReducer<ConversationState>(initialState, (builder) => {
  // ConversationActions
  builder
    .addCase(ConversationActions.fetchConversationMessages.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.messages = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchConversationMessages.fulfilled, (state, { payload }) => {
      const { messages, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.messages = messages;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.fetchFacebookMessages.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.facebookMessages = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchFacebookMessages.fulfilled, (state, { payload }) => {
      const { facebookMessages, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.facebookMessages = facebookMessages;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.facebookMessageUpdated, (state, { payload }) => {
      const hasTemp = state.facebookMessages.some(
        (facebookMessage) => facebookMessage.mid === payload.facebookMessage.mid,
      );
      if (hasTemp) {
        state.facebookMessages = state.facebookMessages.map((facebookMessage) => {
          if (facebookMessage.mid === payload.facebookMessage.mid) {
            return payload.facebookMessage;
          }
          return facebookMessage;
        });
      } else {
        state.facebookMessages.push(payload.facebookMessage);
        // When sending a new message, updating initialOrNextFetchedTimestamp state will scroll messages to the bottom.
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.conversationMessagesReset, (state) => {
      state.messages = [];
      state.facebookMessages = [];
      state.facebookFeeds = [];
      state.twitterDirectMessages = [];
      state.twitterStatuses = [];
      state.instagramComments = [];
      state.whatsAppMessages = [];
    })
    .addCase(ConversationActions.fetchFacebookFeeds.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.facebookFeeds = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchFacebookFeeds.fulfilled, (state, { payload }) => {
      const { facebookFeeds, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.facebookFeeds = facebookFeeds;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.facebookFeedsUpdated, (state, { payload }) => {
      const hasTemp = state.facebookFeeds.some((item) => item.feedId === payload.feedId);
      if (hasTemp) {
        state.facebookFeeds = state.facebookFeeds.map((feed) => {
          if (feed.feedId === payload.feedId) {
            return payload;
          }
          return feed;
        });
      } else {
        state.facebookFeeds.push(payload);
        // When sending a new message, updating initialOrNextFetchedTimestamp state will scroll messages to the bottom.
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.facebookFeedRequestUpdated, (state, { payload }) => {
      state.facebookFeeds = state.facebookFeeds.map((feed) => {
        if (feed.feedId === payload.feed.feedId) {
          return payload.feed;
        }
        return feed;
      });
    })
    .addCase(ConversationActions.fetchTwitterDirectMessages.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.twitterDirectMessages = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchTwitterDirectMessages.fulfilled, (state, { payload }) => {
      const { messages, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.twitterDirectMessages = messages;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.deleteTwitterDirectMessageEvent.fulfilled, (state, { payload }) => {
      state.twitterDirectMessages = state.twitterDirectMessages.map((item) =>
        item.eventId === payload.eventId ? { ...item, status: payload.status } : item,
      );
    })
    .addCase(ConversationActions.fetchTwitterStatuses.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.twitterStatuses = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchTwitterStatuses.fulfilled, (state, { payload }) => {
      const { messages, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.twitterStatuses = messages;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.createTwitterStatus.fulfilled, (state, { payload }) => {
      const duplicatedStatusIndex = state.twitterStatuses.findIndex((item) => item.statusId === payload.statusId);
      if (duplicatedStatusIndex > -1) {
        state.twitterStatuses[duplicatedStatusIndex] = payload;
      } else {
        state.twitterStatuses.push(payload);
        // If got a new message, updating initialOrNextFetchedTimestamp state will scroll messages to the bottom.
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.twitterStatusFromDeskEventUpdated, (state, { payload }) => {
      if (state.twitterStatuses.some((item) => item.statusId === payload.statusId)) {
        state.twitterStatuses = state.twitterStatuses.map((item) =>
          item.statusId === payload.statusId ? { ...item, ...payload } : item,
        );
      } else {
        state.twitterStatuses.push(payload);
        // If got a new message, updating initialOrNextFetchedTimestamp state will scroll messages to the bottom.
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.patchTwitterStatus.fulfilled, (state, { payload }) => {
      state.twitterStatuses = state.twitterStatuses.map((item) =>
        item.id === payload.id ? { ...item, status: payload.status } : item,
      );
    })
    .addCase(ConversationActions.patchTwitterStatusTwitterUser.fulfilled, (state, { payload }) => {
      const { twitterStatusId, ...updates } = payload;
      state.twitterStatuses = state.twitterStatuses.map((item) =>
        item.id === twitterStatusId ? { ...item, ...updates } : item,
      );
    })
    .addCase(ConversationActions.fetchInstagramComments.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.instagramComments = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchInstagramComments.fulfilled, (state, { payload }) => {
      const { instagramComments, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.instagramComments = instagramComments;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.fetchInstagramComments.rejected, (state) => {
      state.isFetchingMessages = false;
    })
    .addCase(ConversationActions.instagramCommentUpdated, (state, { payload }) => {
      const duplicatedEventIndex = state.instagramComments.findIndex((item) => item.id === payload.id);
      const isUpdatable = state.ticket?.id === (payload.ticket as Ticket).id;

      if (isUpdatable) {
        if (duplicatedEventIndex > -1) {
          state.instagramComments[duplicatedEventIndex] = payload;
        } else {
          state.instagramComments.push(payload);
          state.initialOrNextFetchedTimestamp = Date.now();
        }
      }
    })
    .addCase(ConversationActions.instagramCommentFromDeskEventUpdated, (state, { payload }) => {
      if (state.instagramComments.some((item) => item.instagramComment.id === payload.instagramComment.id)) {
        state.instagramComments = state.instagramComments.map((item) =>
          item.instagramComment.id === payload.instagramComment.id ? { ...item, ...payload } : item,
        );
      } else {
        state.instagramComments.push(payload);
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.deleteInstagramComment.pending, (state) => {
      state.isFetchingMessages = true;
    })
    .addCase(ConversationActions.deleteInstagramComment.fulfilled, (state, { payload }) => {
      if (state.instagramComments.some((item) => item.instagramComment.id === payload.id)) {
        state.instagramComments = state.instagramComments.map((item) =>
          item.instagramComment.id === payload.id ? { ...item, instagramComment: payload } : item,
        );
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.deleteInstagramComment.rejected, (state) => {
      state.isFetchingMessages = false;
    })
    .addCase(ConversationActions.fetchInstagramMessages.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.instagramMessages = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchInstagramMessages.fulfilled, (state, { payload }) => {
      const { instagramMessages, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.instagramMessages = instagramMessages;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.fetchInstagramMessages.rejected, (state) => {
      state.isFetchingMessages = false;
    })
    .addCase(ConversationActions.instagramMessageUpdated, (state, { payload }) => {
      const isUpdatable = state.ticket?.id === (payload.ticket as Ticket).id;

      if (isUpdatable) {
        const duplicatedEventIndex = state.instagramMessages.findIndex((item) => item.id === payload.id);
        if (duplicatedEventIndex > -1) {
          state.instagramMessages[duplicatedEventIndex] = payload;
        } else {
          state.instagramMessages.push(payload);
          state.initialOrNextFetchedTimestamp = Date.now();
        }
      }
    })
    .addCase(ConversationActions.instagramMessageFromDeskEventUpdated, (state, { payload }) => {
      if (state.instagramMessages.some((item) => item.id === payload.id)) {
        state.instagramMessages = state.instagramMessages.map((item) =>
          item.id === payload.id ? { ...item, ...payload } : item,
        );
      } else {
        state.instagramMessages.push(payload);
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.instagramMessageUnsendUpdated, (state, { payload }) => {
      state.instagramMessages = state.instagramMessages.filter((message) => message.id !== payload.id);
    })
    .addCase(ConversationActions.fetchWhatsAppMessages.pending, (state, { meta }) => {
      if (meta.arg.types === 'initial') {
        state.isFetchingMessages = true;
        state.whatsAppMessages = [];
      } else {
        state.isFetchingMessages = false;
      }
    })
    .addCase(ConversationActions.fetchWhatsAppMessages.fulfilled, (state, { payload }) => {
      const { whatsAppMessages, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.whatsAppMessages = whatsAppMessages;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(ConversationActions.fetchWhatsAppMessages.rejected, (state) => {
      state.isFetchingMessages = false;
    })
    .addCase(ConversationActions.createWhatsAppMessage.fulfilled, (state, { payload }) => {
      const duplicatedMessageIndex = state.whatsAppMessages.findIndex((message) => message.id === payload.id);

      if (duplicatedMessageIndex > -1) {
        state.whatsAppMessages[duplicatedMessageIndex] = payload;
      } else {
        state.whatsAppMessages.push(payload);
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.whatsAppMessageFromDeskEventUpdated, (state, { payload }) => {
      if (state.whatsAppMessages.some((item) => item.id === payload.id)) {
        state.whatsAppMessages = state.whatsAppMessages.map((item) =>
          item.id === payload.id ? { ...item, ...payload } : item,
        );
      } else {
        state.whatsAppMessages.push(payload);
        // If got a new message, updating initialOrNextFetchedTimestamp state will scroll messages to the bottom.
        state.initialOrNextFetchedTimestamp = Date.now();
      }
    })
    .addCase(ConversationActions.fetchConversation.pending, (state) => {
      state.isFetching = true;
    })
    /**
     * Set current viewing active conversation ticket
     */
    .addCase(ConversationActions.conversationFetched, (state, { payload }) => {
      state.isFetching = false;
      state.ticket = payload;
    })
    .addCase(ConversationActions.conversationAssignmentSet, (state, { payload }) => {
      if (state.ticket) {
        state.ticket.recentAssignment = payload;
      } else {
        state.ticket = null;
      }
    })
    .addCase(ConversationActions.typingStatusSet, (state, { payload }) => {
      state.typingStatus = payload;
    })
    .addCase(ConversationActions.agentTypingStatusSet, (state, { payload }) => {
      state.typingStatus.agentTyping = payload;
    })
    .addCase(ConversationActions.othersTypingStatusSet, (state, { payload }) => {
      state.typingStatus = { ...state.typingStatus, ...payload };
    })
    .addCase(ConversationActions.conversationTicketMessageUpdated, (state, { payload }) => {
      state.messages = state.messages.map((message) =>
        message.message_id === payload.messageId ? { ...message, ...payload.updated } : message,
      );
    })
    .addMatcher(
      isAnyOf(
        ConversationActions.fetchTwitterDirectMessages.rejected,
        ConversationActions.fetchTwitterStatuses.rejected,
      ),
      (state) => {
        state.isFetchingMessages = false;
      },
    )
    .addMatcher(
      isAnyOf(
        ConversationActions.createTwitterDirectMessage.fulfilled,
        ConversationActions.twitterDirectMessageEventUpdated,
      ),
      (state, { payload }) => {
        const duplicatedEventIndex = state.twitterDirectMessages.findIndex((item) => item.eventId === payload.eventId);
        if (duplicatedEventIndex > -1) {
          state.twitterDirectMessages[duplicatedEventIndex] = payload;
        } else {
          state.twitterDirectMessages.push(payload);
          // If got a new message, updating initialOrNextFetchedTimestamp state will scroll messages to the bottom.
          state.initialOrNextFetchedTimestamp = Date.now();
        }
      },
    );

  // Other actions
  builder.addMatcher(
    isAnyOf(ConversationActions.conversationReset, AuthenticationActions.unauthenticated, DeskActions.resetDesk),
    () => initialState,
  );
});
