export enum DialogType {
  Delete = 'DELETE',
  Overage = 'OVERAGE',
  CreateApp = 'CREATE_APP',
  CallsFreeVoucher = 'CALLS_FREE_VOUCHER',
  ChangeAppName = 'CHANGE_APP_NAME',
  DeleteApplication = 'DELETE_APPLICATION',
  Custom = 'CUSTOM',
  Confirm = 'CONFIRM',
  ConfirmWithOrganizationName = 'CONFIRM_WITH_ORGANIZATION_NAME',
  Confirm1k = 'CONFIRM_1K',
  ReasonForCancel = 'REASON_FOR_CANCEL',
  ChangePassword = 'CHANGE_PASSWORD',
  InviteMember = 'INVITE_MEMBER',
  ChangeMemberRole = 'CHANGE_MEMBER_ROLE',
  RegisterCard = 'REGISTER_CARD',
  DeleteAccount = 'DELETE_ACCOUNT',
  RegisterTwoFactor = 'REGISTER_TWO_FACTOR',
  SamlOneMoreStepToGo = 'SAML_ONE_MORE_STEP_TO_GO',
  SelectApplicationsToAccess = 'SELECT_APPLICATIONS_TO_ACCESS',
  AnalyticsExport = 'ANALYTICS_EXPORT',
  AnnouncementDataDisplay = 'ANNOUNCEMENT_DATA_DISPLAY',
  CreateOpenChannel = 'CREATE_OPEN_CHANNEL',
  CreateGroupChannel = 'CREATE_GROUP_CHANNEL',
  ChangeToSupergroup = 'CHANGE_TO_SUPERGROUP',
  UpdateSDKUser = 'UPDATE_SDK_USER',
  CreateSDKUser = 'CREATE_SDK_USER',
  EditChannel = 'EDIT_CHANNEL',
  ChannelData = 'CHANNEL_DATA',
  ResourceMetadata = 'RESOURCE_METADATA',
  AdminMessage = 'ADMIN_MESSAGE',
  DeleteChannels = 'DELETE_CHANNELS',
  DeleteMessage = 'DELETE_MESSAGE',
  EditMessage = 'EDIT_MESSAGE',
  ModeratorInformation = 'MODERATOR_INFORMATION',
  BanMuteUser = 'BAN_MUTE_USER',
  DeactivateUser = 'DEACTIVATE_USER',
  ReactivateUser = 'REACTIVATE_USER',
  GetApiToken = 'GET_API_TOKEN',
  SubscriptionPlan = 'SUBSCRIPTION_PLAN',
  PushApnsSave = 'PUSH_APNS_SAVE',
  PushApnsVoipRegister = 'PUSH_APNS_VOIP_REGISTER',
  PushApnsVoipEdit = 'PUSH_APNS_VOIP_EDIT',
  PushFcmSave = 'PUSH_FCM_SAVE',
  PushHuaweiSave = 'PUSH_HUAWEI_SAVE',
  PushTester = 'PUSH_TESTER',
  CreateUser = 'CREATE_USER',
  DeleteUser = 'DELETE_USER',
  InviteAsGroupChannelMember = 'INVITE_AS_GROUP_CHANNEL_MEMBER',
  IssueUserAccessToken = 'ISSUE_USER_ACCESS_TOKEN',
  RegisterUserAsOperator = 'REGISTER_USER_AS_OPERATOR',
  AssignTransferTicketToAgent = 'ASSIGN_TRANSFER_TICKET_TO_AGENT',
  AssignTransferTicketToGroup = 'ASSIGN_TRANSFER_TICKET_TO_GROUP',
  CreateAgentGroup = 'CREATE_AGENT_GROUP',
  CloseTicket = 'CLOSE_TICKET',
  ConfirmEndOfChat = 'CONFIRM_END_OF_CHAT',
  UpdateAgentProfile = 'UPDATE_AGENT_PROFILE',
  ViewAgentProfile = 'VIEW_AGENT_PROFILE',
  CreateNewToken = 'CREATE_NEW_TOKEN',
  AddWebhook = 'ADD_WEBHOOK',
  EditWebhook = 'EDIT_WEBHOOK',
  DeleteWebhook = 'DELETE_WEBHOOK',
  ReopenTicket = 'REOPEN_TICKET',
  ExportTicket = 'EXPORT_TICKET',
  ChangeEmail = 'CHANGE_EMAIL',
  SelectTwitterStatusRecipients = 'SELECT_TWITTER_STATUS_RECIPIENTS',
  ReportsExport = 'REPORTS_EXPORT',
  ExportTicketsInfo = 'EXPORT_TICKETS_INFO',
  CreateProactiveChat = 'CREATE_PROACTIVE_CHAT',
  DeleteAgentGroup = 'DELETE_AGENT_GROUP',
  ManageDirectCallsColumns = 'MANAGE_DIRECT_CALLS_COLUMNS',
  ExportMissedTicket = 'EXPORT_MISSED_TICKET',
  ExportPendingTicket = 'EXPORT_PENDING_TICKET',
  ExportDirectCallLogs = 'EXPORT_DIRECT_CALL_LOGS',
  PhoneboothUserUnregister = 'PHONEBOOTH_USER_UNREGISTER',
  DirectCallsDurationLimit = 'DIRECT_CALLS_DURATION_LIMIT',
  CreateStudioRoom = 'CREATE_STUDIO_ROOM',
  CreateRoom = 'CREATE_ROOM',
  DeleteRoom = 'DELETE_ROOM',
  CallsEditCustomItems = 'CALLS_EDIT_CUSTOM_ITEMS',
  CallsCustomItemsColumns = 'CALLS_CUSTOM_ITEMS_COLUMNS',
  CallsStudioRoomInvite = 'CALLS_STUDIO_ROOM_INVITE',
  CallsStudioAddExistingRoom = 'CALLS_STUDIO_ADD_EXISTING_ROOM',
  CallsStudioMobileCreateUser = 'CALLS_STUDIO_MOBILE_CREATE_USER',
  CallsStudioMobileAddUser = 'CALLS_STUDIO_MOBILE_ADD_USER',
  CallsStudioMobileEditUser = 'CALLS_STUDIO_MOBILE_EDIT_USER',
  CallsStudioMobileRemoveUser = 'CALLS_STUDIO_MOBILE_REMOVE_USER',
  CallsUpdateSubscription = 'CALLS_UPDATE_SUBSCRIPTION',
  CallsDisableSubscription = 'CALLS_DISABLE_SUBSCRIPTION',
  CallsPayment = 'CALLS_PAYMENT',
  CallsRateTooltip = 'CallS_RATE_TOOLTIP',
  ChangePlan = 'CHANGE_PLAN',
  ConvertFreePlan = 'CONVERT_FREE_PLAN',
  AgentActivationStatusChange = 'AGENT_ACTIVATION_STATUS_CHANGE',
  AgentConnectionStatusChange = 'AGENT_CONNECTION_STATUS_CHANGE',
  RegexEditor = 'REGEX_EDITOR',
  Uninstall = 'UNINSTALL',
  DeleteNexmoAccount = 'DELETE_NEXMO_ACCOUNT',
  IPRestrictionGuide = 'IP_RESTRICTION_GUIDE',
  CreateDeskBot = 'CREATE_DESK_BOT',
  DeactivateDeskBot = 'DEACTIVATE_DESK_BOT',
  DeleteDeskBot = 'DELETE_DESK_BOT',
  DeskAIPromotion = 'DESK_AI_PROMOTION',
  CustomerHideTickets = 'CUSTOMER_HIDE_TICKETS',
  PreviousChat = 'PREVIOUS_CHAT',
  SSOConfig = 'SSO_CONFIG',
  DeleteDeskSendbirdMessage = 'DELETE_DESK_SENDBIRD_MESSAGE',
  SessionTimeout = 'SESSION_TIMEOUT',
  EditQuickReplyCategory = 'EDIT_QUICK_REPLY_CATEGORY',
  DeleteQuickReplyCategory = 'DELETE_QUICK_REPLY_CATEGORY',
  CheckAllRequiredFieldsFilled = 'CHECK_ALL_REQUIRED_FIELDS_FILLED',
  DeactivateExtensionApp = 'DEACTIVATE_EXTENSION_APP',
  ToggleExtensionAppStatus = 'TOGGLE_EXTENSION_APP_STATUS',
  AddFlaggedUsers = 'ADD_FLAGGED_USER',
  DeleteFlaggedUsers = 'DELETE_FLAGGED_USER',
  CreateLiveEvent = 'CREATE_LIVE_EVENT',
  UseDefaultProfanityKeywords = 'USE_DEFAULT_PROFANITY_KEYWORD',
  DownloadRecording = 'DOWNLOAD_RECORDING',
  SelectPresetTemplate = 'SELECT_PRESET_TEMPLATE',
  CreateNotificationChannel = 'CREATE_NOTIFICATION_CHANNEL',
  CreateNotificationTag = 'CREATE_NOTIFICATION_TAG',
  ImportNotificationUsers = 'IMPORT_NOTIFICATION_USERS',
  AiBotEmbedding = 'AI_BOT_EMBEDDING',
  AiBotKnowledge = 'AI_BOT_KNOWLEDGE',
  AppSelector = 'APP_SELECTOR',
  AiBotSubscription = 'AI_BOT_SUBSCRIPTION',
  AiBotStudioPlugin = 'AI_BOT_STUDIO_PLUGIN',
  AiFeedback = 'AI_FEEDBACK',
  AiEmbedGuide = 'AI_EMBED_GUIDE',
  UIKitFeatureIntroduction = 'UI_KIT_FEATURE_INTRODUCTION',
  AddHostCandidate = 'ADD_HOST_CANDIDATE',
  ReleaseModeration = 'RELEASE_MODERATION',
  ChannelManager = 'CHANNEL_MANAGER',
  ExportData = 'EXPORT_DATA',
  FormGuide = 'FORM_GUIDE',
  FormPreview = 'FORM_PREVIEW',
  WhatsAppMetadata = 'WHATSAPP_METADATA',
  CopyTemplate = 'COPY_TEMPLATE',
  CopyTemplateGuide = 'COPY_TEMPLATE_GUIDE',
  PollDetail = 'POLL_DETAIL',
  TicketAssignment = 'TICKET_ASSIGNMENT',
  ModeratorGroupReassignment = 'MODERATOR_GROUP_REASSIGNMENT',
}
