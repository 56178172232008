import { css } from 'styled-components';

import { cssVariables, transitionDefault } from '@feather';

const theme = css`
  // Default link styles
  a {
    transition: color 0.2s ${transitionDefault};
    text-decoration: none;
    color: ${cssVariables('content-primary')};
    font-weight: 500;

    &:hover {
      color: ${cssVariables('content-primary-hover')};
      text-decoration: underline;
    }

    &:active {
      color: ${cssVariables('content-1')};
    }
  }

  // Default text selection styles
  ::selection {
    background: rgba(124, 96, 217, 1);
    color: white;
  }
`;

export default theme;
