import uniq from 'lodash/uniq';

import { axios, getGateURL } from '@api/shared';

export type SignInData = {
  token: string;
  two_factor_registration_required?: boolean;
  two_factor_authentication_required?: boolean;
  account_locked: boolean;
} & AccessControlSecurityOptions;

export const signIn = ({ email, password }: { email: string; password: string }) => {
  return axios.post<SignInData>(`${getGateURL()}/dashboard/auth/authenticate/`, {
    email,
    password,
  });
};

export const signOut = () => {
  return axios.post(`${getGateURL()}/dashboard_api/profiles/sign_out/`, {});
};

export const samlSigninInitiate = (payload: { slug_name: string }) => {
  return axios.post(`${getGateURL()}/dashboard_api/authentications/sso_saml/`, payload);
};

export const resendActivationMail = (payload: { email: string }) => {
  return axios.post(`${getGateURL()}/dashboard_api/authentications/resend_activation_mail/`, payload);
};

export const activateAccount: ActivateAccountAPI = (payload) => {
  return axios.get(`${getGateURL()}/dashboard_api/authentications/activate_user/`, {
    params: payload,
  });
};

export const signUp = (payload) => {
  return axios.post(`${getGateURL()}/dashboard/auth/signup/`, payload);
};

export const verifyAuth: VerifyAuthAPI = () => {
  return (
    axios
      .get(`${getGateURL()}/dashboard/auth/verify2/`)
      // TODO(MOD-6): Remove this replacement after backend migration
      .then((response) => {
        const permissions = [...response.data.role.permissions].map((permission) =>
          permission.replace(/^chat\.channels\./, 'moderation.'),
        );
        response.data.role.permissions = uniq(permissions);
        return response;
      })
  );
};

export const getDeskToken = (appId: string) => {
  return axios.get<{ token: string }>(`${getGateURL()}/dashboard_api/authentications/get_desk_token`, {
    headers: { 'App-Id': appId },
  });
};

export const verifyEmail = (payload: { token: string }) => {
  return axios.get(`${getGateURL()}/dashboard_api/profiles/verify_user_email/?token=${payload.token}`);
};

export const sendEmailVerificationMail = () => {
  return axios.post(`${getGateURL()}/dashboard_api/profiles/resend_email_verification_mail/`, {});
};

export const confirmEmailChange = (payload: { token: string; password: string }) => {
  return axios.post(`${getGateURL()}/dashboard_api/authentications/confirm_user_email_change/`, payload);
};

export const getEmailChange = (token: string) => {
  return axios.get(`${getGateURL()}/dashboard_api/authentications/get_user_email_change_request/?token=${token}`);
};

export const resetPassword = (payload) => {
  return axios.post(`${getGateURL()}/dashboard/auth/reset_password/`, payload);
};

export const forgotPassword = (email) => {
  return axios.post(`${getGateURL()}/dashboard/auth/forgot_password/`, {
    email,
  });
};

export const fetchInvitation = (invite_hash: string) => {
  return axios.get<Omit<Invitation, 'id'>>(`${getGateURL()}/dashboard/auth/invitation/${invite_hash}/`);
};

export const oauthGoogle = ({ idToken }) => {
  return axios.post(`${getGateURL()}/dashboard_api/authentications/oauth_google/`, {
    id_token: idToken,
  });
};

export const verifyTwoFactor = (code) => {
  return axios.post(`${getGateURL()}/dashboard_api/two_factor_authentications/verify/`, {
    code,
  });
};

export const recoverTwoFactor = (code: string) => {
  return axios.post<TwoFactorAuthentication>(`${getGateURL()}/dashboard_api/two_factor_authentications/recover/`, {
    code,
  });
};

export const resetTwoFactorAuth = (twoFactorAuthId: number) => {
  return axios.delete(`${getGateURL()}/dashboard_api/two_factor_authentications/${twoFactorAuthId}`);
};

export const proveGreenLantern = (payload: { eid: string; password: string; targetUserEmail: string }) => {
  return axios.post<AuthenticationResponse>(
    `${getGateURL()}/dashboard_api/authentications/team_account_login/`,
    payload,
    {},
  );
};

export const verifySSOCallbackToken = (token: string) => {
  return axios.post(`${getGateURL()}/dashboard/auth/set_token/`, { token });
};
