import axios from 'axios';

import { toast } from '@feather/components/notification';
import { isStaging } from '@utils';
import getErrorMessage from '@utils/getErrorMessage';

const toastBadRequestWarning = (error: any) => {
  if (isStaging()) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  if (axios.isAxiosError(error) && error?.response) {
    toast.warning({ message: getErrorMessage(error) });
  }
};

export default toastBadRequestWarning;
