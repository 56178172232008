import styled, { css } from 'styled-components';

import { cssVariables, transitionDefault } from '@feather';
import type { StyledProps } from '@ui';

/** @deprecated it overrides `type` attribute causing buggy behaviors on password inputs */
const BasicInput = styled.input.attrs<StyledProps>({
  type: 'text',
})<StyledProps>`
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 0 8px;
  height: 40px;
  color: ${cssVariables('neutral-10')};
  border: 1px solid ${cssVariables('neutral-4')};
  line-height: 1.5;
  border-radius: 4px;
  transition: all 0.25s ${transitionDefault};
  background: ${(props) => (props.readOnly ? cssVariables('neutral-1') : 'white')};
  cursor: ${(props) => (props.readOnly ? 'text' : 'auto')};
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border: 1px solid ${cssVariables('purple-7')};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  &:read-only {
    color: ${cssVariables('content-2')};
    background: ${cssVariables('neutral-3')};
    border-color: ${cssVariables('neutral-3')};
  }

  &:disabled {
    color: ${cssVariables('content-disabled')};
    border-color: ${cssVariables('border-disabled')};
  }

  ${(props) => props.styles}
`;

const BasicTextarea = styled.textarea<StyledProps>`
  display: block;
  width: 100%;
  font-size: 14px;
  background: white;
  padding: 4px 12px 5px;
  border: 1px solid ${cssVariables('neutral-4')};
  color: ${cssVariables('neutral-10')};
  line-height: 1.5;
  border-radius: 4px;
  resize: vertical;
  transition: all 0.2s ${transitionDefault};

  &:focus {
    outline: none;
    border: 1px solid ${cssVariables('purple-7')};
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.28);
  }

  ${(props) => props.styles};
  ${(props) =>
    props.disabled &&
    css`
      background: ${cssVariables('neutral-1')};
      color: ${cssVariables('neutral-5')};
    `}
`;

// InputAddons

const InputIndicator = styled.span<StyledProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: ${cssVariables('neutral-5')};
  text-align: center;
  user-select: none;
  border: 1px solid ${(props) => (props.hasError ? cssVariables('red-5') : cssVariables('neutral-3'))};
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.2s ${transitionDefault};
  ${(props) => props.styles};
`;

export { BasicInput, BasicTextarea, InputIndicator };
