import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { getApplicationSummary } from '@core/api/application';
import {
  addCredentialsFilter,
  fetchPushMessageTemplates,
  getWebhooksAllCategories,
  getWebhooksInformation,
  removeCredentialsFilter,
  togglePushEnabled,
  updateAccessTokenUserPolicy,
  updateAutoEventMessage,
  updateIgnoreMessageOffset,
  updateMaxLengthMessage,
  updateModerationInfoADMM,
  updatePushMessageTemplates,
  updateWebhookInformation,
} from '@core/api/settings';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import { toast } from '@feather/components/notification';
import logException from '@utils/logException';
import {
  ALERT_SETTINGS_ACCESS_TOKEN_POLICY,
  ALERT_SETTINGS_AUTO_EVENT_MESSAGE,
  ALERT_SETTINGS_CREDENTIAL_FILTER_ADDED,
  ALERT_SETTINGS_CREDENTIAL_FILTER_DELETED,
  ALERT_SETTINGS_DISPLAY_PAST_MESSAGE_OFF,
  ALERT_SETTINGS_DISPLAY_PAST_MESSAGE_ON,
  ALERT_SETTINGS_MAX_LENGTH_OF_MESSAGE,
  ALERT_SETTINGS_PUSH_DISABLED,
  ALERT_SETTINGS_PUSH_ENABLED,
  ALERT_SETTINGS_PUSH_NOTIFICATION_TEMPLATES,
  ALERT_SETTINGS_WEBHOOKS,
} from '@utils/text';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { ApplicationActions } from './application';

export const SettingsActions = {
  applicationDeleted: createAction<{ appId: Application['app_id'] }>('core/settings/applicationDeleted'),

  // push
  togglePushEnabled: createAsyncThunk<void, boolean>(
    'core/settings/togglePushEnabled',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const application = selectApplicationData(getState());

      try {
        const { data } = await togglePushEnabled({ appId: application.app_id, push_enabled: payload });

        toast.success({ message: payload ? ALERT_SETTINGS_PUSH_ENABLED : ALERT_SETTINGS_PUSH_DISABLED });
        dispatch(ApplicationActions.applicationSet({ ...application, push_enabled: data.application.push_enabled }));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  fetchPushMessageTemplates: createAsyncThunk<void>(
    'core/settings/fetchPushMessageTemplates',
    async (_, { getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;

      try {
        await fetchPushMessageTemplates({ appId });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  updatePushMessageTemplates: createAsyncThunk<
    void,
    {
      templateName: 'default' | 'alternative';
      payload: { template: { MESG: PushMessageTemplate; FILE: PushMessageTemplate; ADMM: PushMessageTemplate } };
    }
  >('core/settings/updatePushMessageTemplates', async (payload, { dispatch, getState, rejectWithValue }) => {
    const application = selectApplicationData(getState());

    try {
      const { data } = await updatePushMessageTemplates({
        appId: application.app_id,
        templateName: payload.templateName,
        payload: payload.payload,
      });

      const newApp = { ...application };
      data.push_message_templates.forEach(({ template_name, template }) => {
        newApp.attrs = {
          ...newApp.attrs,
          push_message_templates: {
            ...newApp.attrs.push_message_templates,
            [template_name]: template,
          },
        };
      });

      toast.success({ message: ALERT_SETTINGS_PUSH_NOTIFICATION_TEMPLATES });
      dispatch(ApplicationActions.applicationSet(newApp));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  getWebhookAllCategories: createAsyncThunk<string[]>(
    'core/settings/getWebhookAllCategories',
    async (_, { getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;

      try {
        const { data } = await getWebhooksAllCategories({ appId });

        return data.webhook ? data.webhook.all_webhook_categories : data.all_webhook_categories;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  getWebhooksInformation: createAsyncThunk<WebhookSetting>(
    'core/settings/getWebhookInformation',
    async (_, { getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;

      try {
        const { data } = await getWebhooksInformation({ appId });

        return data.webhook;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  updateWebhookInformation: createAsyncThunk<
    WebhookSetting,
    {
      enabled_events?: WebhookEvents[];
      enabled: boolean;
      include_members: boolean;
      include_unread_count: boolean;
      url: string;
    }
  >('core/settings/updateWebhookInformation', async (payload, { dispatch, getState, rejectWithValue }) => {
    const application = selectApplicationData(getState());

    try {
      const { data } = await updateWebhookInformation({
        appId: application.app_id,
        enabled: payload.enabled,
        enabled_events: payload.enabled_events,
        url: payload.url,
        include_members: payload.include_members,
        include_unread_count: payload.include_unread_count,
      });

      const newApp = {
        ...application,
        attrs: {
          ...application.attrs,
          webhook: data.webhook as unknown as WebhookAttribute,
        },
      };

      toast.success({ message: ALERT_SETTINGS_WEBHOOKS });
      dispatch(ApplicationActions.applicationSet(newApp));
      return data.webhook;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  updateMaxLengthMessage: createAsyncThunk<void, { max_length_message: number }>(
    'core/settings/updateMaxLengthMessage',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const application = selectApplicationData(getState());

      try {
        const { data } = await updateMaxLengthMessage({
          appId: application.app_id,
          max_length_message: payload.max_length_message,
        });

        const newApp = {
          ...application,
          max_length_message: data.max_length_message,
        };

        toast.success({ message: ALERT_SETTINGS_MAX_LENGTH_OF_MESSAGE });
        dispatch(ApplicationActions.applicationSet(newApp));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  updateIgnoreMessageOffset: createAsyncThunk<void, boolean>(
    'core/settings/updateIgnoreMessageOffset',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const application = selectApplicationData(getState());

      try {
        const { data } = await updateIgnoreMessageOffset({
          appId: application.app_id,
          ignore_message_offset: payload,
        });

        const newApp = {
          ...application,
          ignore_message_offset: data.ignore_message_offset,
        };

        data.ignore_message_offset
          ? toast.success({ message: ALERT_SETTINGS_DISPLAY_PAST_MESSAGE_ON })
          : toast.success({ message: ALERT_SETTINGS_DISPLAY_PAST_MESSAGE_OFF });
        dispatch(ApplicationActions.applicationSet(newApp));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  updateAutoEventMessage: createAsyncThunk<
    void,
    {
      auto_event_message: Omit<
        AutoEventMessageAttribute,
        'whitelist_customTypes' | 'message_templates' | 'include_last_message' | 'only_update_last_message_in_super'
      >;
    }
  >('core/settings/updateAutoEventMessage', async (payload, { dispatch, getState, rejectWithValue }) => {
    const application = selectApplicationData(getState());

    try {
      const { data } = await updateAutoEventMessage({
        appId: application.app_id,
        auto_event_message: payload.auto_event_message,
      });

      const newApp = {
        ...application,
        attrs: {
          ...application.attrs,
          auto_event_message: data.auto_event_message,
        },
      };

      toast.success({ message: ALERT_SETTINGS_AUTO_EVENT_MESSAGE });
      dispatch(ApplicationActions.applicationSet(newApp));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  updateAccessTokenUserPolicy: createAsyncThunk<void, { guest_user_policy: 0 | 1 | 2 }>(
    'core/settings/updateAccessTokenUserPolicy',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const application = selectApplicationData(getState());

      try {
        const { data } = await updateAccessTokenUserPolicy({
          appId: application.app_id,
          guest_user_policy: payload.guest_user_policy,
        });

        const newApp = {
          ...application,
          guest_user_policy: data.guest_user_policy,
        };

        toast.success({ message: ALERT_SETTINGS_ACCESS_TOKEN_POLICY });
        dispatch(ApplicationActions.applicationSet(newApp));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  addCredentialsFilter: createAsyncThunk<void, { allowedDomain: string }>(
    'core/settings/addCredentialsFilter',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const application = selectApplicationData(getState());

      try {
        const { data } = await addCredentialsFilter({
          appId: application.app_id,
          credentials_key: payload.allowedDomain,
        });

        const newApp = {
          ...application,
          credentials_list: application.credentials_list.concat([data]),
        };

        toast.success({ message: ALERT_SETTINGS_CREDENTIAL_FILTER_ADDED });
        dispatch(ApplicationActions.applicationSet(newApp));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  removeCredentialsFilter: createAsyncThunk<void, string>(
    'core/settings/removeCredentialsFilter',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const application = selectApplicationData(getState());

      try {
        const { data } = await removeCredentialsFilter({
          appId: application.app_id,
          id: payload,
        });

        const newApp = {
          ...application,
          credentials_list: application.credentials_list.filter((credential) => {
            return credential.id !== data.id;
          }),
        };

        toast.success({ message: ALERT_SETTINGS_CREDENTIAL_FILTER_DELETED });
        dispatch(ApplicationActions.applicationSet(newApp));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  fetchModeratorInfoADMM: createAsyncThunk<boolean>(
    'core/settings/fetchModeratorInfoADMM',
    async (_, { getState, rejectWithValue }) => {
      const { app_id } = selectApplicationData(getState());

      try {
        const { data } = await getApplicationSummary({ app_id });

        return data.is_moderator_info_in_admin_message;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  updateModeratorInfoADMM: createAsyncThunk<boolean, boolean>(
    'core/settings/updateModeratorInfoADMM',
    async (payload, { getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;

      try {
        const { data } = await updateModerationInfoADMM({ appId, is_moderator_info_in_admin_message: payload });

        toast.success({ message: 'Moderation information in Admin Message has been changed' });
        return data.is_moderator_info_in_admin_message;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
};
