import type { ReactNode } from 'react';

import startCase from 'lodash/startCase';
import styled from 'styled-components';

import { FeatureAvailability } from '@constants';
import { LinkVariant, cssVariables } from '@feather';
import { Button } from '@feather/components/button';
import * as Icons from '@feather/components/icons';
import { Body, Headings } from '@feather/typography';
import useAppId from '@hooks/useAppId';
import useAuthorization from '@hooks/useAuthorization';
import useTranslationSync from '@hooks/useTranslationSync';
import type { PropsOf } from '@utils';

import { LinkWithPermissionCheck } from '../LinkWithPermissionCheck';
import { CenteredPageContainer } from '../grid';
import messages from './translation/en.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
`;

const Title = styled.h1`
  ${Headings['heading-05']};
  text-align: center;
`;

const Description = styled.div`
  ${Body['body-short-01']};
  color: ${cssVariables('content-2')};
  text-align: center;

  & + & {
    margin-top: 12px;
  }

  ${Title} + & {
    margin-top: 12px;
  }
`;

const Action = styled.div`
  padding: 24px 0;
`;

type Props = Omit<PropsOf<typeof LinkWithPermissionCheck>, 'permissions' | 'href' | 'useReactRouter'>;

const ApplicationSettingLink = ({ variant = LinkVariant.Inline, ...props }: Props) => {
  const appId = useAppId();
  return (
    <LinkWithPermissionCheck
      variant={variant}
      {...props}
      permissions={['application.settings.view', 'application.settings.all']}
      href={`/${appId}/settings/features`}
    />
  );
};

type UnavailableFeatureGuideProps = {
  description: string;
  featureName: 'Data exports' | 'Advanced analytics' | 'Announcements' | 'Moderator messages';
  availability: FeatureAvailability;
  linkToSettingRenderer?: ReactNode;
};

const UnavailableFeatureGuide = ({
  description,
  featureName,
  availability,
  linkToSettingRenderer,
}: UnavailableFeatureGuideProps) => {
  const { isPermitted } = useAuthorization();
  const { t } = useTranslationSync(() => messages);

  const isPermittedToOrganizationSettings = isPermitted(['organization.general.view', 'organization.general.all']);

  if (availability === FeatureAvailability.NotSupportedPlan) {
    return (
      <CenteredPageContainer>
        <Container>
          <Icons.UpgradeColored size={80} style={{ marginBottom: 16 }} />
          <Title>
            {featureName === 'Data exports' ? t('upgradePlan.title.withoutPro') : t('upgradePlan.title.withPro')}
          </Title>
          <Description>{description}</Description>
          <Description>{t('upgradePlan.footer')}</Description>
          <Action>
            <LinkWithPermissionCheck
              permissions={['organization.general.view', 'organization.general.all']}
              href="/settings/general"
            >
              <Button buttonType="primary" disabled={!isPermittedToOrganizationSettings}>
                {t('upgradePlan.button')}
              </Button>
            </LinkWithPermissionCheck>
          </Action>
        </Container>
      </CenteredPageContainer>
    );
  }
  return (
    <CenteredPageContainer>
      <Container>
        <Icons.TogglesColored size={80} style={{ marginBottom: 16 }} />
        <Title>{t('featureOn.title', { featureName: startCase(featureName) })}</Title>
        <Description>{description}</Description>
        {linkToSettingRenderer ?? (
          <Description style={{ whiteSpace: 'pre' }}>
            {t('featureOn.description', {
              featureName: <b>{featureName}</b>,
              a: (text) => <ApplicationSettingLink>{text}</ApplicationSettingLink>,
            })}
          </Description>
        )}
      </Container>
    </CenteredPageContainer>
  );
};

export default UnavailableFeatureGuide;
