import type { AxiosPromise } from 'axios';
import axiosModule from 'axios';

import setupAxiosInterceptors from '@api/setupAxiosInterceptors';
import { axios, getGateURL } from '@api/shared';

const gateAxios = axiosModule.create({ baseURL: getGateURL(), withCredentials: true });
setupAxiosInterceptors(gateAxios);

export const createOrganization: CreateOrganizationAPI = ({
  organizationName: organization_name,
  contact,
  shopify_signup_token,
}) => {
  return axios.post(`${getGateURL()}/dashboard_api/organizations/`, {
    organization_name,
    contact,
    shopify_signup_token,
  });
};

export const fetchOrganization: FetchOrganizationAPI = (organization_uid) => {
  return axios.get(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/`);
};

export const updateOrganization: UpdateOrganizationAPI = (organization_uid, payload) => {
  return axios.patch(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/`, payload);
};

export const fetchOrganizationMembers: FetchOrganizationMembersAPI = ({ uid, params }) => {
  return axios.get(`${getGateURL()}/dashboard_api/organizations/${uid}/members/`, {
    params,
  });
};

export const updateOrganizationMemberRole: UpdateOrganizationMemberRoleAPI = ({ uid, ...payload }) => {
  return axios.put(`${getGateURL()}/dashboard/organization/${uid}/members/`, payload);
};

export const deleteOrganizationMember = ({ uid, email }: { uid: string; email: string }) => {
  return axios.delete(`${getGateURL()}/dashboard/organization/${uid}/members/`, {
    data: {
      email,
    },
  });
};

export const exportOrganizationMembersList = (organization_uid: string) => {
  return axios.get(`${getGateURL()}/dashboard/organization/${organization_uid}/export_member_list/`, {
    headers: {
      'Content-Type': 'text/csv',
    },
  });
};

export const inviteMember = (payload: { email: string; organization_uid: string; role: string }) => {
  return axios.post<Omit<Invitation, 'id'>>(`${getGateURL()}/dashboard/organization/invitation/`, payload);
};

export const fetchOrganizationInvitations: FetchOrganizationInvitationsAPI = ({ uid, params }) => {
  return axios.get(`${getGateURL()}/dashboard_api/organizations/${uid}/invitations/`, {
    params,
  });
};

export const updateInvitation = (id: string) => {
  return axios.put<Omit<Invitation, 'id'>>(`${getGateURL()}/dashboard/organization/invitation/${id}/`, {});
};

export const cancelInvitation = (id: string) => {
  return axios.delete<{}>(`${getGateURL()}/dashboard/organization/invitation/${id}/`);
};

export const setLoginIPRanges = ({
  loginIPRanges,
  ignore_cidr_on_two_factor_authentication,
}: SetLoginIPRangesPayload) => {
  const login_cidr_list = loginIPRanges;

  return axios.put<{ organization: Organization }>(`${getGateURL()}/dashboard/auth/login_ip_range/`, {
    login_cidr_list,
    ignore_cidr_on_two_factor_authentication,
  });
};

export const updateSamlConfiguration: UpdateSamlConfigurationAPI = (organization_uid, payload) => {
  return axios.post(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/sso_profile/`, payload);
};

export const deleteSamlConfiguration: DeleteSamlConfigurationAPI = (organization_uid) => {
  return axios.delete(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/sso_profile/`);
};

/**
 *
 * @param payload Transfer payload with organization uid and target member's email
 * @param payload.organization_uid Current organization's uid
 * @param payload.email Target member's email
 */
export const transferOwner: TransferOwnerAPI = ({ organization_uid, email }: TransferOwnerPayload) => {
  return axios.post(`${getGateURL()}/dashboard/organization/${organization_uid}/transfer_owner/`, {
    email,
  });
};

export const fetchMemberRoles: FetchMemberRolesAPI = ({ uid, limit, offset }) => {
  return gateAxios.get(`${getGateURL()}/dashboard_api/organizations/${uid}/member_roles/`, {
    params: { limit, offset },
  });
};

export const getOrganizationAPIKey = ({ organization_uid }) => {
  return axios.get(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/api_key/`);
};

type FetchMemberRoleResponse = (roleId: string) => AxiosPromise<MemberRole>;

export const fetchMemberRole: FetchMemberRoleResponse = (roleId: string) => {
  return axios.get(`${getGateURL()}/dashboard_api/organization_member_roles/${roleId}/`);
};

export const fetchMemberRoleByName: FetchMemberRoleByNameAPI = (name) => {
  return axios.get(`${getGateURL()}/dashboard_api/organization_member_roles/get_by_name/?name=${name}`);
};

export const fetchPermissions: FetchPermissionsAPI = (uid: string) => {
  return gateAxios.get(`/dashboard_api/organizations/${uid}/permissions/`);
};

export const createMemberRole: CreateMemberRoleAPI = (payload) => {
  return axios.post(`${getGateURL()}/dashboard_api/organization_member_roles/`, payload);
};

export const editMemberRole: EditMemberRoleAPI = ({ roleId, payload }) => {
  return axios.patch(`${getGateURL()}/dashboard_api/organization_member_roles/${roleId}/`, payload);
};

export const deleteMemberRole: DeleteMemberRoleAPI = ({ roleId }) => {
  return axios.delete(`${getGateURL()}/dashboard_api/organization_member_roles/${roleId}/`);
};

export const renewOrganizationAPIKey = ({ organization_uid }) => {
  return axios.patch(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/renew_api_key/`, {});
};

export const startFreeTrial = () => {
  return axios.post(`${getGateURL()}/dashboard_api/billing/subscriptions/start_free_trial/`, {});
};

export const enforce2FA = ({ uid, enforce }: Enforce2FAPayload) => {
  return axios.post<{
    organization: Organization;
  }>(`${getGateURL()}/dashboard/auth/enforce_2fa/`, { uid, enforce });
};

export type AccessControlSecurityPayload = {
  organization_uid: Organization['uid'];
} & AccessControlSecurityOptions;

export const updateAccessControlSecurity = (payload: AccessControlSecurityPayload) => {
  return axios.patch<{ organization: Organization; token: string }>(
    `${getGateURL()}/dashboard/auth/security/`,
    payload,
  );
};
