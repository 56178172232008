import { Suspense, useEffect } from 'react';

import '@stripe/stripe-js';
import 'element-scroll-polyfill';
import { useIntl } from 'react-intl';

import { SubscriptionInfoContextProvider } from '@/SubscriptionInfoContext';
import { SupportPlanContextProvider } from '@/SupportPlanContext';
import { useDispatch } from '@/redux/hooks';
import { useImagePreview } from '@/redux/hooks/imagePreview';
import { Outlet } from '@/router';
import useSentryApplicationTracker from '@/useSentryApplicationTracker';
import useSentryUserTracker from '@/useSentryUserTracker';
import { CallsVoucherProvider } from '@common/containers/CallsVoucherContext';
import { CurrentChatSubscriptionProvider } from '@common/containers/CurrentChatSubscriptionProvider';
import { FullScreenModals } from '@common/containers/FullScreenModals';
import { Dialogs } from '@common/containers/dialogs';
import { ImagePreview } from '@common/containers/imagePreview';
import { ImagePreviewActions } from '@common/redux/actions/imagePreview';
import useAppUpdateToast from '@hooks/useAppUpdateToast';
import GainsightInitializer from '@hooks/useGainsight';
import useListenLocationPathChange from '@hooks/useListenLocationPathChange';
import { FullScreenModalContextProvider } from '@ui/components/FullScreenModal/context';
import { DrawerContextProvider } from '@ui/components/drawer';

const useImagePreviewEscKeyHandler = () => {
  const isImagePreviewVisible = useImagePreview((imagePreview) => imagePreview.images.length > 0);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isImagePreviewVisible) {
      const onEscKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          dispatch(ImagePreviewActions.hideImagePreview());
        }
      };
      document.addEventListener('keydown', onEscKeyDown);
      return () => {
        document.removeEventListener('keydown', onEscKeyDown);
      };
    }
  });
};

const useUpdateWindowIntl = () => {
  const intl = useIntl();
  useEffect(() => {
    window.intl = intl;
  }, [intl]);
};

const RootLayout = () => {
  useImagePreviewEscKeyHandler();
  useUpdateWindowIntl();
  useSentryUserTracker();
  useSentryApplicationTracker();
  useListenLocationPathChange();
  useAppUpdateToast();

  return (
    <CurrentChatSubscriptionProvider>
      <SubscriptionInfoContextProvider>
        <SupportPlanContextProvider>
          <CallsVoucherProvider>
            <FullScreenModalContextProvider>
              <DrawerContextProvider>
                <Suspense fallback={null}>
                  <GainsightInitializer />
                  <Outlet />
                  <FullScreenModals />
                  <Dialogs />
                  <ImagePreview />
                </Suspense>
              </DrawerContextProvider>
            </FullScreenModalContextProvider>
          </CallsVoucherProvider>
        </SupportPlanContextProvider>
      </SubscriptionInfoContextProvider>
    </CurrentChatSubscriptionProvider>
  );
};

export default RootLayout;
