import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { browserRouter } from '@/browserRouter';
import createAsyncThunk from '@/redux/createAsyncThunk';
import { getDeskToken } from '@common/api/authentication';
import { DialogsActions } from '@common/redux/actions/dialogs';
import type { AgentConnection } from '@constants/index';
import { DeskSettingsPage } from '@constants/index';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import {
  createProjectApiKey,
  deleteProjectApiKey,
  deskAuthentication,
  fetchProjectApiKeys,
  updateProjectSetting,
} from '@desk/api/desk';
import { toast } from '@feather/components/notification';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { selectDeskProject } from '../selectors/desk';

export const DeskActions = {
  deskAuthentication: createAsyncThunk<void>(
    'desk/desk/deskAuthentication',
    async (_, { dispatch, getState, rejectWithValue }) => {
      const application = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());
      const userEmail = getState().auth.user.email;

      try {
        const {
          data: { token },
        } = await getDeskToken(application?.app_id || '');
        const { data } = await deskAuthentication(pid, application.region, { token });

        dispatch(
          DeskActions.deskAuthenticated({
            id: data.agent.user,
            agent: { ...data.agent, project: data.project, email: userEmail },
            project: data.project,
          }),
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          browserRouter.navigate('/');
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  deskAuthenticated: createAction<{ id: AuthAgent['id']; agent: AuthAgent; project: Project }>(
    'desk/desk/deskAuthenticated',
  ),

  updateProject: createAsyncThunk<
    void,
    {
      type:
        | DeskSettingsPage.general
        | DeskSettingsPage.automation
        | DeskSettingsPage.triggers
        | DeskSettingsPage.security
        | DeskSettingsPage.aiFeatures;
      payload: Partial<Project>;
      options?: { snoozeDefaultToast?: boolean };
    }
  >('desk/desk/updateProject', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { snoozeDefaultToast } = payload.options ?? { snoozeDefaultToast: false };

    try {
      const { data } = await updateProjectSetting(pid, region, { type: payload.type, payload: payload.payload });

      dispatch(DeskActions.setDeskProject(data));
      if (!snoozeDefaultToast) {
        toast.success({ message: window.intl.formatMessage({ id: 'desk.settings.message.updateProject.success' }) });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!snoozeDefaultToast) {
          toastBadRequestWarning(error);
        }
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  updateOperationHours: createAsyncThunk<void, any /* FIXME(DFE-1056): Operation Hours FormValues */>(
    'desk/desk/updateOperationHours',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await updateProjectSetting(pid, region, {
          type: DeskSettingsPage.general,
          payload,
        });

        dispatch(DeskActions.setDeskProject(data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  fetchApiTokens: createAsyncThunk<
    { count: number; results: ApiToken[]; pagination: LimitOffsetPagination },
    { offset: number; limit: number }
  >('desk/desk/fetchApiTokens', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await fetchProjectApiKeys(pid, region, { offset: payload.offset, limit: payload.limit });

      dispatch(DeskActions.apiTokenPaginationSet({ offset: payload.offset, limit: payload.limit }));
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),
  apiTokenPaginationSet: createAction<{ offset: number; limit: number }>('desk/desk/apiTokenPaginationSet'),

  createApiToken: createAsyncThunk<void, CreateProjectApiKeyPayload['payload']>(
    'desk/desk/createApiToken',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());
      const { limit, count } = getState().desk.apiTokens.pagination;
      const offset = Math.floor((count + 1) / limit) * limit;

      try {
        dispatch(DialogsActions.setIsFetching(true));
        await createProjectApiKey(pid, region, { payload });

        dispatch(DeskActions.fetchApiTokens({ limit, offset }));
        dispatch(DialogsActions.hideDialog());
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      } finally {
        dispatch(DialogsActions.setIsFetching(false));
      }
    },
  ),
  deleteApiToken: createAsyncThunk<void, { id: number; isLastItemOnPage: boolean }>(
    'desk/desk/deleteApiToken',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        await deleteProjectApiKey(pid, region, { id: payload.id });

        const pagination = { ...getState().desk.apiTokens.pagination };
        if (payload.isLastItemOnPage) {
          pagination.offset = Math.max(0, pagination.offset - pagination.limit);
        }
        dispatch(DeskActions.fetchApiTokens(pagination));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  setAgentConnection: createAction<AgentConnection>('desk/desk/setAgentConnection'),
  setDeskConnected: createAction<boolean>('desk/desk/setDeskConnected'),
  setDeskProject: createAction<Partial<Project>>('desk/desk/setDeskProject'),
  setDeskAgent: createAction<AuthAgent>('desk/desk/setDeskAgent'),
  resetDesk: createAction('desk/desk/resetDesk'),
};
