import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { browserRouter } from '@/browserRouter';
import createAsyncThunk from '@/redux/createAsyncThunk';
import { AGENT_GROUP_LIST_LIMIT } from '@constants';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import { deleteAgentGroup, fetchAgentGroups } from '@desk/api/agentGroups';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { selectDeskProject } from '../selectors/desk';

export const AgentGroupsActions = {
  fetchAgentGroups: createAsyncThunk<
    { items: AgentGroup<'listItem'>[]; pagination: { offset: number; limit: number; count: number; page: number } },
    FetchAgentGroupsPayload
  >('desk/agentGroups/fetchAgentGroups', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { offset = 0, limit = AGENT_GROUP_LIST_LIMIT, query } = payload;

    try {
      const { data } = await fetchAgentGroups(pid, region, { offset, limit, query });

      return {
        items: data.results,
        pagination: {
          offset,
          limit,
          count: data.count,
          page: offset / limit + 1,
        },
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  deleteAgentGroup: createAsyncThunk<void, DeleteAgentGroupPayload>(
    'desk/agentGroups/deleteAgentGroup',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { app_id, region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());
      const { groupId, transferTargetGroupId, needFetchList = false } = payload;

      try {
        await deleteAgentGroup(pid, region, { groupId, transferTargetGroupId });

        if (needFetchList) {
          dispatch(AgentGroupsActions.fetchAgentGroups({}));
        }
        browserRouter.navigate(`/${app_id}/desk/settings/teams`);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  agentGroupQueryUpdated: createAction<UpdateAgentGroupQueryPayload>('desk/agentGroups/agentGroupQueryUpdated'),

  agentGroupsReset: createAction('desk/agentGroups/agentGroupsReset'),
};
