import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';

import styled, { css } from 'styled-components';

import { useDispatch } from '@/redux/hooks';
import { useImagePreview } from '@/redux/hooks/imagePreview';
import { ImagePreviewActions } from '@common/redux/actions/imagePreview';
import { NAVIGATION_BAR_HEIGHT } from '@constants/ui';
import * as Icons from '@feather/components/icons';
import { Tooltip } from '@feather/components/tooltip';
import useDimension from '@hooks/useDimension';
import useOrganization from '@hooks/useOrganization';
import { Overlay } from '@ui/components/overlay';
import { transitionDefault } from '@ui/styles';

const ImagePreviewContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  user-select: none;
`;

const ImageHolder = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  user-select: initial;
  margin: auto;
`;

const ImageTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: ${NAVIGATION_BAR_HEIGHT}px;
  display: flex;
`;

const ImageName = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 24px;
`;

const ImageMenu = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const ImageMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.25s ${transitionDefault};
  margin-right: 16px;
  border: 0;
  border-radius: 2px;
  background: transparent;
  cursor: pointer;
  width: 40px;
  height: 40px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  &:last-child {
    margin-right: 13px;
  }
`;

const Images = forwardRef<HTMLDivElement, { images: ImagePreview[] }>(({ images }, ref) => {
  const dimension = useDimension();
  const maxHeight = dimension.y * 0.78;
  return (
    <ImageHolder ref={ref}>
      {useMemo(
        () =>
          images.map((image, index) => {
            return (
              <img
                key={`image_${index}`}
                src={image.url}
                style={{
                  maxHeight,
                }}
                alt={image.name}
              />
            );
          }),
        [images, maxHeight],
      )}
    </ImageHolder>
  );
});

export const ImagePreview = () => {
  const dispatch = useDispatch();

  const imagePreview = useImagePreview();
  const isPreventDownloadFilesEnabled = useOrganization(
    (organization) => organization.attributes?.isDeskSettingsPreventDownloadFilesEnabled,
  );

  const hide = useCallback(() => {
    dispatch(ImagePreviewActions.hideImagePreview());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('popstate', hide);
    return () => {
      window.removeEventListener('popstate', hide);
    };
  }, [hide]);

  const holderComponentRef = useRef<HTMLDivElement>(null);
  const topComponentRef = useRef<HTMLDivElement>(null);

  const handleContainerMouseDown: MouseEventHandler<HTMLDivElement> = (ev) => {
    const isClickInside =
      (holderComponentRef.current !== null && holderComponentRef.current.contains(ev.target as Node)) ||
      (topComponentRef.current !== null && topComponentRef.current.contains(ev.target as Node));

    if (!isClickInside) {
      hide();
    }
  };

  const handleClickDownload = (image: ImagePreview) => {
    window.open(image.url);
  };

  const isOpen = imagePreview.images.length > 0;
  return (
    <Overlay
      isOpen={isOpen}
      hasBackdrop={true}
      backdropStyles={css`
        background-color: rgba(0, 0, 0, 0.8);
      `}
      onClose={hide}
    >
      {isOpen ? (
        <ImagePreviewContainer onClick={handleContainerMouseDown} data-test-id="ImagePreview">
          <ImageTop ref={topComponentRef}>
            <ImageName>{imagePreview.images[0].name}</ImageName>
            <ImageMenu>
              {!isPreventDownloadFilesEnabled && (
                <Tooltip content="Download" placement="bottom">
                  <ImageMenuButton aria-label="Download" onClick={() => handleClickDownload(imagePreview.images[0])}>
                    <Icons.Download size={24} color="white" />
                  </ImageMenuButton>
                </Tooltip>
              )}
              <Tooltip content="Close" placement="bottom">
                <ImageMenuButton aria-label="Close" onClick={hide}>
                  <Icons.Close size={24} color="white" />
                </ImageMenuButton>
              </Tooltip>
            </ImageMenu>
          </ImageTop>
          <Images ref={holderComponentRef} images={imagePreview.images} />
        </ImagePreviewContainer>
      ) : (
        ''
      )}
    </Overlay>
  );
};
