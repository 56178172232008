import type { SetupIntent } from '@stripe/stripe-js';

import { axios, getGateURL } from '@api/shared';

export const fetchCardInfo = ({ organization_uid }) => {
  return axios.get(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/card/`);
};

export const initCardRegistration: InitCardRegistrationAPI = (organization_uid) => {
  return axios.post(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/init_card_registration/`, {});
};

export const registerCard = (organization_uid: string, payment_method_id: SetupIntent['payment_method']) => {
  return axios.post(`${getGateURL()}/dashboard_api/organizations/${organization_uid}/card/`, {
    payment_method: payment_method_id,
  });
};

export const fetchCurrentSubscription: FetchCurrentSubscriptionAPI = (product) => {
  return axios.get(`${getGateURL()}/dashboard_api/billing/subscriptions/current/?product=${product}`);
};

export const fetchSubscriptionInfo: FetchSubscriptionInfoAPI = (product) => {
  return axios.get(`${getGateURL()}/dashboard_api/billing/subscriptions/info/?product=${product}`);
};

export const fetchSubscriptionByMonth: FetchSubscriptionByMonthAPI = ({ month, product }) => {
  return axios.get(`${getGateURL()}/dashboard_api/billing/subscriptions/month/?month=${month}&product=${product}`);
};

export const subscribeSubscriptionPlan: SubscribeSubscriptionPlanAPI = (payload) => {
  return axios.post(`${getGateURL()}/dashboard_api/billing/subscriptions/subscribe/`, payload);
};

export const unsubscribeSubscriptionPlan: UnsubscribeSubscriptionPlanAPI = ({ subscription_id, endReason }) => {
  return axios.post(`${getGateURL()}/dashboard_api/billing/subscriptions/${subscription_id}/unsubscribe/`, {
    end_reason: endReason,
  });
};

export const cancelUnsubscribeSubscription: CancelUnsubscribeSubscriptionAPI = (subscription_id) => {
  return axios.post(`${getGateURL()}/dashboard_api/billing/subscriptions/${subscription_id}/cancel_unsubscribe/`, {});
};

export const cancelDowngradeSubscription: CancelDowngradeSubscriptionAPI = (subscription_id) => {
  return axios.post(`${getGateURL()}/dashboard_api/billing/subscriptions/${subscription_id}/cancel_downgrade/`, {});
};

export const fetchInvoices: FetchInvoicesAPI = (params) => {
  return axios.get(`${getGateURL()}/dashboard_api/billing/invoices/`, {
    params,
  });
};

export const fetchBillingContacts: FetchBillingContactAPI = (uid) => {
  return axios.get(`${getGateURL()}/dashboard_api/organizations/${uid}/billing_contacts/`);
};

export const updateBillingContacts: UpdateBillingContactsAPI = ({ uid, payload }) => {
  return axios.put(`${getGateURL()}/dashboard_api/organizations/${uid}/billing_contacts/`, payload);
};

export const fetchSubscriptionPlans: FetchSubscriptionPlansAPI = (organization_uid) => {
  return axios.get(`${getGateURL()}/dashboard_api/billing/plans/?organization_uid=${organization_uid}`);
};

export const fetchVoucherSubscription = () => {
  return axios.get<VoucherSubscription>(`${getGateURL()}/dashboard_api/billing/vouchers/subscription/`, {});
};

export const updateVoucherSubscription = (payload: VoucherSubscription) => {
  return axios.put<VoucherSubscription>(`${getGateURL()}/dashboard_api/billing/vouchers/subscription/`, payload, {});
};

export const deleteVoucherSubscription = () => {
  return axios.delete<VoucherSubscription>(`${getGateURL()}/dashboard_api/billing/vouchers/subscription/`, {});
};

export const fetchVouchers = (params?: {
  status?: 'READY' | 'ACTIVE' | 'EXPIRED';
  ordering?: 'created_at' | '-created_at' | 'start_dt' | '-start_dt' | 'status';
  limit?: number;
}) => {
  return axios.get<Page<Voucher>>(`${getGateURL()}/dashboard_api/billing/vouchers/`, {
    params,
  });
};

export const createVoucher = (payload: { credit: number; paid_amount: number; duration_days: number }) => {
  return axios.post<Voucher>(`${getGateURL()}/dashboard_api/billing/vouchers/`, payload, {});
};

export const purchaseVoucher = (voucherUid: string) => {
  return axios.post<Voucher>(`${getGateURL()}/dashboard_api/billing/vouchers/${voucherUid}/pay/`, {});
};

export const activateVoucher = (voucherUid: string) => {
  return axios.put<Voucher>(`${getGateURL()}/dashboard_api/billing/vouchers/${voucherUid}/activate/`, {});
};

export const fetchCreditRates = () => {
  return axios.get<CreditRates>(`${getGateURL()}/dashboard_api/billing/vouchers/credit_rates_per_minute/`, {});
};

export const fetchDiscountTable = () => {
  return axios.get<DiscountsTable>(`${getGateURL()}/dashboard_api/billing/vouchers/discount_table/`, {});
};

export const fetchACHCreditTransferSource = (uid) => {
  return axios.get<ACHCreditTransferSource>(
    `${getGateURL()}/dashboard_api/organizations/${uid}/ach_credit_transfer_source/`,
    {},
  );
};

type CallsVoucherResponse = {
  usage: number; // second
  used_credit: number;
  others_usage: number; // second
  others_used_credit: number;
  org_credit: number;
  org_remaining_credit: number;
};
export const fetchVoucherCreditUsageByApp = (appId: string) => {
  return axios.get<{ data: CallsVoucherResponse }>(
    `${getGateURL()}/dashboard_api/applications/${appId}/voice_video_usage_with_org_usages`,
  );
};

type VoucherCreditConsumerDataList =
  | 'direct_audio'
  | 'direct_video'
  | 'direct_p2p_audio'
  | 'direct_p2p_video'
  | 'small_room_for_audio_only'
  | 'large_room_for_audio_only'
  | 'small_room_for_video'
  | 'large_room_for_video'
  | 'single_host_live' // changed to live_event_for_video but keeping it for backward compatibility
  | 'live_event_for_video'
  | 'multiple_hosts_live'
  | 'single_host_audio_only_live' // changed to live_event_for_audio_only but keeping it for backward compatibility
  | 'live_event_for_audio_only'
  | 'direct_recording_audio'
  | 'direct_recording_video'
  | 'cloud_recording_audio_direct_call'
  | 'cloud_recording_audio_group_call'
  | 'cloud_recording_audio_live_event'
  | 'cloud_recording_video_direct_call'
  | 'cloud_recording_video_group_call'
  | 'cloud_recording_video_live_event';

// Additionally created based on the above list
type AdditionalVoucherCreditConsumerDataList = 'remaining';

export type VoucherCreditConsumerType = VoucherCreditConsumerDataList | AdditionalVoucherCreditConsumerDataList;

type CallsVoucherUsage = {
  record_date: string;
  updated_dt: string;
} & {
  [key in VoucherCreditConsumerType]: {
    record_date: string;
    usage: number;
    used_credit: number;
  };
};

export type Resolution = 'daily' | 'monthly';
export type Range = { start: string; end: string };

export const fetchVoucherCreditUsageByAppWithRange = (appId: string, range: Resolution, params: { params: Range }) => {
  return axios.get<{ data: CallsVoucherUsage[] }>(
    `${getGateURL()}/dashboard_api/applications/${appId}/voice_video_usage/${range}`,
    params,
  );
};

export type CallsVoucherUsageByType = {
  type: VoucherCreditConsumerType;
  usage: number;
  used_credit: number;
  percentage: number;
};

export const fetchVoucherCreditUsageByCallsType = (orgId: string, { order }) => {
  return axios.get<{ data: CallsVoucherUsageByType[] }>(
    `${getGateURL()}/dashboard_api/organizations/${orgId}/voice_video_usage_by_type`,
    { params: { order } },
  );
};

export type CallsVoucherUsageByAllAppsResponse = {
  [key in VoucherCreditConsumerDataList]: {
    usage: number;
    used_credit: number;
    percentage: number;
  };
};

export const fetchVoucherCreditUsageByAllApps = (orgId: string) => {
  return axios.get<{ data: CallsVoucherUsageByAllAppsResponse }>(
    `${getGateURL()}/dashboard_api/organizations/${orgId}/voice_video_usage`,
  );
};

type VoucherCreditUsageByRange = {
  record_date: string;
  usage: number;
  used_credit: number;
};
export type VoucherCreditUsageByRangeResponse = {
  total: VoucherCreditUsageByRange[];
  calls: VoucherCreditUsageByRange[];
  live: VoucherCreditUsageByRange[];
};

export const fetchVoucherCreditUsageByRange = (orgId: string, resolution: Resolution, { start, end }: Range) => {
  return axios.get<{ data: VoucherCreditUsageByRangeResponse }>(
    `${getGateURL()}/dashboard_api/organizations/${orgId}/voice_video_usage/${resolution}`,
    { params: { start, end } },
  );
};
