import { createAction } from '@reduxjs/toolkit';

import type { LeftNavigationBarMenuItem } from '@feather';

import type { LNBState } from '../reducers/lnb';

export const LNBActions = {
  setLNBMenuItems: createAction<{ items: LNBState['menuItems'] }>('common/lnb/setLNBMenuItems'),

  setActiveLNBMenuItem: createAction<{
    activeItemKey?: LNBState['activeItemKey'];
    activeGroup?: LNBState['activeGroup'];
  }>('common/lnb/setActiveLNBMenuItem'),

  updateLNBMenuItem: createAction<{ key: string; option: Partial<Omit<LeftNavigationBarMenuItem, 'type'>> }>(
    'common/lnb/updateLNBMenuItem',
  ),
};
