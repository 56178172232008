import { createReducer } from '@reduxjs/toolkit';

import { IntegrationsActions } from '@desk/redux/actions/integrations';

import { DeskActions } from '../actions/desk';

interface IntegrationsState {
  isAddingFacebookPages: boolean;
  isSubscribingFacebookPages: boolean;
  isUnsubscribingFacebookPage: boolean;
  loadedFacebookPages: FacebookPage[];
  fetchFacebookActivePages: {
    isFetching: boolean;
    error: string | null;
  };
  facebookPages: FacebookPage[];
  isUpdatingFacebookPageSettings: boolean;
}

const initialState: IntegrationsState = {
  isAddingFacebookPages: false,
  isSubscribingFacebookPages: false,
  isUnsubscribingFacebookPage: false,
  loadedFacebookPages: [],
  fetchFacebookActivePages: { isFetching: false, error: null },
  facebookPages: [],
  isUpdatingFacebookPageSettings: false,
};

export const integrationsReducer = createReducer<IntegrationsState>(initialState, (builder) => {
  // IntegrationsActions
  builder
    .addCase(IntegrationsActions.facebookAddPages.pending, (state) => {
      state.isAddingFacebookPages = true;
    })
    .addCase(IntegrationsActions.facebookAddPages.fulfilled, (state, { payload }) => {
      const currentFacebookPageIds = state.facebookPages.map((page) => page.pageId);
      const newFacebookPages = payload.facebookPages.filter((page) => !currentFacebookPageIds.includes(page.pageId));
      state.isAddingFacebookPages = false;
      state.loadedFacebookPages = [];
      state.facebookPages.push(...newFacebookPages);
    })
    .addCase(IntegrationsActions.facebookAddPages.rejected, (state) => {
      state.isAddingFacebookPages = false;
    })
    .addCase(IntegrationsActions.facebookUnsubscribe.pending, (state) => {
      state.isUnsubscribingFacebookPage = true;
    })
    .addCase(IntegrationsActions.facebookUnsubscribe.fulfilled, (state, { payload }) => {
      state.isUnsubscribingFacebookPage = false;
      state.facebookPages = state.facebookPages.filter((page) => {
        return page.pageId !== payload.facebookPage.pageId;
      });
    })
    .addCase(IntegrationsActions.facebookUnsubscribe.rejected, (state) => {
      state.isUnsubscribingFacebookPage = false;
    })
    .addCase(IntegrationsActions.facebookActivePages.pending, (state) => {
      state.fetchFacebookActivePages.isFetching = true;
      state.fetchFacebookActivePages.error = null;
    })
    .addCase(IntegrationsActions.facebookActivePages.fulfilled, (state, { payload }) => {
      state.facebookPages = payload.facebookPages;
      state.fetchFacebookActivePages = { isFetching: false, error: null };
    })
    .addCase(IntegrationsActions.facebookActivePages.rejected, (state, { payload }) => {
      state.fetchFacebookActivePages = { isFetching: false, error: payload ?? null };
    })
    .addCase(IntegrationsActions.facebookUpdatePageSettings.pending, (state) => {
      state.isUpdatingFacebookPageSettings = true;
    })
    .addCase(IntegrationsActions.facebookUpdatePageSettings.fulfilled, (state, { payload }) => {
      state.isUpdatingFacebookPageSettings = false;
      state.facebookPages = state.facebookPages.map((page) => {
        if (page.pageId === payload.facebookPage.pageId) {
          return payload.facebookPage;
        }
        return page;
      });
    })
    .addCase(IntegrationsActions.facebookUpdatePageSettings.rejected, (state) => {
      state.isUpdatingFacebookPageSettings = false;
    });

  // Other actions
  builder.addCase(DeskActions.resetDesk, () => initialState);
});
