import snakeCase from 'lodash/snakeCase';

import DeskApiAxios from './DeskApiAxios';

export const deskAuthentication: DeskAuthentication = (pid, region, { token }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('auth/token', { token, auth_type: 'dashboard_token' });
};

export const signOut: DeskSignoutAPI = (pid, region) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('auth/sign_out');
};

/**
 * Update Desk Project Settings
 * @param {pid, payload}
 */
export const updateProjectSetting: UpdateProjectSettingAPI = (pid, region, { type, payload }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`projects/${pid}/settings_${snakeCase(type)}`, payload);
};

export const fetchProjectApiKeys: FetchProjectApiKeysAPI = (pid, region, { offset = 0, limit = 50 }) => {
  const params = `limit=${limit}&offset=${offset}`;
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`projects/project_api_keys/?${params}`);
};

export const createProjectApiKey: CreateProjectApiKeyAPI = (pid, region, { payload }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('project_api_keys', payload);
};

export const deleteProjectApiKey: DeleteProjectApiKeyAPI = (pid, region, { id }) => {
  const data: { status: ApiTokenStatus } = { status: 'INACTIVE' };
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`project_api_keys/${id}/`, data);
};
