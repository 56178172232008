import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { sendAdminMessageWithModeratorInfo } from '@chat/api/channelModeration';
import { DialogsActions } from '@common/redux/actions/dialogs';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import { toast } from '@feather/components/notification';
import logException from '@utils/logException';
import { ALERT_ADMIN_MESSAGE_SENT } from '@utils/text';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

export const ModerationsActions = {
  sendAdminMessage: createAsyncThunk<
    void,
    Pick<SendAdminMessageParams, 'channelUrls' | 'channelType' | 'message' | 'sendPush' | 'moderator'> & {
      origin: 'channels' | 'moderation';
    }
  >('chat/moderation/sendAdminMessage', async (payload, { dispatch, getState, rejectWithValue }) => {
    const appId = selectApplicationData(getState()).app_id;

    try {
      dispatch(DialogsActions.setIsFetching(true));
      await sendAdminMessageWithModeratorInfo({ appId, ...payload });

      if (payload.origin && payload.origin === 'channels') {
        toast.success({ message: ALERT_ADMIN_MESSAGE_SENT });
        dispatch(DialogsActions.hideDialog());
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    } finally {
      dispatch(DialogsActions.setIsFetching(false));
    }
  }),

  resetOpenChannels: createAction('chat/moderation/resetOpenChannels'),
  resetOpenChannelsModerationData: createAction('chat/moderation/resetOpenChannelsModerationData'),

  resetGroupChannels: createAction('chat/moderation/resetGroupChannels'),
  resetGroupChannelsModerationData: createAction('chat/moderation/resetGroupChannelsModerationData'),
};
