import type { RouteProps } from 'react-router-dom';

import config from '@/config';
import type { ChatFeatureName } from '@constants';
import { URI_REGEX, BYTES_PER_GIGABYTE, CardBrandsNew, CDN_ASSETS_PREFIX, CardBrands } from '@constants';

import safeParseNumber from './safeParseNumber';

String.prototype.hashCode = function () {
  let hash = 0,
    i,
    chr,
    len;
  if (this.length === 0) return hash;
  for (i = 0, len = this.length; i < len; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const isEmpty = (val) => {
  if (null == val) return true;
  if ('boolean' === typeof val) return false;
  if ('number' === typeof val) return val === 0;
  if (undefined !== val.length) return val.length === 0;

  if (val instanceof Object) {
    return Object.keys(val).length === 0;
  }
  return true;
};

/**
 * Encode a string as a URI component. If a URL parameter may contain !, ', (, ), or *,
 * always use this function instead of `encodeURIComponent()`.
 *
 * JavaScript's built-in function `encodeURIComponent()` does not encode !, ', (, ), and *.
 * It has been found to cause server errors when `encodeURIComponent()` was used for the URL params
 * with these characters to send a request to Gate's soda proxy endpoints.
 */
export const fixedEncodeURIComponent = (str: string): string => {
  /**
   * This function makes a string portable, so it can be transmitted across any network to any computer that supports ASCII characters.
   * @param char !, ', (, ), *
   */
  const encode = function encodeSpecialCharacters(char: string): string {
    return `%${char.charCodeAt(0).toString(16)}`.toUpperCase();
  };

  return encodeURIComponent(str).replace(/[!'()*]/g, encode);
};

export const getRandomNumber = (str, divider: number) => {
  if (str) {
    const numberFromHash = Math.abs(String(str).hashCode()) % divider;

    return numberFromHash + 1;
  }
  return Math.abs(String('getRandomNumber').hashCode()) % divider;
};

const range = (start, end, step = 1): number[] => {
  const _end = end || start;
  const _start = end ? start : 0;
  const _step = step;
  return Array((_end - _start) / _step)
    .fill(0)
    .map((_, i) => _start + i * _step);
};

export const generatePagination = ({ token = '', total = 0, page = 1, last = 0 }, perPage: PerPage) => {
  const MAX_BLOCK_SIZE = 5;

  const totalPage = Math.floor(total / perPage);
  const allPages: number = total % perPage !== 0 || total === 0 ? totalPage + 1 : totalPage;

  const totalBlock = Math.floor((allPages - 1) / MAX_BLOCK_SIZE + 1);
  const currentBlock = Math.floor((page - 1) / MAX_BLOCK_SIZE + 1);

  let pages: number[] = [];
  let pre = 0;
  let next = 0;

  let hasPre = false;
  let hasNext = false;

  if (currentBlock > 1) {
    hasPre = true;
    pre = (currentBlock - 1) * MAX_BLOCK_SIZE;
  }

  if (currentBlock < totalBlock) {
    hasNext = true;
    next = currentBlock * MAX_BLOCK_SIZE + 1;

    pages = range(Math.floor((currentBlock - 1) * MAX_BLOCK_SIZE + 1), Math.floor(currentBlock * MAX_BLOCK_SIZE) + 1);
  } else if (currentBlock === totalBlock) {
    pages = range(Math.floor((currentBlock - 1) * MAX_BLOCK_SIZE + 1), allPages + 1);
  }

  if (pages.length === 0) {
    pages = [1];
  }

  const pagination = {
    pages,
    pre,
    hasPre,
    hasNext,
    page,
    next,
    token,
    last,
    total,
    block: currentBlock,
    totalBlock,
    first: 1,
  };

  return pagination;
};

export const uuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0; // Nachkommastellen abschneiden
    const value = char === 'x' ? random : (random % 4) + 8; // Bei x Random 0-15 (0-F), bei y Random 0-3 + 8 = 8-11 (8-b) gemäss RFC 4122
    return value.toString(16); // Hexadezimales Zeichen zurückgeben
  });
};
export const getDimension = () => {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e!.clientWidth || g.clientWidth,
    y = w.innerHeight || e!.clientHeight || g.clientHeight;

  return {
    x,
    y,
  };
};

export const getYear = () => {
  return new Date().getUTCFullYear();
};

export const getUriHost = (uri) => {
  if (typeof uri !== 'string') {
    return uri;
  }
  const matches = uri.trim().match(URI_REGEX);
  return matches && matches.length > 3 ? matches[3] : uri;
};

export const isEqualIgnoreCase = (first: string, second: string) =>
  first.localeCompare(second, 'en', { sensitivity: 'base' }) === 0;

export const safeParseJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};

export const getClosedStatusesIndex = (closedStatus: ClosedStatus, useArchive: boolean = false) => {
  const statuses: ClosedStatus[] = [
    'CLOSED_BY_CUSTOMER',
    'CLOSED_BY_AGENT',
    'CLOSED_BY_ADMIN',
    'CLOSED_BY_SYSTEM',
    'CLOSED_BY_PLATFORM_API',
    'CLOSED_BUT_NOT_DEFINED',
  ];
  if (useArchive) {
    statuses.push('CLOSED_BY_ARCHIVE');
  }
  const index = statuses.indexOf(closedStatus);
  return index;
};

export const transformBytesToGigaByte = (value: number) => {
  return value / BYTES_PER_GIGABYTE;
};

export const isByteUsageFeature = (featureKey: ChatFeatureName | FeaturePlanKey | FeatureUsageField | string) =>
  featureKey === 'file_storage' || featureKey === 'avg_file_storage' || featureKey === 'upload_traffic';
interface GenerateUsageData {
  (payload: {
    feature: ChatFeature;
    plan?: BillingPlanItem;
    usage: number;
    others?: number;
    usageField: FeatureUsageField;
  }): UsageData;
}

export const generateUsageData: GenerateUsageData = ({ feature, plan, usage = 0, others, usageField }) => {
  const currentUsage = feature.trackable ? safeParseNumber(usage, { as: 'float' }) : 0;
  const totalUsage = feature.trackable ? safeParseNumber(usage, { as: 'float' }) + (others ?? 0) : 0;

  const quota = feature.trackable ? safeParseNumber(plan?.purchased_units ?? 0, { as: 'float' }) : 0;
  const limit = feature.trackable ? safeParseNumber(plan?.hard_limit ?? 0, { as: 'float' }) : 0;

  return {
    others,
    usage: currentUsage,
    quota,
    limit,
    isExceedLimit: !!plan?.enabled && feature.trackable && totalUsage >= limit,
    usageField,
    plan,
  };
};

/**
 * Billing
 */
const cardToImage = {
  amex: 'American Express (Inverted)',
  diners: 'Diners Club (Inverted)',
  discover: 'Discover',
  mastercard: 'MasterCard (Inverted)',
  unionpay: 'Unknown',
  visa: 'Visa (Inverted)',
  jcb: 'JCB (Inverted)',
};

export const isCardImageExists = (cardInfo) => {
  return (
    Object.prototype.hasOwnProperty.call(cardInfo, 'brand') &&
    CardBrands.includes(cardInfo.brand) &&
    cardInfo.brand.toLowerCase() !== 'unknown'
  );
};

export const getCardImage = (brand) => {
  if (CardBrandsNew.includes(brand)) {
    return `${CDN_ASSETS_PREFIX}/images/cards/${cardToImage[brand]}.png`;
  }
  return `${CDN_ASSETS_PREFIX}/images/cards/${brand === 'Discover' ? brand : `${brand} (Inverted)`}.png`;
};

/**
 * this will give nice padding into contact input
 * @param code
 */
export const getCountryCodeWidth = (code: number) => {
  switch (code.toString().length) {
    case 1:
      return 16 + 17.67 + 7;
    case 2:
      return 16 + 26.11 + 8;
    case 3:
      return 16 + 34.55 + 8;
    default:
      return 51;
  }
};

export const isStaging = () => {
  return config.BUILD_MODE === 'staging' || config.BUILD_MODE === 'amplify';
};

export const getRoutePathString = (path: RouteProps['path']) => {
  if (path == null) {
    return '';
  }
  if (Array.isArray(path)) {
    return path.length > 0 ? path[0] : '';
  }
  return path;
};
