import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { changeEmail, isAbleToUnregister, turnoffTwoFactor, unregister } from '@common/api/account';
import { DialogType } from '@common/containers/dialogs/DialogType';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { AuthenticationActions } from './authentication';
import { DialogsActions } from './dialogs';

export const AccountActions = {
  changeEmail: createAsyncThunk<{ newEmail: string }, { email: string; password: string }>(
    'common/account/changeEmail',
    async (payload, { dispatch, rejectWithValue }) => {
      try {
        const { data } = await changeEmail({
          email: payload.email,
          password: payload.password,
        });

        dispatch(DialogsActions.hideDialog());
        return { newEmail: data.new_email };
      } catch (error) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }
    },
  ),
  passwordChanged: createAction<{ token: string; user: AuthUser }>('common/account/passwordChanged'),
  userUpdated: createAction<AuthUser>('common/account/userUpdated'),

  checkUnregisterAvailability: createAsyncThunk<void>(
    'common/account/checkUnregisterAvailability',
    async (_, { dispatch, getState, rejectWithValue }) => {
      const organization = getState().organizations.current;

      try {
        const { data } = await isAbleToUnregister();

        const dialogProps = data.organizations.find((org) => org.uid === organization.uid);

        dispatch(createAction(AccountActions.unregister.fulfilled.type)());
        dispatch(DialogsActions.showDialog({ dialogTypes: DialogType.DeleteAccount, dialogProps }));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  unregister: createAsyncThunk<void, { password: string }>(
    'common/account/unregister',
    async (payload, { rejectWithValue }) => {
      try {
        await unregister(payload.password);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  turnOffGoogleAuthenticator: createAsyncThunk<void>(
    'common/account/turnoffGoogleAuthenticator',
    async (_, { dispatch, rejectWithValue }) => {
      try {
        await turnoffTwoFactor();

        dispatch(AuthenticationActions.twoFactorAuthenticationSet({ two_factor_authentication: false }));
        dispatch(DialogsActions.hideDialog());
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
};
