import { axios, getGateURL } from '@api/shared';

export const changePassword = (payload: { password: string; new_password: string; new_password_confirm: string }) => {
  return axios.put(`${getGateURL()}/dashboard/auth/change_password/`, payload);
};

export const changeEmail: ChangeEmailAPI = (payload) => {
  return axios.post(`${getGateURL()}/dashboard_api/profiles/change_user_email/`, payload);
};

export const updateProfile: UpdateProfileAPI = (id, payload) => {
  return axios.patch(`${getGateURL()}/dashboard_api/profiles/${id}/`, payload);
};

export const isAbleToUnregister: IsAbleToUnregisterAPI = () => {
  return axios.get(`${getGateURL()}/dashboard/auth/is_able_to_unregister/`);
};

export const unregister = (password: string = '') => {
  return axios.post(`${getGateURL()}/dashboard/auth/unregister/`, {
    password,
  });
};

export const prepareTwoFactor = () => {
  return axios.post<{ provision_uri: string; secret_code: string }>(
    `${getGateURL()}/dashboard_api/two_factor_authentications/`,
    {},
  );
};

export const registerTwoFactor = (payload: { code: string }) => {
  return axios.post<{ recovery_code: string; token: string }>(
    `${getGateURL()}/dashboard_api/two_factor_authentications/confirm/`,
    payload,
  );
};

export const turnoffTwoFactor = () => {
  return axios.delete(`${getGateURL()}/dashboard_api/two_factor_authentications/`);
};

export const activateLockedAccount = () => {
  return axios.put(`${getGateURL()}/dashboard/auth/activate_locked_account/`, {});
};

export const linkAwsAccount = (payload: {
  company_name: string;
  company_email: string;
  aws_account_id: string;
  token: string;
}) => {
  return axios.post<{
    token: string;
    company_name: string;
    company_email: string;
    aws_id: string;
  }>(`${getGateURL()}/aws_marketplace/users/`, payload);
};
