import { createReducer } from '@reduxjs/toolkit';

import { ChannelsActions } from '@chat/redux/actions/channels';
import { ModerationsActions } from '@chat/redux/actions/moderations';
import { AuthenticationActions } from '@common/redux/actions/authentication';
import { GroupChannelSearchOperator } from '@constants';
import { ApplicationActions } from '@core/redux/actions/application';

interface GroupChannelsState {
  channels: readonly SendbirdChatChannel.GroupChannel[];
  next: string;

  current: SendbirdChatChannel.GroupChannel | null;

  isFetching: boolean;
  isFetchingLoadMore: boolean;

  // moderation
  isFetchingChannel: boolean;

  search: {
    option: GroupChannelSearchOperator;
    query: string;
    isSearching: boolean;
    isSuccess: boolean;
  };
  showEmptyChannels: boolean;
  order?: FetchGroupChannelsAPIPayloadBase['order'];
}

const initialState: GroupChannelsState = {
  channels: [],
  next: '',

  current: null,

  isFetching: true,

  isFetchingChannel: true,
  isFetchingLoadMore: false,

  search: {
    option: GroupChannelSearchOperator.nicknameEquals,
    query: '',
    isSearching: false,
    isSuccess: false,
  },
  showEmptyChannels: true,
};

export const groupChannelsReducer = createReducer<GroupChannelsState>(initialState, (builder) => {
  // ChannelsActions
  builder
    .addCase(ChannelsActions.fetchGroupChannels.pending, (state, { meta }) => {
      if (meta.arg.init) {
        state.isFetching = true;
      } else {
        state.isFetchingLoadMore = true;
      }
    })
    .addCase(ChannelsActions.fetchGroupChannels.fulfilled, (state, { payload }) => {
      state.channels = payload.init ? payload.channels : state.channels.concat(payload.channels);
      state.next = payload.next;
      state.isFetching = false;
      state.isFetchingLoadMore = false;
    })
    .addCase(ChannelsActions.fetchGroupChannels.rejected, (state) => {
      state.isFetching = false;
      state.isFetchingLoadMore = false;
    })
    .addCase(ChannelsActions.searchGroupChannels.pending, (state, { meta }) => {
      state.isFetching = true;
      state.search.query = meta.arg.query;
      state.search.option = meta.arg.option;
      state.search.isSearching = true;
      state.search.isSuccess = false;
    })
    .addCase(ChannelsActions.searchGroupChannels.fulfilled, (state, { payload }) => {
      state.channels = payload.init ? payload.channels : state.channels.concat(payload.channels);
      state.next = payload.next;
      state.isFetching = false;
      state.isFetchingLoadMore = false;
      state.search.query = payload.query;
      state.search.isSearching = false;
      state.search.isSuccess = true;
    })
    .addCase(ChannelsActions.searchGroupChannels.rejected, (state) => {
      state.isFetching = false;
      state.isFetchingLoadMore = false;
      state.search.isSearching = false;
    })
    .addCase(ChannelsActions.fetchGroupChannel.pending, (state) => {
      state.isFetchingChannel = true;
    })
    .addCase(ChannelsActions.setCurrentGroupChannel, (state, { payload }) => {
      state.isFetchingChannel = false;
      if (state.channels.some((channel) => channel.channel_url === payload.channel_url)) {
        state.channels = state.channels.map((channel) =>
          channel.channel_url === payload.channel_url ? payload : channel,
        );
      }
      state.current = payload;
    })
    .addCase(ChannelsActions.updateGroupChannelInList, (state, { payload }) => {
      state.channels = state.channels.map((channel) =>
        payload && channel.channel_url === payload.channel_url ? payload : channel,
      );
    })
    .addCase(ChannelsActions.deleteGroupChannelInList, (state, { payload }) => {
      state.channels = state.channels.filter((channel) =>
        payload.every((deleteTargetChannel) => channel.channel_url !== deleteTargetChannel.channel_url),
      );
    })
    .addCase(ChannelsActions.setGroupChannelSearchOption, (state, { payload }) => {
      state.search.option = payload;
    })
    .addCase(ChannelsActions.setGroupChannelSearchQuery, (state, { payload }) => {
      state.search.query = payload;
    })
    .addCase(ChannelsActions.setGroupChannelSearchState, (state, { payload }) => {
      state.search.isSearching = payload;
    })
    .addCase(ChannelsActions.setGroupChannelSearchSuccess, (state, { payload }) => {
      state.search.isSearching = false;
      state.search.isSuccess = payload;
    })
    .addCase(ChannelsActions.setGroupChannelShowEmptyChannels, (state, { payload }) => {
      state.showEmptyChannels = payload;
    })
    .addCase(ChannelsActions.setGroupChannelsSortOrder, (state, { payload }) => {
      state.order = payload;
    });

  // ModerationsActions
  builder
    .addCase(ModerationsActions.resetGroupChannels, () => initialState)
    .addCase(ModerationsActions.resetGroupChannelsModerationData, (state) => {
      state.current = null;
    });

  // Other actions
  builder
    .addCase(ApplicationActions.applicationReset, () => initialState)
    .addCase(AuthenticationActions.unauthenticated, () => initialState);
});
