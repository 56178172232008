import axios from 'axios';

import { browserRouter } from '@/browserRouter';
import createAsyncThunk from '@/redux/createAsyncThunk';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import {
  facebookActivePages,
  facebookLoadPages,
  facebookSubscribe,
  facebookUnsubscribe,
  facebookUpdatePageSettings,
} from '@desk/api/integrations';
import { toast } from '@feather/components/notification';
import getErrorMessage from '@utils/getErrorMessage';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { selectDeskProject } from '../selectors/desk';

type FacebookPagePayload = { facebookPage: FacebookPage };

export const IntegrationsActions = {
  facebookAddPages: createAsyncThunk<
    { facebookPages: FacebookPage[] },
    FacebookLoadPagesPayload & { onSuccessNavigateTo?: string }
  >('desk/integrations/facebookAddPages', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { accessToken, onSuccessNavigateTo } = payload;

    try {
      const { data } = await facebookLoadPages(pid, region, { accessToken });

      if (data.length === 0) {
        toast.warning({ message: 'No page loaded.' });
        return { facebookPages: data };
      }
      const results = await Promise.all(data.map((page) => facebookSubscribe(pid, region, { page_id: page.pageId })));
      const facebookPages = results.map((res) => res.data);
      onSuccessNavigateTo && browserRouter.navigate(onSuccessNavigateTo);
      toast.success({
        message: window.intl.formatMessage({ id: 'desk.settings.integration.facebook.noti.add.success' }),
      });
      return { facebookPages };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  facebookUnsubscribe: createAsyncThunk<{ facebookPage: FacebookPage }, FacebookPagePayload>(
    'desk/integrations/facebookUnsubscribe',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await facebookUnsubscribe(pid, region, { page_id: payload.facebookPage.pageId });

        toast.success({
          message: window.intl.formatMessage({ id: 'desk.settings.integration.facebook.noti.remove.success' }),
        });
        return { facebookPage: data };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  facebookActivePages: createAsyncThunk<{ facebookPages: FacebookPage[] }, void, { rejectValue: string | null }>(
    'desk/integrations/facebookActivePages',
    async (_, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await facebookActivePages(pid, region);

        return { facebookPages: data.results };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return rejectWithValue(getErrorMessage(error));
        }

        logException(error);
        return rejectWithValue(getErrorMessage(error));
      }
    },
  ),

  facebookUpdatePageSettings: createAsyncThunk<{ facebookPage: FacebookPage }, FacebookPagePayload>(
    'desk/integrations/facebookUpdatePageSettings',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await facebookUpdatePageSettings(pid, region, {
          pageId: payload.facebookPage.pageId,
          payload: {
            isConversationEnabled: payload.facebookPage.isConversationEnabled,
            isFeedEnabled: payload.facebookPage.isFeedEnabled,
          },
        });

        browserRouter.navigate(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
        toast.success({
          message: window.intl.formatMessage({ id: 'desk.settings.integration.facebook.noti.update.success' }),
        });
        return { facebookPage: data };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
};
