import type { AxiosPromise, AxiosRequestConfig, Canceler } from 'axios';
import axios from 'axios';
import qs from 'qs';

import setupAxiosInterceptors from './setupAxiosInterceptors';

axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });
setupAxiosInterceptors(axios);

export enum HttpMethod {
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
}

export type CancellableAxiosPromise<T = any> = AxiosPromise<T> & {
  cancel: Canceler;
};

export class CancellableAxios {
  private createCancelTokenSource = () => axios.CancelToken.source();

  protected makeRequest = <T>({
    method,
    url,
    data,
    config: originalConfig,
  }: {
    method: HttpMethod;
    url: string;
    data?: any;
    config: AxiosRequestConfig;
  }): CancellableAxiosPromise<T> => {
    const cancelTokenSource = this.createCancelTokenSource();
    const config = {
      ...axios.defaults,
      withCredentials: true,
      ...originalConfig,
      cancelToken: cancelTokenSource.token,
    } as AxiosRequestConfig;

    const request: CancellableAxiosPromise<T> = (() => {
      let axiosPromise: AxiosPromise<T> & { cancel?: Canceler };
      switch (method) {
        case HttpMethod.Get:
          axiosPromise = axios.get<T>(url, config);
          break;
        case HttpMethod.Delete:
          axiosPromise = axios.delete<T>(url, config);
          break;
        case HttpMethod.Head:
          axiosPromise = axios.head<T>(url, config);
          break;
        case HttpMethod.Post:
          axiosPromise = axios.post<T>(url, data, config);
          break;
        case HttpMethod.Put:
          axiosPromise = axios.put<T>(url, data, config);
          break;
        default:
          // HttpMethod.Patch:
          axiosPromise = axios.patch<T>(url, data, config);
      }
      axiosPromise.cancel = cancelTokenSource.cancel;

      return axiosPromise as CancellableAxiosPromise<T>;
    })();

    return request;
  };

  public get original() {
    return axios;
  }

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public get = <T = any>(url: string, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({ method: HttpMethod.Get, url, config });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public delete = <T = any>(url: string, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({ method: HttpMethod.Delete, url, config });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public head = <T = any>(url: string, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({ method: HttpMethod.Head, url, config });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public post = <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({ method: HttpMethod.Post, url, data, config });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public put = <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({ method: HttpMethod.Put, url, data, config });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public patch = <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({ method: HttpMethod.Patch, url, data, config });
}

export const cancellableAxios = new CancellableAxios();
