import { DialogType } from '@common/containers/dialogs/DialogType';
import type { LinkProps } from '@feather';
import { Link } from '@feather/components/link';
import { Tooltip, TooltipVariant } from '@feather/components/tooltip';
import useAuthorization from '@hooks/useAuthorization';
import useShowDialog from '@hooks/useShowDialog';
import useTranslationSync from '@hooks/useTranslationSync';

import messages from './translation/en.json';

const getMessages = () => messages;

type Props = LinkProps & {
  permissions: PermissionKey[];
  /**
   * If the link is within a tooltip, use `dialog` alertType since showing a tooltip over a tooltip is not recommended.
   */
  alertType?: 'tooltip' | 'dialog';
};

export const LinkWithPermissionCheck = ({ permissions, alertType = 'tooltip', ...linkProps }: Props) => {
  const { t } = useTranslationSync(getMessages);
  const { isPermitted } = useAuthorization();
  const showPermissionDeniedDialog = useShowDialog({
    dialogTypes: DialogType.Custom,
    dialogProps: {
      size: 'small',
      title: t('dialog.title'),
      description: t('dialog.body'),
      positiveButtonProps: { text: t('dialog.btn.confirm') },
      isNegativeButtonHidden: true,
    },
  });

  const isAllowedToNavigate = isPermitted(permissions);
  if (isAllowedToNavigate) {
    return <Link useReactRouter {...linkProps} />;
  }

  if (alertType === 'tooltip') {
    return (
      <Tooltip
        css="display: inline-block;"
        content={t('tooltip.permissionRequired')}
        variant={TooltipVariant.Light}
        portalId="portal_tooltip"
        placement="bottom"
        tooltipContentStyle="max-width: 256px;"
      >
        <Link useReactRouter {...linkProps} disabled={true} />
      </Tooltip>
    );
  }
  return (
    <Link
      useReactRouter
      {...linkProps}
      onClick={(event) => {
        event.preventDefault();
        showPermissionDeniedDialog();
      }}
    />
  );
};
