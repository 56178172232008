import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { AccountActions } from '@common/redux/actions/account';

interface AccountState {
  isFetching: boolean;
  isFetchingUnregister: boolean;
}

const initialState: AccountState = {
  isFetching: false,
  isFetchingUnregister: false,
};

export const accountReducer = createReducer<AccountState>(initialState, (builder) => {
  builder
    .addCase(AccountActions.passwordChanged, (state) => {
      state.isFetching = false;
    })
    .addCase(AccountActions.unregister.pending, (state) => {
      state.isFetchingUnregister = true;
    })
    .addMatcher(isAnyOf(AccountActions.unregister.fulfilled, AccountActions.unregister.rejected), (state) => {
      state.isFetchingUnregister = false;
    });
});
