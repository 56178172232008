import { useEffect } from 'react';

import * as Sentry from '@sentry/react';

import useUser from '@hooks/useUser';

const useSentryUserTracker = () => {
  const user = useUser();

  useEffect(() => {
    if (!user.user_id) return;

    Sentry.setUser({ id: String(user.user_id) });

    return () => {
      Sentry.setUser(null);
    };
  }, [user]);
};

export default useSentryUserTracker;
