import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { browserRouter } from '@/browserRouter';
import createAsyncThunk from '@/redux/createAsyncThunk';
import {
  createOpenChannel,
  deleteChannel,
  fetchGroupChannel,
  fetchGroupChannels,
  fetchMyGroupChannels,
  fetchOpenChannel,
  fetchOpenChannels,
  searchGroupChannels,
  searchOpenChannels,
} from '@chat/api/channels';
import { DialogsActions } from '@common/redux/actions/dialogs';
import { LIST_LIMIT } from '@constants';
import { GroupChannelSearchOperator } from '@constants/core';
import { OpenChannelSearchOperator } from '@constants/core';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import { toast } from '@feather/components/notification';
import { fixedEncodeURIComponent } from '@utils/generals';
import logException from '@utils/logException';
import { ALERT_CHANNEL_CREATED, ALERT_CHANNEL_DELETED } from '@utils/text';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

const fetchOpenChannelsAction = createAsyncThunk<
  { channels: SendbirdChatChannel.OpenChannel[]; next: string; init: boolean },
  { init: boolean }
>('chat/channels/fetchOpenChannels', async (payload, { dispatch, getState, rejectWithValue }) => {
  const appId = selectApplicationData(getState()).app_id;
  const { init } = payload;
  const listToken = init ? '' : getState().openChannels.next || '';

  try {
    const { data } = await fetchOpenChannels({ appId, listToken });

    return {
      channels: data.channels,
      next: data.next,
      init,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        dispatch(fetchOpenChannelsAction({ init: true }));
      } else {
        toastBadRequestWarning(error);
      }
      return rejectWithValue(null);
    }

    logException(error);
    return rejectWithValue(null);
  }
});

export const ChannelsActions = {
  // Channels
  deleteChannels: createAsyncThunk<
    boolean,
    { channelType: SendbirdChatChannel.ChannelType; channels: readonly SendbirdChatChannel.BaseChannel[] }
  >('chat/channels/deleteChannels', async (payload, { dispatch, getState, rejectWithValue }) => {
    const appId = selectApplicationData(getState()).app_id;
    const { channels, channelType } = payload;

    try {
      dispatch(DialogsActions.setIsFetching(true));
      const results = await Promise.all(
        channels.map((channel) =>
          deleteChannel({ appId, channelType, channel_url: channel.channel_url }).then(
            () => ({ isError: false, error: null }),
            (error) => ({ isError: true, error }),
          ),
        ),
      );

      const errors = results.filter((result) => result?.isError);
      errors.forEach(({ error }) => toastBadRequestWarning(error));
      const isDeletedAtLeastOne = errors.length < results.length;
      if (isDeletedAtLeastOne) {
        toast.success({ message: ALERT_CHANNEL_DELETED });
        dispatch(DialogsActions.hideDialog());
      }
      return isDeletedAtLeastOne;
    } catch (error) {
      logException(error);
      return rejectWithValue(error);
    } finally {
      dispatch(DialogsActions.setIsFetching(false));
    }
  }),

  // Open channels
  fetchOpenChannels: fetchOpenChannelsAction,

  searchOpenChannels: createAsyncThunk<
    { channels: SendbirdChatChannel.OpenChannel[]; next: string; init?: boolean; query: string },
    { option: OpenChannelSearchOperator; query: string; init?: boolean }
  >('chat/channels/searchOpenChannels', async (payload, { getState, rejectWithValue }) => {
    const appId = selectApplicationData(getState()).app_id;
    const { option, query, init } = payload;
    const listToken = init ? '' : getState().openChannels.next || '';
    const getQueryOptions = ({ option, query, listToken }) => {
      const tokenAndLimit = `&token=${listToken}&limit=${LIST_LIMIT}`;

      if (option === OpenChannelSearchOperator.urlEquals) {
        return `/${fixedEncodeURIComponent(query)}`;
      }
      if (option === OpenChannelSearchOperator.nameContains) {
        return `?name_contains=${fixedEncodeURIComponent(query)}${tokenAndLimit}`;
      }
      if (option === OpenChannelSearchOperator.customTypeEquals) {
        return `?custom_type=${fixedEncodeURIComponent(query)}${tokenAndLimit}`;
      }
      return '';
    };
    const queryOptions = getQueryOptions({ option, query, listToken });

    try {
      const { data } = await searchOpenChannels({ appId, queryOptions });

      return {
        channels: 'channels' in data ? data.channels : [data],
        next: 'next' in data ? data.next : '',
        init,
        query,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data as { code: number } | undefined;
        if (option === OpenChannelSearchOperator.urlEquals && errorData?.code === 400201) {
          return {
            channels: [],
            next: '',
            init,
            query,
          };
        }

        toastBadRequestWarning(error);
        return rejectWithValue(null);
      }

      logException(error);
      toastBadRequestWarning(error);
      return rejectWithValue(null);
    }
  }),
  setOpenChannelSearchQuery: createAction<string>('chat/channels/setOpenChannelSearchQuery'),
  setOpenChannelSearchState: createAction<boolean>('chat/channels/setOpenChannelSearchState'),
  setOpenChannelSearchSuccess: createAction<boolean>('chat/channels/setOpenChannelSearchSuccess'),
  setOpenChannelSearchOption: createAction<OpenChannelSearchOperator>('chat/channels/setOpenChannelSearchOption'),

  updateOpenChannelInList: createAction<SendbirdChatChannel.OpenChannel>('chat/channels/updateOpenChannelInList'),
  deleteOpenChannelInList: createAction<readonly SendbirdChatChannel.BaseChannel[]>(
    'chat/channels/deleteOpenChannelInList',
  ),

  setCurrentOpenChannel: createAction<SendbirdChatChannel.OpenChannel>('chat/channels/setCurrentOpenChannel'),
  createOpenChannel: createAsyncThunk<
    SendbirdChatChannel.OpenChannel,
    {
      data: Pick<SendbirdChatChannel.ChannelPartial, 'name' | 'channel_url' | 'cover_url' | 'custom_type'> & {
        cover_file: File | null;
      };
    }
  >('chat/channels/createOpenChannel', async (payload, { dispatch, getState, rejectWithValue }) => {
    const appId = selectApplicationData(getState()).app_id;

    try {
      dispatch(DialogsActions.setIsFetching(true));
      const { data } = await createOpenChannel({ appId, data: payload.data });

      toast.success({ message: ALERT_CHANNEL_CREATED });
      dispatch(ChannelsActions.fetchOpenChannels({ init: true }));
      dispatch(DialogsActions.hideDialog());

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    } finally {
      dispatch(DialogsActions.setIsFetching(false));
    }
  }),
  fetchOpenChannel: createAsyncThunk<void, SendbirdChatChannel.ChannelURL>(
    'chat/channels/fetchOpenChannel',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;
      const channel_url = payload;

      try {
        const { data } = await fetchOpenChannel({ appId, channel_url });

        dispatch(ChannelsActions.setCurrentOpenChannel(data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const errorData = error.response?.data as { code: number } | undefined;
          if (errorData?.code === 400201) {
            // channel not found
            browserRouter.navigate(`/${appId}/open-channels`);
            toastBadRequestWarning(error);
            return rejectWithValue(error);
          }

          logException(error);
          return rejectWithValue(error);
        }
      }
    },
  ),

  // Group channels
  fetchGroupChannels: createAsyncThunk<
    { channels: SendbirdChatChannel.GroupChannel[]; next: string; init: boolean },
    { init: boolean; superMode?: GroupChannelSuperMode }
  >('chat/channels/fetchGroupChannels', async (payload, { getState, rejectWithValue }) => {
    const appId = selectApplicationData(getState()).app_id;
    const init = !!payload.init;
    const { showEmptyChannels, next, order } = getState().groupChannels;
    const listToken = init ? '' : next;

    try {
      const { data } = await fetchGroupChannels({
        appId,
        listToken,
        limit: LIST_LIMIT,
        showEmpty: showEmptyChannels,
        order,
        superMode: payload.superMode,
      });

      return {
        channels: data.channels,
        next: data.next,
        init,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      toastBadRequestWarning(error);
      return rejectWithValue(error);
    }
  }),

  searchGroupChannels: createAsyncThunk<
    { channels: SendbirdChatChannel.GroupChannel[]; next: string; init?: boolean; query: string },
    { option: GroupChannelSearchOperator; query: string; init?: boolean; superMode?: GroupChannelSuperMode }
  >('chat/channels/searchGroupChannels', async (payload, { getState, rejectWithValue }) => {
    const appId = selectApplicationData(getState()).app_id;
    const { option, query, init, superMode } = payload;
    const { next, showEmptyChannels, order } = getState().groupChannels;
    const listToken = init ? '' : next;

    const params = { appId, listToken, limit: LIST_LIMIT, showEmpty: showEmptyChannels, order, superMode };

    let queryOptions = '';
    let request: ReturnType<typeof fetchMyGroupChannels | typeof searchGroupChannels>;
    if (
      option === GroupChannelSearchOperator.userIdEquals ||
      (option === GroupChannelSearchOperator.membersIncludeIn && !query.includes(','))
    ) {
      // should use list my group channels API to avoid slow queries
      request = fetchMyGroupChannels({ ...params, userId: query.trim() });
    } else {
      if (option === GroupChannelSearchOperator.urlEquals) {
        queryOptions = `channel_urls=${fixedEncodeURIComponent(query)}`;
      } else if (option === GroupChannelSearchOperator.nicknameEquals) {
        queryOptions = `members_nickname=${fixedEncodeURIComponent(query)}`;
      } else if (option === GroupChannelSearchOperator.customTypeEquals) {
        queryOptions = `custom_type=${fixedEncodeURIComponent(query)}`;
      } else if (option === GroupChannelSearchOperator.nameEquals) {
        queryOptions = `name=${fixedEncodeURIComponent(query)}`;
      } else if (option === GroupChannelSearchOperator.nameStartswith) {
        queryOptions = `name_startswith=${fixedEncodeURIComponent(query)}`;
      } else if (option === GroupChannelSearchOperator.membersIncludeIn) {
        const encodedUserIds = query
          .split(',')
          .map((id) => fixedEncodeURIComponent(id.trim()))
          .join(',');
        queryOptions = `members_include_in=${encodedUserIds}&query_type=AND`;
      }

      request = searchGroupChannels({ ...params, queryOptions: queryOptions && `?${queryOptions}` });
    }

    try {
      const { data } = await request;

      return {
        channels: data.channels,
        next: data.next,
        init,
        query,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),
  setGroupChannelSearchQuery: createAction<string>('chat/channels/setGroupChannelSearchQuery'),
  setGroupChannelSearchState: createAction<boolean>('chat/channels/setGroupChannelSearchState'),
  setGroupChannelSearchSuccess: createAction<boolean>('chat/channels/setGroupChannelSearchSuccess'),
  setGroupChannelSearchOption: createAction<GroupChannelSearchOperator>('chat/channels/setGroupChannelSearchOption'),
  setGroupChannelShowEmptyChannels: createAction<boolean>('chat/channels/setGroupChannelShowEmptyChannels'),
  setGroupChannelsSortOrder: createAction<FetchGroupChannelsAPIPayloadBase['order']>(
    'chat/channels/setGroupChannelsSortOrder',
  ),

  updateGroupChannelInList: createAction<SendbirdChatChannel.GroupChannel>('chat/channels/updateGroupChannelInList'),
  deleteGroupChannelInList: createAction<readonly SendbirdChatChannel.BaseChannel[]>(
    'chat/channels/deleteGroupChannelInList',
  ),

  setCurrentGroupChannel: createAction<SendbirdChatChannel.GroupChannel>('chat/channels/setCurrentGroupChannel'),
  fetchGroupChannel: createAsyncThunk<void, SendbirdChatChannel.ChannelURL>(
    'chat/channels/fetchGroupChannel',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;
      const channel_url = payload;

      try {
        const { data } = await fetchGroupChannel({ appId, channel_url });

        dispatch(ChannelsActions.setCurrentGroupChannel(data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const errorData = error.response?.data as { code: number } | undefined;
          if (errorData?.code === 400201) {
            // channel not found
            browserRouter.navigate(`/${appId}/group-channels`);
            toastBadRequestWarning(error);
            return rejectWithValue(error);
          }

          logException(error);
          return rejectWithValue(error);
        }
      }
    },
  ),
};
