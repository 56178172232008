import { useCallback } from 'react';

import { useDispatch } from '@/redux/hooks';
import { DialogsActions } from '@common/redux/actions/dialogs';

const useHideDialog = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(DialogsActions.hideDialog()), [dispatch]);
};

export default useHideDialog;
