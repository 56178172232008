import { createReducer } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { AGENT_GROUP_LIST_LIMIT } from '@constants';
import { AgentGroupsActions } from '@desk/redux/actions/agentGroups';

export interface AgentGroupsState {
  isFetching: boolean;
  items: Array<AgentGroup<'listItem'>>;
  query: string;
  current: {
    agentSearchQuery: string;
    agents: Pick<AgentGroupMember, 'id' | 'photoThumbnailUrl' | 'email' | 'displayName'>[];
  };
  pagination: LimitOffsetPagination;
}

const initialState: AgentGroupsState = {
  isFetching: false,
  items: [],
  query: '',
  current: {
    agentSearchQuery: '',
    agents: [],
  },
  pagination: {
    offset: 0,
    limit: AGENT_GROUP_LIST_LIMIT,
    count: 0,
    page: 1,
  } as LimitOffsetPagination,
};

export const agentGroupsReducer = createReducer<AgentGroupsState>(initialState, (builder) => {
  // AgentGroupsActions
  builder
    .addCase(AgentGroupsActions.fetchAgentGroups.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(AgentGroupsActions.fetchAgentGroups.fulfilled, (state, { payload }) => {
      state.items = payload.items;
      state.pagination = payload.pagination;
      state.isFetching = false;
    })
    .addCase(AgentGroupsActions.fetchAgentGroups.rejected, (state) => {
      state.isFetching = false;
    })
    .addCase(AgentGroupsActions.agentGroupQueryUpdated, (state, { payload }) => {
      state.query = payload.query;
    })
    .addCase(AgentGroupsActions.deleteAgentGroup.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(AgentGroupsActions.deleteAgentGroup.fulfilled, (state) => {
      state.isFetching = false;
    })
    .addCase(AgentGroupsActions.deleteAgentGroup.rejected, (state) => {
      state.isFetching = false;
    })
    .addCase(AgentGroupsActions.agentGroupsReset, () => initialState);

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
