import type { AxiosPromise } from 'axios';

import type { CancellableAxiosPromise } from '@api/cancellableAxios';
import { axios, getGateURL } from '@api/shared';
import { CDN_ASSETS_PREFIX } from '@constants';
import { fixedEncodeURIComponent } from '@utils';

export const changeAppName: ChangeAppNameAPI = ({ appId, appName }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/settings/change_app_name/`,
    {
      app_name: appName,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const togglePushEnabled = ({ appId, push_enabled }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/applications/detail/`,
    {
      push_enabled,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const fetchPushConfiguration = <T extends PushTypePath>({
  appId,
  pushTypePath,
}: {
  appId: string;
  pushTypePath: T;
}) => {
  return axios.get<{
    push_configurations: PushConfiguration<T>[];
  }>(`${getGateURL()}/platform/v3/applications/push/${pushTypePath}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const createPushConfiguration = <T extends PushTypePath>({
  appId,
  pushTypePath,
  payload,
}: {
  appId: string;
  pushTypePath: T;
  payload: CreatePushConfigurationPayload<T>;
}) => {
  return axios.post<{
    push_configurations: PushConfiguration<T>[];
  }>(`${getGateURL()}/platform/v3/applications/push/${pushTypePath}`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const updatePushConfiguration = <T extends PushTypePath>({
  appId,
  pushConfigurationId,
  pushTypePath,
  payload,
}: {
  appId: string;
  pushConfigurationId: string;
  pushTypePath: T;
  payload: UpdatePushConfigurationPayload<T>;
}) => {
  return axios.put<{ push_configurations: string }>(
    `${getGateURL()}/platform/v3/applications/push/${pushTypePath}/${pushConfigurationId}`,
    payload,
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const deletePushConfiguration = ({
  appId,
  pushTypePath,
  pushConfigurationId,
}: {
  appId: string;
  pushTypePath: PushTypePath;
  pushConfigurationId: string;
}) => {
  return axios.delete(`${getGateURL()}/platform/v3/applications/push/${pushTypePath}/${pushConfigurationId}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchPushMessageTemplates = ({ appId }) => {
  return axios.get(`${getGateURL()}/platform/v3/applications/push/message_templates`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const updatePushMessageTemplates = ({ appId, templateName, payload }) => {
  return axios.post(`${getGateURL()}/platform/v3/applications/push/message_templates/${templateName}`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const updatePushSettings: UpdatePushSettingsAPI = ({ appId, update }) => {
  return axios.put(`${getGateURL()}/platform/v3/applications/push/settings`, update, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const getWebhooksAllCategories = ({ appId }: { appId: string }) => {
  return axios.get(`${getGateURL()}/platform/v3/applications/settings/webhook`, {
    headers: {
      'App-Id': appId,
    },
    params: {
      display_all_webhook_categories: true,
    },
  });
};

type GetWebhooksInformationResponse = {
  webhook: {
    enabled: boolean;
    url: string;
    include_members: boolean;
    enabled_events: WebhookEvents[];
    include_unread_count: boolean;
  };
};

export const getWebhooksInformation = ({
  appId,
}: {
  appId: string;
}): CancellableAxiosPromise<GetWebhooksInformationResponse> => {
  return axios.get(`${getGateURL()}/platform/v3/applications/settings/webhook`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const updateWebhookInformation: UpdateWebhookInformationAPI = ({
  appId,
  enabled,
  enabled_events,
  url,
  include_members,
  include_unread_count,
}) => {
  return axios.put(
    `${getGateURL()}/platform/v3/applications/settings/webhook`,
    { enabled, url, enabled_events, include_members, include_unread_count },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const updateMaxLengthMessage = ({ appId, max_length_message }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/settings/max_length_message/`,
    {
      max_length_message,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const updateIgnoreMessageOffset = ({ appId, ignore_message_offset }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/settings/ignore_message_offset/`,
    {
      ignore_message_offset,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const updateAutoEventMessage = ({ appId, auto_event_message }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/settings/auto_event_message/`,
    {
      auto_event_message,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const fetchDefaultChannelInvitationPreference: FetchDefaultChannelInvitationPreferenceAPI = ({ appId }) => {
  return axios.get(`${getGateURL()}/platform/v3/applications/default_channel_invitation_preference`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const updateDefaultChannelInvitationPreference: UpdateDefaultChannelInvitationPreferenceAPI = ({
  appId,
  auto_accept,
}) => {
  return axios.put(
    `${getGateURL()}/platform/v3/applications/default_channel_invitation_preference`,
    {
      auto_accept,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const fetchDefaultProfanity = (): AxiosPromise<{ en: string; ko: string }> => {
  return axios.get(`${CDN_ASSETS_PREFIX}/data/profanity_filters.json`, {
    withCredentials: false,
  });
};

export const updateAccessTokenUserPolicy = ({ appId, guest_user_policy }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/settings/access_token_user_policy/`,
    {
      guest_user_policy,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const addCredentialsFilter = ({ appId, credentials_key }) => {
  return axios.post(
    `${getGateURL()}/platform/dapi/settings/credentials_filter/`,
    {
      credentials_key,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const removeCredentialsFilter = ({ appId, id }) => {
  return axios.delete(`${getGateURL()}/platform/dapi/settings/credentials_filter/${id}/`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const deleteApplication = ({ appId }) => {
  return axios.delete(`${getGateURL()}/platform/v3/applications`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const updateModerationInfoADMM = ({ appId, is_moderator_info_in_admin_message }) => {
  return axios.put(
    `${getGateURL()}/dashboard/application/${appId}/`,
    {
      is_moderator_info_in_admin_message,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const fetchSettingsGlobal: FetchSettingsGlobalAPI = ({ appId }) => {
  return axios.get(`${getGateURL()}/platform/v3/applications/settings_global`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const updateSettingsGlobal: UpdateSettingsGlobalAPI = ({ appId, payload }) => {
  return axios.put(`${getGateURL()}/platform/v3/applications/settings_global`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchSettingsForCustomChannelTypes: FetchSettingsForCustomChannelTypesAPI = ({ appId, limit, token }) => {
  return axios.get(`${getGateURL()}/platform/v3/applications/settings_by_channel_custom_type`, {
    headers: { 'App-Id': appId },
    params: { limit, token },
  });
};

export const fetchSettingsForCustomChannelType: FetchSettingsForCustomChannelTypeAPI = ({ appId, custom_type }) => {
  return axios.get(
    `${getGateURL()}/platform/v3/applications/settings_by_channel_custom_type/${fixedEncodeURIComponent(custom_type)}`,
    {
      headers: { 'App-Id': appId },
    },
  );
};

/**
 * https://sendbird.com/docs/chat/platform-api/v3/channel/setting-up-channels/create-channel-settings-by-a-custom-channel-type
 */
export const createSettingsForCustomChannelType: CreateSettingsForCustomChannelTypeAPI = ({ appId, ...payload }) => {
  return axios.post(`${getGateURL()}/platform/v3/applications/settings_by_channel_custom_type`, payload, {
    headers: { 'App-Id': appId },
  });
};

/**
 * https://sendbird.com/docs/chat/platform-api/v3/channel/setting-up-channels/update-channel-settings-by-a-custom-channel-type
 */
export const updateSettingsForCustomChannelType: UpdateSettingsForCustomChannelTypeAPI = ({
  appId,
  custom_type,
  ...payload
}) => {
  return axios.put(
    `${getGateURL()}/platform/v3/applications/settings_by_channel_custom_type/${fixedEncodeURIComponent(custom_type)}`,
    payload,
    {
      headers: { 'App-Id': appId },
    },
  );
};

export const getIPWhitelist: GetIPWhitelistAPI = ({ appId }) => {
  return axios.get(`${getGateURL()}/platform/v3/applications/settings/ip_whitelist`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const putIPWhitelist: PutIPWhitelistAPI = ({ appId, ip_whitelist_addresses }) => {
  return axios.put(
    `${getGateURL()}/platform/v3/applications/settings/ip_whitelist`,
    { ip_whitelist_addresses },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const deleteIPWhitelist: DeleteIPWhitelistAPI = ({ appId, ip_whitelist_addresses }) => {
  return axios.delete(`${getGateURL()}/platform/v3/applications/settings/ip_whitelist`, {
    data: { ip_whitelist_addresses },
    headers: {
      'App-Id': appId,
    },
  });
};

export const updateOpenChannelDynamicPartitioningOption: UpdateOpenChannelDynamicPartitioningOptionAPI = ({
  appId,
  option_type,
}) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/settings/open_channels_dynamic_partitioning/`,
    { option_type },
    { headers: { 'App-Id': appId } },
  );
};

export const fetchAccessControlList: FetchAccessControlListAPI = ({ appId }) =>
  axios.get(`${getGateURL()}/platform/dapi/settings/acls_from_sdk/`, {
    headers: { 'App-Id': appId },
  });

export const updateAccessControlList: UpdateAccessControlListAPI = ({ appId, payload }) =>
  axios.put(`${getGateURL()}/platform/dapi/settings/acls_from_sdk/`, { ...payload }, { headers: { 'App-Id': appId } });
