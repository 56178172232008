import type { FC, ReactNode } from 'react';
import { useMemo, useEffect, createContext, useContext, useCallback, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';

import { SubscriptionProduct, OrganizationStatus, SubscriptionName, ChatFeatureName } from '@constants';
import { toast } from '@feather/components/notification';
import useAppIntl from '@hooks/useAppIntl';
import useAuthorization from '@hooks/useAuthorization';
import useCurrentSubscription from '@hooks/useCurrentSubscription';
import useGetToday from '@hooks/useGetToday';
import useIsCallsActivatedOrganization from '@hooks/useIsCallsActivatedOrganization';
import useMonthlyUsage from '@hooks/useMonthlyUsage';
import useOrganization from '@hooks/useOrganization';
import safeParseNumber from '@utils/safeParseNumber';

const defaultUsage = {
  usage: 0,
  quota: 0,
  limit: 10000,
};

const CurrentChatSubscriptionContext = createContext<{
  isLoading: boolean;
  isLoaded: boolean;
  currentSubscription: Subscription | null;
  isFreeTrial: boolean;
  isFreeTrialMissing: boolean;
  isDeveloperPlan: boolean;
  isPlanOverChargeable: boolean;
  update: (data: Subscription) => void;
  chatUsage: {
    [key: string]: {
      usage: number;
      quota: number;
      limit: number;
    };
  };
  isAiSubscription: boolean;
}>({
  isLoading: false,
  currentSubscription: null,
  isLoaded: false,
  isFreeTrial: false,
  isFreeTrialMissing: false,
  isDeveloperPlan: false,
  isPlanOverChargeable: false,
  isAiSubscription: false,
  update: () => {},
  chatUsage: {
    [ChatFeatureName.MonthlyActiveUser]: defaultUsage,
    [ChatFeatureName.PeakConnection]: defaultUsage,
    [ChatFeatureName.AiEngine]: defaultUsage,
  },
});

export const CurrentChatSubscriptionProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const intl = useAppIntl();
  const { isSelfService } = useAuthorization();
  const { isOrganizationEmpty, isOrganizationDeactivated } = useOrganization((organization) => ({
    isOrganizationEmpty: isEmpty(organization),
    isOrganizationDeactivated:
      !isEmpty(organization) && organization && organization.status !== OrganizationStatus.Active,
  }));

  const isCallsEnabledOrganization = useIsCallsActivatedOrganization();
  const {
    isLoading: isLoadingChatSubscription,
    currentSubscription,
    isLoaded,
    update,
  } = useCurrentSubscription(SubscriptionProduct.Chat);
  const isLoading = isOrganizationEmpty || !isLoaded || isLoadingChatSubscription;
  const subscriptionName = currentSubscription?.subscription_name;

  const isAiSubscription = currentSubscription?.subscription_name.startsWith('ai_') ?? false;

  /**
   * Chat subscription can be null when either
   * 1) Organization has been deactivated or,
   * 2) Calls has been activated.
   *
   * Otherwise, a chat subscription must exist.
   */

  const getSelfServiceSubscriptionProps = useCallback((): {
    isFreeTrial: boolean;
    isFreeTrialMissing: boolean;
    isDeveloperPlan: boolean;
    isPlanOverChargeable: boolean;
  } => {
    if (isLoading || !isSelfService) {
      return { isFreeTrial: false, isFreeTrialMissing: false, isDeveloperPlan: false, isPlanOverChargeable: false };
    }

    return {
      isFreeTrial: subscriptionName === SubscriptionName.FreeTrial,
      isFreeTrialMissing: currentSubscription == null && !isCallsEnabledOrganization && !isOrganizationDeactivated,
      isDeveloperPlan: subscriptionName === SubscriptionName.Developer,
      isPlanOverChargeable: subscriptionName
        ? ![SubscriptionName.Developer, SubscriptionName.FreeTrial].includes(subscriptionName)
        : false,
    };
  }, [
    currentSubscription,
    isCallsEnabledOrganization,
    isLoading,
    isOrganizationDeactivated,
    isSelfService,
    subscriptionName,
  ]);

  const { isFreeTrialMissing, isDeveloperPlan, isPlanOverChargeable, isFreeTrial } = getSelfServiceSubscriptionProps();

  const getToday = useGetToday();
  const date = useRef(getToday());

  const { monthlyUsage } = useMonthlyUsage(date.current);

  const chatUsage = useMemo(() => {
    const isAvailable = monthlyUsage && currentSubscription?.plan;

    return {
      [ChatFeatureName.AiEngine]: isAvailable
        ? {
            usage: safeParseNumber(monthlyUsage.ai_engine, { as: 'float' }),
            quota: currentSubscription.ai_chatbot_plan.ai_chatbot.purchased_units,
            limit: currentSubscription.ai_chatbot_plan.ai_chatbot.hard_limit,
          }
        : defaultUsage,
      [ChatFeatureName.MonthlyActiveUser]: isAvailable
        ? {
            usage: monthlyUsage.mau,
            quota: currentSubscription.plan.mau.purchased_units,
            limit: currentSubscription.plan.mau.hard_limit,
          }
        : defaultUsage,
      [ChatFeatureName.PeakConnection]: isAvailable
        ? {
            usage: monthlyUsage.pc,
            quota: currentSubscription.plan.pc.purchased_units,
            limit: currentSubscription.plan.pc.hard_limit,
          }
        : defaultUsage,
    };
  }, [currentSubscription, monthlyUsage]);

  useEffect(() => {
    if (isFreeTrialMissing) {
      toast.warning({ message: intl.formatMessage({ id: 'common.noPlanError.toastErrorMessage' }) });
    }
  }, [intl, isFreeTrialMissing]);

  return (
    <CurrentChatSubscriptionContext.Provider
      value={useMemo(
        () => ({
          isLoading,
          isLoaded,
          currentSubscription,
          isFreeTrial,
          isFreeTrialMissing,
          isDeveloperPlan,
          isPlanOverChargeable,
          update,
          chatUsage,
          isAiSubscription,
        }),
        [
          isLoading,
          isLoaded,
          currentSubscription,
          isFreeTrial,
          isFreeTrialMissing,
          isDeveloperPlan,
          isPlanOverChargeable,
          update,
          chatUsage,
          isAiSubscription,
        ],
      )}
    >
      {children}
    </CurrentChatSubscriptionContext.Provider>
  );
};

export const useCurrentChatSubscription = () => {
  const { isSelfService } = useAuthorization();
  const {
    isLoading: isLoadingPlan,
    isLoaded,
    currentSubscription,
    isFreeTrialMissing,
    ...rest
  } = useContext(CurrentChatSubscriptionContext);

  const isLoading = isSelfService ? isLoadingPlan : false;

  return { isLoading, isLoaded, isSelfService, currentSubscription, isFreeTrialMissing, ...rest };
};
