import { useCallback } from 'react';

import { today } from '@internationalized/date';

import useDeskOrLocalTimeZone from './useDeskOrLocalTimeZone';

const useGetToday = () => {
  const timeZone = useDeskOrLocalTimeZone();

  return useCallback(() => today(timeZone), [timeZone]);
};

export default useGetToday;
