import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createContext, useContext, useMemo, useRef } from 'react';

import type { User } from '@sendbird/chat';
import { ConnectionState } from '@sendbird/chat';
import SendbirdChat, { ConnectionHandler, UserEventHandler } from '@sendbird/chat';
import { GroupChannelHandler, GroupChannelModule } from '@sendbird/chat/groupChannel';
import { OpenChannelHandler, OpenChannelModule } from '@sendbird/chat/openChannel';
import isEqual from 'lodash/isEqual';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

/**
 * HandlerParams interfaces are not exposed, while it is useful for the typing
 */
type ConnectionHandlerParams = Exclude<ConstructorParameters<typeof ConnectionHandler>[0], undefined>;
type GroupChannelHandlerParams = Exclude<ConstructorParameters<typeof GroupChannelHandler>[0], undefined>;
type OpenChannelHandlerParams = Exclude<ConstructorParameters<typeof OpenChannelHandler>[0], undefined>;
type UserEventHandlerParams = Exclude<ConstructorParameters<typeof UserEventHandler>[0], undefined>;

type ConnectionHandlerKey = keyof ConnectionHandlerParams;
export type GroupChannelHandlerKey = keyof GroupChannelHandlerParams;
export type OpenChannelHandlerKey = keyof OpenChannelHandlerParams;
/** @internal */
export type UserEventHandlerKey = keyof UserEventHandlerParams;

type ConnectionHandlerKeyArgsMap = {
  [key in ConnectionHandlerKey]: Parameters<Exclude<ConnectionHandlerParams[key], undefined>>;
};
export type GroupChannelHandlerKeyArgsMap = {
  [key in GroupChannelHandlerKey]: Parameters<Exclude<GroupChannelHandlerParams[key], undefined>>;
};
export type OpenChannelHandlerKeyArgsMap = {
  [key in OpenChannelHandlerKey]: Parameters<Exclude<OpenChannelHandlerParams[key], undefined>>;
};
/** @internal */
export type UserEventHandlerKeyArgsMap = {
  [key in UserEventHandlerKey]: Parameters<Exclude<UserEventHandlerParams[key], undefined>>;
};

type ConnectionHandlerSubject<Key extends ConnectionHandlerKey = ConnectionHandlerKey> = Subject<
  [Key, ConnectionHandlerKeyArgsMap[Key]]
>;
type GroupChannelHandlerSubject<Key extends GroupChannelHandlerKey = GroupChannelHandlerKey> = Subject<
  [Key, GroupChannelHandlerKeyArgsMap[Key]]
>;
type OpenChannelHandlerSubject<Key extends OpenChannelHandlerKey = OpenChannelHandlerKey> = Subject<
  [Key, OpenChannelHandlerKeyArgsMap[Key]]
>;
type UserEventHandlerSubject<Key extends UserEventHandlerKey = UserEventHandlerKey> = Subject<
  [Key, UserEventHandlerKeyArgsMap[Key]]
>;

type GetConnectionObservable = <Key extends ConnectionHandlerKey>(
  key: Key,
) => Observable<[Key, ConnectionHandlerKeyArgsMap[Key]]> | void;
type GetGroupChannelObservable = <Key extends GroupChannelHandlerKey>(
  key: Key,
) => Observable<[Key, GroupChannelHandlerKeyArgsMap[Key]]> | void;
type GetOpenChannelObservable = <Key extends OpenChannelHandlerKey>(
  key: Key,
) => Observable<[Key, OpenChannelHandlerKeyArgsMap[Key]]> | void;
type GetUserEventObservable = <Key extends UserEventHandlerKey>(
  key: Key,
) => Observable<[Key, UserEventHandlerKeyArgsMap[Key]]> | void;

const CONNECTION_HANDLER_KEYS = Object.keys(new ConnectionHandler()) as ConnectionHandlerKey[];
export const GROUP_CHANNEL_HANDLER_KEYS = Object.keys(new GroupChannelHandler()) as GroupChannelHandlerKey[];
export const OPEN_CHANNEL_HANDLER_KEYS = Object.keys(new OpenChannelHandler()) as OpenChannelHandlerKey[];
const USER_EVENT_HANDLER_KEYS = Object.keys(new UserEventHandler()) as UserEventHandlerKey[];

/** @internal */
export interface SendbirdChatContext {
  connect: (...args: Parameters<SendbirdChatInstance['connect']>) => Promise<User | void>;
  disconnect: () => Promise<void>;
  init: (...args: Omit<Parameters<typeof SendbirdChat.init>, 'modules'>) => SendbirdChatInstance | void;
  instance: Readonly<SendbirdChatInstance | null>;
  getConnectionObservable: GetConnectionObservable;
  getGroupChannelObservable: GetGroupChannelObservable;
  getOpenChannelObservable: GetOpenChannelObservable;
  getUserEventObservable: GetUserEventObservable;
  unsubscribeAll: () => void;
}

const SendbirdChatContext = createContext<SendbirdChatContext>({
  connect: async () => {},
  disconnect: async () => {},
  init: () => {},
  get instance() {
    return null;
  },
  getConnectionObservable: () => {},
  getGroupChannelObservable: () => {},
  getOpenChannelObservable: () => {},
  getUserEventObservable: () => {},
  unsubscribeAll: () => {},
});

type InstanceKey = 'app' | 'desk' | 'announcement';

const instanceMap: {
  [key in InstanceKey]?: {
    instance: SendbirdChatInstance;
    params: Omit<Parameters<typeof SendbirdChat.init>, 'modules'>[0];
  };
} = {};

type Props = {
  children: ReactNode;
  instanceKey: InstanceKey;
};

export const SendbirdChatProvider = ({ children, instanceKey }: Props) => {
  const sbRef = useRef<SendbirdChatInstance | null>(null);

  const connection$Ref = useRef<ConnectionHandlerSubject>(new Subject());
  const groupChannel$Ref = useRef<GroupChannelHandlerSubject>(new Subject());
  const openChannel$Ref = useRef<OpenChannelHandlerSubject>(new Subject());
  const userEvent$Ref = useRef<UserEventHandlerSubject>(new Subject());
  const unsubscribeAll$Ref = useRef<Subject<void>>(new Subject());

  const value = useMemo<SendbirdChatContext>(
    () => ({
      connect: async (...args) => {
        return (await sbRef.current?.connect(...args)) ?? undefined;
      },

      disconnect: async () => {
        try {
          await sbRef.current?.disconnect();
        } catch {
          // Multiple call of `disconnect` may cause accessing property of undefined
          // This catch block can be neglected
        }
      },

      init: (params) => {
        const oldInstance = instanceMap[instanceKey];
        if (oldInstance?.instance) {
          if (oldInstance.instance.connectionState === ConnectionState.OPEN && isEqual(oldInstance.params, params)) {
            sbRef.current = oldInstance.instance;
            return oldInstance.instance;
          }
          oldInstance.instance.disconnect().catch(() => {});
        }

        const instance = SendbirdChat.init({
          ...params,
          modules: [new GroupChannelModule(), new OpenChannelModule()],
        }) as SendbirdChatInstance;

        if (!instance) {
          return;
        }

        const connectionHandler = new ConnectionHandler(
          CONNECTION_HANDLER_KEYS.reduce((params, key) => {
            params[key] = (...args: ConnectionHandlerKeyArgsMap[typeof key]) => {
              connection$Ref.current.next([key, args]);
            };
            return params;
          }, {}),
        );

        const groupChannelHandler = new GroupChannelHandler(
          GROUP_CHANNEL_HANDLER_KEYS.reduce((params, key) => {
            params[key] = (...args: GroupChannelHandlerKeyArgsMap[typeof key]) =>
              groupChannel$Ref.current.next([key, args]);
            return params;
          }, {}),
        );

        const openChannelHandler = new OpenChannelHandler(
          OPEN_CHANNEL_HANDLER_KEYS.reduce((params, key: OpenChannelHandlerKey) => {
            params[key] = (...args: OpenChannelHandlerKeyArgsMap[typeof key]) =>
              openChannel$Ref.current.next([key, args]);
            return params;
          }, {}),
        );

        const userEventHandler = new UserEventHandler(
          USER_EVENT_HANDLER_KEYS.reduce((params, key) => {
            params[key] = (...args: UserEventHandlerKeyArgsMap[typeof key]) => userEvent$Ref.current.next([key, args]);
            return params;
          }, {}),
        );

        instance.addConnectionHandler('ConnectionHandler', connectionHandler);
        instance.groupChannel.addGroupChannelHandler('GroupChannelHandler', groupChannelHandler);
        instance.openChannel.addOpenChannelHandler('OpenChannelHandler', openChannelHandler);
        instance.addUserEventHandler('UserEventHandler', userEventHandler);

        sbRef.current = instance;
        instanceMap[instanceKey] = { instance, params };

        return instance;
      },

      get instance() {
        return sbRef.current;
      },

      getConnectionObservable: <Key extends ConnectionHandlerKey>(key: Key) => {
        if (connection$Ref.current) {
          return connection$Ref.current.pipe(
            takeUntil(unsubscribeAll$Ref.current),
            filter<[Key, ConnectionHandlerKeyArgsMap[Key]]>(([incomingKey]) => incomingKey === key),
          );
        }
      },

      getGroupChannelObservable: <Key extends GroupChannelHandlerKey>(key: Key) => {
        if (groupChannel$Ref.current) {
          return groupChannel$Ref.current.pipe(
            takeUntil(unsubscribeAll$Ref.current),
            filter<[Key, GroupChannelHandlerKeyArgsMap[Key]]>(([incomingKey]) => incomingKey === key),
          );
        }
      },

      getOpenChannelObservable: <Key extends OpenChannelHandlerKey>(key: Key) => {
        if (openChannel$Ref.current) {
          return openChannel$Ref.current.pipe(
            takeUntil(unsubscribeAll$Ref.current),
            filter<[Key, OpenChannelHandlerKeyArgsMap[Key]]>(([incomingKey]) => incomingKey === key),
          );
        }
      },

      getUserEventObservable: <Key extends UserEventHandlerKey>(key: Key) => {
        if (userEvent$Ref.current) {
          return userEvent$Ref.current.pipe(
            takeUntil(unsubscribeAll$Ref.current),
            filter<[Key, UserEventHandlerKeyArgsMap[Key]]>(([incomingKey]) => incomingKey === key),
          );
        }
      },

      unsubscribeAll: () => {
        unsubscribeAll$Ref.current.next();
      },
    }),
    [instanceKey],
  );

  useEffect(() => {
    const unsubscribeAll = unsubscribeAll$Ref.current;
    return () => {
      unsubscribeAll.next();
      sbRef.current?.disconnect().catch(() => {});
    };
  }, [instanceKey]);

  return <SendbirdChatContext.Provider value={value}>{children}</SendbirdChatContext.Provider>;
};

export const useSendbirdChat = () => useContext(SendbirdChatContext);

export const useSendbirdChatConnectionObservable = <Key extends ConnectionHandlerKey>(key: Key) => {
  const { instance, getConnectionObservable } = useSendbirdChat();
  const unsubscribe$Ref = useRef<Subject<void>>(new Subject());

  useEffect(() => {
    const unsubscribe = unsubscribe$Ref.current;
    return () => {
      unsubscribe.next();
    };
  }, []);

  return useMemo(
    () =>
      instance &&
      getConnectionObservable(key)!.pipe(
        takeUntil(unsubscribe$Ref.current),
        map<[Key, ConnectionHandlerKeyArgsMap[Key]], ConnectionHandlerKeyArgsMap[Key]>(([, args]) => args),
      ),
    [getConnectionObservable, instance, key],
  );
};

export const useSendbirdChatGroupChannelObservable = <Key extends GroupChannelHandlerKey>(key: Key) => {
  const { instance, getGroupChannelObservable } = useSendbirdChat();
  const unsubscribe$Ref = useRef<Subject<void>>(new Subject());

  useEffect(() => {
    const unsubscribe = unsubscribe$Ref.current;
    return () => {
      unsubscribe.next();
    };
  }, []);

  return useMemo(
    () =>
      instance &&
      getGroupChannelObservable(key)!.pipe(
        takeUntil(unsubscribe$Ref.current),
        map<[Key, GroupChannelHandlerKeyArgsMap[Key]], GroupChannelHandlerKeyArgsMap[Key]>(([, args]) => args),
      ),
    [getGroupChannelObservable, instance, key],
  );
};

export const useSendbirdChatOpenChannelObservable = <Key extends OpenChannelHandlerKey>(key: Key) => {
  const { instance, getOpenChannelObservable } = useSendbirdChat();
  const unsubscribe$Ref = useRef<Subject<void>>(new Subject());

  useEffect(() => {
    const unsubscribe = unsubscribe$Ref.current;
    return () => {
      unsubscribe.next();
    };
  }, []);

  return useMemo(
    () =>
      instance &&
      getOpenChannelObservable(key)!.pipe(
        takeUntil(unsubscribe$Ref.current),
        map<[Key, OpenChannelHandlerKeyArgsMap[Key]], OpenChannelHandlerKeyArgsMap[Key]>(([, args]) => args),
      ),
    [getOpenChannelObservable, instance, key],
  );
};

// Can be used in the future (currently not in use)
// export const useSendbirdChatUserEventObservable = <Key extends UserEventHandlerKey>(key: Key) => {
//   const { instance, getUserEventObservable } = useSendbirdChat();
//   const unsubscribe$Ref = useRef<Subject<void>>(new Subject());

//   useEffect(() => {
//     const unsubscribe = unsubscribe$Ref.current;
//     return () => {
//       unsubscribe.next();
//     };
//   }, []);

//   return useMemo(
//     () =>
//       instance &&
//       getUserEventObservable(key)!.pipe(
//         takeUntil(unsubscribe$Ref.current),
//         map<[Key, UserEventHandlerKeyArgsMap[Key]], UserEventHandlerKeyArgsMap[Key]>(([, args]) => args),
//       ),
//     [getUserEventObservable, instance, key],
//   );
// };

export const useSendbirdChatConnected = () => {
  const { instance } = useSendbirdChat();
  const [isConnected, setIsConnected] = useState(instance?.connectionState === ConnectionState.OPEN);

  const onConnected$ = useSendbirdChatConnectionObservable('onConnected');
  const onDisconnected$ = useSendbirdChatConnectionObservable('onDisconnected');
  const onReconnectStarted$ = useSendbirdChatConnectionObservable('onReconnectStarted');
  const onReconnectSucceeded$ = useSendbirdChatConnectionObservable('onReconnectSucceeded');
  const onReconnectFailed$ = useSendbirdChatConnectionObservable('onReconnectFailed');

  useEffect(() => {
    const subscriptionOnConnected = onConnected$?.subscribe(() => setIsConnected(true));
    const subscriptionOnDisconnected = onDisconnected$?.subscribe(() => setIsConnected(false));
    const subscriptionOnReconnectStarted = onReconnectStarted$?.subscribe(() => setIsConnected(false));
    const subscriptionOnReconnectSucceeded = onReconnectSucceeded$?.subscribe(() => setIsConnected(true));
    const subscriptionOnReconnectFailed = onReconnectFailed$?.subscribe(() =>
      setIsConnected(false || instance?.connectionState === ConnectionState.OPEN),
    );

    return () => {
      subscriptionOnConnected?.unsubscribe();
      subscriptionOnDisconnected?.unsubscribe();
      subscriptionOnReconnectStarted?.unsubscribe();
      subscriptionOnReconnectSucceeded?.unsubscribe();
      subscriptionOnReconnectFailed?.unsubscribe();
    };
  }, [
    instance?.connectionState,
    onConnected$,
    onDisconnected$,
    onReconnectFailed$,
    onReconnectStarted$,
    onReconnectSucceeded$,
  ]);

  useEffect(() => {
    setIsConnected(instance?.connectionState === ConnectionState.OPEN);
  }, [instance?.connectionState]);

  return useMemo(() => isConnected, [isConnected]);
};
