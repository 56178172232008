import type { RootState } from '@/store';

import { useShallowEqualSelector } from '.';

type LocalState = RootState['auth'];
type LocalSelector<T> = (state: LocalState) => T;

export function useAuth(): LocalState;
export function useAuth<T>(selector: LocalSelector<T>): T;

export function useAuth<T>(selector?: LocalSelector<T>) {
  return useShallowEqualSelector<T | LocalState>((state) =>
    selector ? selector(state.auth) : (state.auth as LocalState),
  );
}
