export const CallsAllowedRegionsForCreateApplication = [
  'staging',
  'intoz',
  'gojek-test',
  'ap-2',
  'ap-3',
  'ap-5',
  'us-1',
  'us-2',
  'us-3',
  'eu-1',
  'hinge',
  'brightinsight',
  'airasia',
  'verse',
  'vmuae',
  'paytmcs',
];

// FIXME(DBP-1208): ap-1 will be migrated
export const CallsAllowedRegions = [...CallsAllowedRegionsForCreateApplication, 'ap-1'];

export const FREE_VOUCHER_CREDITS = 20;

export const DAYS_PER_MONTH_OF_TERM = 31;
