import type { ReactNode } from 'react';

const convertNodeArrayToReactFragment = (nodeArray: readonly ReactNode[]) =>
  nodeArray.reduce(
    (acc, cur) => (
      <>
        {acc}
        {cur}
      </>
    ),
    null,
  );

export default convertNodeArrayToReactFragment;
