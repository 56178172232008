import type { ReactNode } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { NAVIGATION_BAR_HEIGHT } from '@constants/ui';
import { cssVariables, shadow, transitionDefault } from '@feather';
import { ZIndexes } from '@ui/zIndexes';

import { Portal } from '../portal';
import { drawerTransitionDurationSecond } from './constants';
import { useDrawer } from './useDrawer';

type Props = {
  id: string;
  className?: string;
  children?: ReactNode;
  isFullHeight?: boolean;
  isDarkBackground?: boolean;
};

const Container = styled.div<{ isOpen: boolean; isFullHeight: Props['isFullHeight'] }>`
  position: fixed;
  right: ${({ isOpen }) => (isOpen ? '0' : '-16px')};
  bottom: 0;
  max-width: 100%;
  background: white;
  transform: translateX(${({ isOpen }) => (isOpen ? 0 : '100%')});
  transition: ${drawerTransitionDurationSecond}s ${transitionDefault};
  transition-property: transform, box-shadow;
  z-index: ${ZIndexes.drawer};

  ${({ isOpen }) => isOpen && shadow[12]}
  ${({ isFullHeight }) =>
    css`
      top: ${isFullHeight ? 0 : NAVIGATION_BAR_HEIGHT}px;
      height: ${isFullHeight ? '100%' : `calc(100% - ${NAVIGATION_BAR_HEIGHT}px)`};
    `}
`;

const Background = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZIndexes.drawerBackground};
  background: ${({ isOpen }) => (isOpen ? cssVariables('bg-overlay-4') : 'rgba(0,0,0,0)')};
  transition: background 0.3s ${transitionDefault};
`;

export const Drawer = forwardRef<HTMLDivElement, Props>(
  ({ id, children, className, isFullHeight, isDarkBackground }, ref) => {
    const { activeDrawerID } = useDrawer();
    const [isTransitioned, setIsTransitioned] = useState(false);
    const isOpen = id === activeDrawerID;

    useEffect(() => {
      if (isOpen) {
        const setTimeoutId = setTimeout(() => {
          setIsTransitioned(true);
        }, drawerTransitionDurationSecond * 1000);
        return () => {
          clearTimeout(setTimeoutId);
        };
      }

      if (isTransitioned) {
        const setTimeoutId = setTimeout(() => {
          setIsTransitioned(false);
        }, drawerTransitionDurationSecond * 1000);
        return () => {
          clearTimeout(setTimeoutId);
        };
      }
    }, [isOpen, isTransitioned]);

    if (!isOpen && !isTransitioned) {
      return null;
    }

    return (
      <Portal>
        <Container
          ref={ref}
          className={className}
          role="complementary"
          isOpen={isOpen && isTransitioned}
          aria-hidden={!isOpen}
          isFullHeight={isFullHeight}
        >
          {children}
        </Container>
        {isDarkBackground && <Background isOpen={isOpen && isTransitioned} data-test-id="DrawerBackground" />}
      </Portal>
    );
  },
);
