import { saveAs as fileSaverSaveAs } from 'file-saver';

const saveAs = ({
  data,
  filename,
  type,
}: {
  type?: string; // ex 'text/csv;charset=utf-8',
  data: any;
  filename: string;
}) => {
  const csvBlob = new Blob([data], { type: type || 'text/csv;charset=utf-8' });
  fileSaverSaveAs(csvBlob, filename);
};

export default saveAs;
