import type { FC } from 'react';
import React, { createContext } from 'react';

import { I18nProvider } from '@react-aria/i18n';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from '@constants';
import useFeatureFlags from '@hooks/useFeatureFlags';
import { useIsDeskLocaleKoKR } from '@hooks/useIsDeskLocale/locales/ko_KR';

import useIntlMessages from './useIntlMessages';

export const IntlMessagesLoadingContext = createContext<{ isLoading: boolean }>({ isLoading: false });

export const ConnectedIntlProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const isDeskLocaleKoKR = useIsDeskLocaleKoKR();

  const {
    flags: { intlInspector },
  } = useFeatureFlags();

  const locale = (() => {
    if (intlInspector) {
      return 'key';
    }
    if (isDeskLocaleKoKR) {
      return 'ko';
    }
    return DEFAULT_LOCALE;
  })();

  const { isLoading, data: messages } = useIntlMessages(locale);

  return (
    <IntlMessagesLoadingContext.Provider value={{ isLoading }}>
      <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE} messages={messages}>
        <I18nProvider locale={locale}>{children}</I18nProvider>
      </IntlProvider>
    </IntlMessagesLoadingContext.Provider>
  );
};
