import { useCallback, useEffect } from 'react';

import { useLocation } from '@/router';

import useHideDialog from '../useHideDialog';

type Props = {
  onLocationChange: () => void;
};

const useListenLocationPathChange = (props?: Props) => {
  const hideDialog = useHideDialog();
  const location = useLocation();
  const handleLocationChange = useCallback(() => {
    props?.onLocationChange();
  }, [props]);

  useEffect(() => {
    window.addEventListener('popstate', hideDialog);
    return () => {
      window.removeEventListener('popstate', hideDialog);
    };
  }, [hideDialog]);

  useEffect(() => {
    handleLocationChange();
  }, [handleLocationChange, location.pathname]);
};

export default useListenLocationPathChange;
