import axiosModule from 'axios';

import setupAxiosInterceptors from '@api/setupAxiosInterceptors';
import { axios, getGateURL } from '@api/shared';
import { LIST_LIMIT } from '@constants';
import { UserSearchQueryOptions } from '@constants/core';
import { fixedEncodeURIComponent } from '@utils/generals';

type FetchUsersResponse = { users: SDKUser[]; next: string };

const gateAxios = axiosModule.create({ baseURL: getGateURL(), withCredentials: true });
setupAxiosInterceptors(gateAxios);

const getQueryOptions = ({ query, option }: { query: string; option: string }) => {
  const encodedQuery = fixedEncodeURIComponent(query.trim());

  if (option === UserSearchQueryOptions.QUERY_USER_ID) {
    return { user_ids: encodedQuery };
  }
  if (option === UserSearchQueryOptions.QUERY_USER_NICKNAME) {
    return { nickname: encodedQuery };
  }
  if (option === UserSearchQueryOptions.QUERY_USER_NICKNAME_STARTSWITH) {
    return { nickname_startswith: encodedQuery };
  }
  return '';
};

/** Users */
export const fetchUsers = ({
  appId,
  token = '',
  limit,
  metadata,
  ...params
}: CoreAPIPayload<{
  token?: string;
  limit?: number;
  active_mode?: UserActiveMode;
  shot_bot?: boolean;
  user_ids?: string;
  nickname?: string;
  nickname_startswith?: string;
  metadata?: {
    metadatakey: string;
    metadatavalues_in: string;
  };
}>) =>
  gateAxios.get<FetchUsersResponse>(`${getGateURL()}/platform/v3/users`, {
    params: {
      token,
      limit: limit ?? LIST_LIMIT,
      ...metadata,
      ...params,
    },
    headers: { 'App-Id': appId },
  });

export const searchUsers: SearchUsersAPI = ({
  appId,
  query,
  option,
  limit = LIST_LIMIT,
  next = '',
  active_mode = 'activated',
  metadata,
}) => {
  return axios.get(`${getGateURL()}/platform/v3/users`, {
    params: {
      token: next,
      limit,
      active_mode,
      ...getQueryOptions({ query, option }),
      ...metadata,
    },
    headers: {
      'App-Id': appId,
    },
  });
};

export const createUser: CreateUserAPI = ({ appId, userId, nickname, profileUrl, profileFile, issueAccessToken }) => {
  const formData = new FormData();
  formData.set('user_id', userId);
  formData.set('nickname', nickname);

  // profile_url is a required parameter even if it's empty
  formData.set('profile_url', profileUrl || '');

  // optionally can upload a profile image
  if (profileFile) {
    formData.set('profile_file', profileFile);
  }

  if (issueAccessToken) {
    formData.set('issue_access_token', 'true');
  }
  return axios.post(`${getGateURL()}/platform/v3/users`, formData, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const deleteUser = ({ appId, userId }: { appId: string; userId: string }) => {
  return gateAxios.delete<{}>(`${getGateURL()}/platform/v3/users/${encodeURIComponent(userId)}`, {
    headers: { 'App-Id': appId },
  });
};

export const reactivateUser: ReactivateUserAPI = ({ appId, userId }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/users/${encodeURIComponent(userId)}/moderations`,
    {
      is_active: true,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const deactivateUser: DeactivateUserAPI = ({ appId, userId, leaveAll }) => {
  return axios.put(
    `${getGateURL()}/platform/dapi/users/${encodeURIComponent(userId)}/moderations`,
    {
      is_active: false,
      leave_all_when_deactivated: leaveAll,
    },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

/** retrieves user details */
export const fetchUser = ({
  appId,
  userId,
  with_access_token = false,
  include_phone_number = false,
}: {
  appId: string;
  userId: User['userId'];
  with_access_token?: boolean;
  include_phone_number?: boolean;
}) => {
  return gateAxios.get<SDKUser>(`/platform/v3/users/${encodeURIComponent(userId)}`, {
    headers: { 'App-Id': appId },
    params: { with_access_token, ...(include_phone_number && { include_phone_number: true }) },
  });
};

export const editUser: EditUserAPI = ({ appId, userId, ...updates }) => {
  const payload = new FormData();
  if (updates.nickname) {
    payload.set('nickname', updates.nickname);
  }

  // using != null here to clear a profile image by passing an empty string
  if (updates.profileUrl != null) {
    payload.set('profile_url', updates.profileUrl);
  }

  // can optionally upload an image file to update profile image
  if (updates.profileFile) {
    payload.set('profile_file', updates.profileFile);
  }

  if (updates.issueAccessToken) {
    payload.set('issue_access_token', 'true');
  }

  return axios.put(`${getGateURL()}/platform/v3/users/${encodeURIComponent(userId)}`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchUserPushTokens: FetchUserPushTokensAPI = ({ appId, userId, tokenType }) => {
  return axios.get(`${getGateURL()}/platform/v3/users/${encodeURIComponent(userId)}/push/${tokenType}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const deleteUserPushToken: DeleteUserPushTokenAPI = ({ appId, userId, tokenType, token }) => {
  return axios.delete(
    `${getGateURL()}/platform/v3/users/${encodeURIComponent(userId)}/push/${tokenType}/${encodeURIComponent(token)}`,
    { headers: { 'App-Id': appId } },
  );
};

/** Register an operator */
export const fetchRegisteredOperator: FetchRegisteredOperatorAPI = ({ appId, organizationUid, userId }) => {
  return axios.get(
    `${getGateURL()}/dashboard/organization/${organizationUid}/member_by_sdk_user/?sdk_user_id=${encodeURIComponent(
      userId,
    )}`,
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const searchOrganizationMembers: SearchOrganizationMembersAPI = ({
  appId,
  organizationUid,
  userId = '',
  email = '',
}) => {
  return axios.get(
    `${getGateURL()}/dashboard/organization/${organizationUid}/members/search/?email=${encodeURIComponent(
      email,
    )}&sdk_user_id=${encodeURIComponent(userId)}`,
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const registerUserAsOperator: RegisterUserAsOperatorAPI = ({ appId, organizationUid, userId, email }) => {
  return axios.put(
    `${getGateURL()}/dashboard/organization/${organizationUid}/members/link_sdk_user/`,
    { email, sdk_user_id: userId },
    {
      headers: {
        'App-Id': appId,
      },
    },
  );
};

export const unregisterUserAsOperator: UnregisterUserAsOperatorAPI = ({ appId, organizationUid, userId, email }) => {
  return axios.delete(`${getGateURL()}/dashboard/organization/${organizationUid}/members/link_sdk_user/`, {
    data: { sdk_user_id: userId, email },
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchUserGroupChannelCount: FetchUserGroupChannelCountAPI = ({ appId, userId, super_mode }) => {
  return axios.get(`${getGateURL()}/platform/v3/users/${encodeURIComponent(userId)}/group_channel_count`, {
    params: {
      state: 'joined',
      super_mode,
    },
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchUserMetadata: FetchUserMetadata = ({ userId, appId }) => {
  return axios.get(`${getGateURL()}/platform/v3/users/${userId}/metadata`, {
    headers: {
      'App-Id': `${appId}`,
    },
  });
};

export const deleteUserMetadata: DeleteUserMetadata = ({ userId, appId }) => {
  return axios.delete(`${getGateURL()}/platform/v3/users/${userId}/metadata`, {
    headers: {
      'App-Id': `${appId}`,
    },
  });
};

export const postUserMetadata: PostUserMetadata = ({ userId, appId, metadata }) => {
  return axios.post(
    `${getGateURL()}/platform/v3/users/${userId}/metadata`,
    { metadata },
    {
      headers: {
        'App-Id': `${appId}`,
      },
    },
  );
};

export const fetchFlaggedUsers: FetchFlaggedUsers = ({ appId, next = '', limit = LIST_LIMIT }) => {
  return axios.get(`${getGateURL()}/platform/v3/applications/settings_global/blacklist`, {
    params: {
      token: next,
      limit,
    },
    headers: {
      'App-Id': `${appId}`,
    },
  });
};

export const postFlaggedUsers: PostFlaggedUsers = ({ appId, userIds }) => {
  return axios.post(
    `${getGateURL()}/platform/v3/applications/settings_global/blacklist`,
    {
      blacklist_user_ids: userIds,
    },
    {
      headers: {
        'App-Id': `${appId}`,
      },
    },
  );
};

export const deleteFlaggedUsers: DeleteFlaggedUsers = ({ appId, userIds }) => {
  return axios.delete(`${getGateURL()}/platform/v3/applications/settings_global/blacklist`, {
    params: {
      user_ids: userIds,
    },
    headers: {
      'App-Id': `${appId}`,
    },
  });
};
