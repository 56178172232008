import type { QueryFunction } from 'react-query';

import type QueryKeyPrefix from '@constants/queryKey';
import { fetchAPITokens, fetchEnabledFeatures } from '@core/api/application';
import { fetchUser } from '@core/api/users';

export const fetchAPITokensQueryFn: QueryFunction<
  ChatAPIToken[],
  [(typeof QueryKeyPrefix)['ChatApiTokens'], Parameters<typeof fetchAPITokens>[0]]
> = async ({ queryKey: [, { appId, role }] }) => {
  const { data } = await fetchAPITokens({ appId, role });
  return data.api_tokens;
};

export const fetchEnabledFeaturesQueryFn: QueryFunction<
  EnabledFeatures,
  [(typeof QueryKeyPrefix)['AppFetchEnabledFeatures'], string]
> = async ({ queryKey: [, appId] }) => {
  const { data } = await fetchEnabledFeatures(appId);
  return data;
};

export const fetchUserQueryFn: QueryFunction<
  SDKUser,
  [(typeof QueryKeyPrefix)['AppUser'], Parameters<typeof fetchUser>[0]]
> = async ({ queryKey: [, { appId, userId, with_access_token, include_phone_number }] }) => {
  const { data } = await fetchUser({ appId, userId, with_access_token, include_phone_number });
  return data;
};
