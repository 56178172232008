import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { ApplicationActions } from '@core/redux/actions/application';
import { SettingsActions } from '@core/redux/actions/settings';

export interface SettingsState {
  isFetchingAppName: boolean;
  isFetchingPushMessageTemplates: boolean;

  isFetchingWebhookInformation: boolean;
  isFetchingMaxLengthMessage: boolean;
  isFetchingIgnoreMessageOffset: boolean;
  isFetchingAutoEventMessage: boolean;
  isFetchingAccessTokenUserPolicy: boolean;
  isAddingCredentialsFilter: boolean;
  isFetchingModeratorInfoADMM: boolean;

  isPushMessageTemplatesFetched: boolean; // push message templates called check

  isModeratorInfoInAdminMessage: boolean;

  isFetchedWebhook: boolean;
  webhook: WebhookSetting;
  webhookAllEvents: string[];
}

const initialState: SettingsState = {
  isFetchingAppName: false,
  isFetchingPushMessageTemplates: false,

  isFetchingWebhookInformation: false,
  isFetchingMaxLengthMessage: false,
  isFetchingIgnoreMessageOffset: false,
  isFetchingAutoEventMessage: false,
  isFetchingAccessTokenUserPolicy: false,
  isAddingCredentialsFilter: false,
  isFetchingModeratorInfoADMM: false,

  isPushMessageTemplatesFetched: false,

  isFetchedWebhook: false,
  webhook: {
    enabled: false,
    url: '',
    include_members: false,
    include_unread_count: false,
    enabled_events: [],
  },
  webhookAllEvents: [],

  isModeratorInfoInAdminMessage: false,
};

export const settingsReducer = createReducer<SettingsState>(initialState, (builder) => {
  // SettingsActions
  builder
    .addCase(SettingsActions.getWebhookAllCategories.pending, (state) => {
      state.isFetchingWebhookInformation = true;
    })
    .addCase(SettingsActions.getWebhookAllCategories.fulfilled, (state, { payload }) => {
      const webhookAllEvents = payload;
      state.webhookAllEvents = webhookAllEvents;
      state.isFetchingWebhookInformation = false;
    })
    .addCase(SettingsActions.getWebhookAllCategories.rejected, (state) => {
      state.isFetchingWebhookInformation = false;
    })
    .addCase(SettingsActions.getWebhooksInformation.pending, (state) => {
      state.isFetchedWebhook = false;
      state.isFetchingWebhookInformation = true;
    })
    .addCase(SettingsActions.getWebhooksInformation.fulfilled, (state, { payload }) => {
      const webhook = payload;
      state.webhook = webhook;
      state.isFetchedWebhook = true;
      state.isFetchingWebhookInformation = false;
    })
    .addCase(SettingsActions.getWebhooksInformation.rejected, (state) => {
      state.isFetchedWebhook = false;
      state.isFetchingWebhookInformation = false;
    })
    .addCase(SettingsActions.updateWebhookInformation.pending, (state) => {
      state.isFetchingWebhookInformation = true;
    })
    .addCase(SettingsActions.updateWebhookInformation.fulfilled, (state, { payload }) => {
      const webhook = payload;
      state.webhook = webhook;
      state.isFetchingWebhookInformation = false;
    })
    .addCase(SettingsActions.updateWebhookInformation.rejected, (state) => {
      state.isFetchingWebhookInformation = false;
    })
    .addCase(SettingsActions.updateMaxLengthMessage.pending, (state) => {
      state.isFetchingMaxLengthMessage = true;
    })
    .addCase(SettingsActions.updateMaxLengthMessage.fulfilled, (state) => {
      state.isFetchingMaxLengthMessage = false;
    })
    .addCase(SettingsActions.updateIgnoreMessageOffset.pending, (state) => {
      state.isFetchingIgnoreMessageOffset = true;
    })
    .addCase(SettingsActions.updateAutoEventMessage.pending, (state) => {
      state.isFetchingAutoEventMessage = true;
    })
    .addCase(SettingsActions.updateAccessTokenUserPolicy.pending, (state) => {
      state.isFetchingAccessTokenUserPolicy = true;
    })
    .addCase(SettingsActions.addCredentialsFilter.pending, (state) => {
      state.isAddingCredentialsFilter = true;
    });

  // Other actions
  builder.addCase(ApplicationActions.changeAppName.pending, (state) => {
    state.isFetchingAppName = true;
  });

  // SettingsActions
  builder
    .addMatcher(
      isAnyOf(SettingsActions.fetchPushMessageTemplates.pending, SettingsActions.updatePushMessageTemplates.pending),
      (state) => {
        state.isFetchingPushMessageTemplates = true;
      },
    )
    .addMatcher(
      isAnyOf(
        SettingsActions.fetchPushMessageTemplates.fulfilled,
        SettingsActions.updatePushMessageTemplates.fulfilled,
      ),
      (state) => {
        if (state.isPushMessageTemplatesFetched) {
          state.isFetchingPushMessageTemplates = false;
        } else {
          state.isPushMessageTemplatesFetched = true;
          state.isFetchingPushMessageTemplates = false;
        }
      },
    )
    .addMatcher(
      isAnyOf(SettingsActions.fetchPushMessageTemplates.rejected, SettingsActions.updatePushMessageTemplates.rejected),
      (state) => {
        state.isFetchingPushMessageTemplates = false;
      },
    )
    .addMatcher(
      isAnyOf(SettingsActions.updateIgnoreMessageOffset.fulfilled, SettingsActions.updateIgnoreMessageOffset.rejected),
      (state) => {
        state.isFetchingIgnoreMessageOffset = false;
      },
    )
    .addMatcher(
      isAnyOf(SettingsActions.updateAutoEventMessage.fulfilled, SettingsActions.updateAutoEventMessage.rejected),
      (state) => {
        state.isFetchingAutoEventMessage = false;
      },
    )
    .addMatcher(
      isAnyOf(
        SettingsActions.updateAccessTokenUserPolicy.fulfilled,
        SettingsActions.updateAccessTokenUserPolicy.rejected,
      ),
      (state) => {
        state.isFetchingAccessTokenUserPolicy = false;
      },
    )
    .addMatcher(
      isAnyOf(SettingsActions.addCredentialsFilter.fulfilled, SettingsActions.addCredentialsFilter.rejected),
      (state) => {
        state.isAddingCredentialsFilter = false;
      },
    )
    .addMatcher(
      isAnyOf(SettingsActions.fetchModeratorInfoADMM.pending, SettingsActions.updateModeratorInfoADMM.pending),
      (state) => {
        state.isFetchingModeratorInfoADMM = true;
      },
    )
    .addMatcher(
      isAnyOf(SettingsActions.fetchModeratorInfoADMM.fulfilled, SettingsActions.updateModeratorInfoADMM.fulfilled),
      (state, { payload }) => {
        state.isFetchingModeratorInfoADMM = false;
        state.isModeratorInfoInAdminMessage = payload;
      },
    )
    .addMatcher(
      isAnyOf(SettingsActions.fetchModeratorInfoADMM.rejected, SettingsActions.updateModeratorInfoADMM.rejected),
      (state) => {
        state.isFetchingModeratorInfoADMM = false;
      },
    );

  // Other actions
  builder
    .addMatcher(
      isAnyOf(ApplicationActions.changeAppName.fulfilled, ApplicationActions.changeAppName.rejected),
      (state) => {
        state.isFetchingAppName = false;
      },
    )
    .addMatcher(
      isAnyOf(ApplicationActions.applicationReset, AuthenticationActions.unauthenticated),
      () => initialState,
    );
});
