import type { RootState } from '@/store';

import { useShallowEqualSelector } from '.';

type LocalState = RootState['desk'];
type LocalSelector<T> = (state: LocalState) => T;

export function useDesk(): LocalState;
export function useDesk<T>(selector: LocalSelector<T>): T;

export function useDesk<T>(selector?: LocalSelector<T>) {
  return useShallowEqualSelector<T>((state) => (selector ? selector(state.desk) : (state.desk as T)));
}
