import type { FC, ContextType } from 'react';
import { createContext, useState, useCallback, useEffect } from 'react';

import is from '@sindresorhus/is';
import type { QueryObserverResult } from 'react-query';
import { useQuery } from 'react-query';

import { fetchSubscriptionInfoQueryFn } from '@common/api/queryFn';
import { SubscriptionName, SubscriptionProduct } from '@constants';
import QueryKeyPrefix from '@constants/queryKey';
import useAuthentication from '@hooks/useAuthentication';
import useAuthorization from '@hooks/useAuthorization';
import useErrorToast from '@hooks/useErrorToast';
import useOrganization from '@hooks/useOrganization';

type SubscriptionInfos = Record<SubscriptionProduct, SubscriptionInfo>;

const initialPlanInfos = Object.values(SubscriptionProduct).reduce<SubscriptionInfos>((infos, product) => {
  infos[product] = { current: null, future: null };
  return infos;
}, {} as SubscriptionInfos);

export const SubscriptionInfoContext = createContext<{
  isLoading: boolean;
  isLoaded: boolean;
  subscriptions: SubscriptionInfos;
  updateSubscriptions: (payload: { product: SubscriptionProduct; info: SubscriptionInfo }) => void;
  fetchSubscriptions: () => Promise<QueryObserverResult<FetchSubscriptionInfoResponse<Subscription>>>;
  isUnsubscribing: boolean;
  isUnsubscribed: boolean;
  isPlanChangeScheduled: boolean;
}>({
  isLoading: false,
  isLoaded: false,
  subscriptions: initialPlanInfos,
  updateSubscriptions: () => {},
  fetchSubscriptions: () => new Promise(() => {}),
  isUnsubscribing: false,
  isUnsubscribed: false,
  isPlanChangeScheduled: false,
});

export const SubscriptionInfoContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState(initialPlanInfos);
  const organization = useOrganization();

  const { authenticated } = useAuthentication();
  const { isPermitted } = useAuthorization();
  const updateSubscriptions: ContextType<typeof SubscriptionInfoContext>['updateSubscriptions'] = useCallback(
    ({ product, info }) => {
      setSubscriptions({ ...subscriptions, [product]: info });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const hasOrganizationGeneralPermission = isPermitted(['organization.general.all', 'organization.general.view']);

  const {
    data: subscriptionInfo,
    status,
    error,
    refetch: fetchSubscriptions,
  } = useQuery({
    queryKey: [QueryKeyPrefix.OrgSubscriptionInfo, { product: SubscriptionProduct.Chat }],
    queryFn: fetchSubscriptionInfoQueryFn,
    enabled: authenticated && hasOrganizationGeneralPermission && is.nonEmptyObject(organization),
  });

  const { current_subscription: current, future_subscription: future } = subscriptionInfo ?? {
    current_subscription: null,
    future_subscription: null,
  };

  useErrorToast(error);

  useEffect(() => {
    if (subscriptionInfo) {
      updateSubscriptions({
        product:
          subscriptionInfo.future_subscription?.product ||
          subscriptionInfo.current_subscription?.product ||
          SubscriptionProduct.Chat,
        info: {
          current: subscriptionInfo.current_subscription,
          future: subscriptionInfo.future_subscription,
        },
      });
    }
  }, [authenticated, subscriptionInfo, updateSubscriptions]);

  const isCurrentFreePlan =
    current != null && [SubscriptionName.FreeTrial, SubscriptionName.Free].includes(current.subscription_name);
  const isCurrentPlanEnding = current != null && typeof current.end_date === 'string';
  const isFuturePlanExist = future != null;
  const isUnsubscribing = isCurrentPlanEnding && !isCurrentFreePlan && !isFuturePlanExist;
  const isUnsubscribed = current == null && status === 'success';
  const isPlanChangeScheduled = (!isCurrentFreePlan && isCurrentPlanEnding) || isFuturePlanExist;

  return (
    <SubscriptionInfoContext.Provider
      value={{
        isLoading: status === 'loading',
        isLoaded: status === 'success' || status === 'error',
        subscriptions,
        updateSubscriptions,
        fetchSubscriptions,
        isUnsubscribing,
        isUnsubscribed,
        isPlanChangeScheduled,
      }}
    >
      {children}
    </SubscriptionInfoContext.Provider>
  );
};
