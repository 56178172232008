export const CommonColors = {
  WHITE: '#ECECEC',
  BLACK: '#2E2E2E',
  GRADIENT: 'GRADIENT',
};

export const LightThemeColors = {
  PRIMARY: '#742DDD',
  PURPLE: '#9840D0',
  RED: '#CD4525',
  ORANGE: '#F09359',
  YELLOW: '#F6C453',
  GREEN: '#459A71',
  BLUE: '#4C7DF7',
};

export const DarkThemeColors = {
  PRIMARY: '#A29DF8',
  PURPLE: '#B166D6',
  RED: '#EA7470',
  ORANGE: '#F3AF62',
  YELLOW: '#F8D871',
  GREEN: '#60B58B',
  BLUE: '#81A1EC',
};

export const onboardingStepPaths = {
  STEP_ONE_PATH: '1',
  STEP_TWO_PATH: '2',
  STEP_THREE_PATH: '3',
};

export const STEPS = {
  STEP_ONE: 1,
  STEP_TWO: 2,
  STEP_THREE: 3,
};

// Users will be redirected to here⬇ when they sign up at sendbird.com
// {origin}/application-id/ai-chatbots/onboarding/steps
export const FIRST_ONBOARDING_STEP_URL = `/onboarding/steps/${onboardingStepPaths.STEP_ONE_PATH}`;
export const FIRST_ONBOARDING_STEP_FULL_URL = `/application-id/ai-chatbots/onboarding/steps/${onboardingStepPaths.STEP_ONE_PATH}`;
