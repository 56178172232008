import { LocalStorage } from '@utils/ClientStorage';

export const featureFlags = [
  { id: 'intlInspector', title: 'Enable intl inspector' },
  { id: 'deskIntlKo', title: 'Desk Language: Korean' },
  { id: 'mockWoowahan', title: '[Desk] Mock Woowahan Organization' },
  { id: 'allowedChannelCustomTypes', title: 'Use allowed channel custom types configured in Insight' },
  { id: 'rtl', title: 'Allow RTL' },
  { id: 'spotv', title: 'SPOTV' },
  { id: 'allowAudioRoomInStudio', title: 'Allow to create and join audio room in Calls studio' },
  { id: 'allowAccessModeration', title: 'Allow to access moderation pages' },
  { id: 'allowedGroupChannelSearchMember', title: 'Enable member search in group channels' },
  { id: 'categoriesReorder', title: 'Category filters reorder' },
  { id: 'messageDeletion', title: 'Message deletion' },
  { id: 'smsIntegration', title: 'SMS Integration' },
  { id: 'moderationV2', title: 'Moderation v2' },
  { id: 'coupang', title: 'Coupang' },
  { id: 'knowledgeManagement', title: 'AI Knowledge Management Centre' },
  { id: 'tving', title: 'Tving' },
  { id: 'rakuten', title: 'Rakuten' },
] as const;

export type FeatureFlagKey = (typeof featureFlags)[number]['id'];

const toggleFeatureFlag = (key: FeatureFlagKey, isEnabled: boolean) => {
  if (isEnabled) {
    LocalStorage.upsertObject('featureFlags', { [key]: true });
    return;
  }
  LocalStorage.removeFromObject('featureFlags', [key]);
};

const isFeatureFlagEnabled = (key: FeatureFlagKey) => {
  return LocalStorage.getObject('featureFlags')?.[key] === true;
};

export const FeatureFlagStore = { toggle: toggleFeatureFlag, get: isFeatureFlagEnabled };
