import { createGlobalStyle, css, keyframes } from 'styled-components';

import { transitionDefault } from '@feather';

import ie9UnsupportedStyles from './ie9UnsupportedStyles';
import './preflight.css';
import reactDomContainerStyles from './reactDomContainerStyles';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
  ${theme}
  ${reactDomContainerStyles}
  ${ie9UnsupportedStyles}
`;

const clearfix = () => css`
  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

const animationBounceDelay = () => keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const animationScaleY = () => keyframes`
  0% {
    transform: scaleY(0);
    opacity: 0;
  } 100% {
    transform: scaleY(1.0);
    opacity: 1;
  }
`;

const animationLoading = () => keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export {
  GlobalStyles,
  clearfix,
  // animations
  transitionDefault,
  animationBounceDelay,
  animationScaleY,
  animationLoading,
};
