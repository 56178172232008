import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { fetchCardInfo } from '@common/api/billing';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

export const BillingActions = {
  fetchCardInfo: createAsyncThunk<CreditCardInfo | {} | null, { organization_uid: string }>(
    'common/billing/fetchCardInfo',
    async (payload, { rejectWithValue }) => {
      try {
        const { data } = await fetchCardInfo({
          organization_uid: payload.organization_uid,
        });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
};
