import type { RootState } from '@/store';

import { useSelector } from '.';

type LocalState = RootState['imagePreview'];
type LocalSelector<T> = (state: LocalState) => T;

export function useImagePreview(): LocalState;
export function useImagePreview<T>(selector: LocalSelector<T>): T;

export function useImagePreview<T>(selector?: LocalSelector<T>, equalityFn?: (left: T, right: T) => boolean) {
  return useSelector<T | LocalState>(
    (state) => (selector ? selector(state.imagePreview) : (state.imagePreview as LocalState)),
    equalityFn,
  );
}
