import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';

/**
 * Actions below are re-defined here to avoid circular dependency issue.
 */
/**
 * @see ApplicationActions.applicationCreated
 */
const applicationCreated = createAction<Application>('core/application/applicationCreated');
/**
 * @see ApplicationActions.changeAppName.fulfilled
 */
const applicationAppNameChanged = createAction<Pick<Application, 'app_id' | 'app_name'>>(
  'core/application/changeAppName/fulfilled',
);
/**
 * @see SettingsActions.applicationDeleted
 */
const applicationDeleted = createAction<Pick<Application, 'app_id'>>('core/settings/applicationDeleted');

type ApplicationUpdateState =
  | ReturnType<typeof applicationCreated>
  | ReturnType<typeof applicationAppNameChanged>
  | ReturnType<typeof applicationDeleted>
  | null;

const initialState: ApplicationUpdateState = null;

export const applicationUpdateReducer = createReducer<ApplicationUpdateState>(initialState, (builder) => {
  builder.addMatcher(
    isAnyOf(applicationCreated, applicationAppNameChanged, applicationDeleted),
    (_, action) => action as ApplicationUpdateState,
  );
});
