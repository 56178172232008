import type { FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import type { MakeGrid } from './types';

export const ContentContainer = styled.div`
  min-width: 1088px;
  padding: 0 32px;
`;

export const PageContainer = styled(ContentContainer)`
  // https://github.com/styled-components/styled-components/issues/1816
  && {
    padding-top: 24px;
  }
`;

export const TablePageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > *:not(:first-child) {
    margin-top: 16px;
  }

  > *:last-child {
    flex: 1;
    min-height: 0;
  }
`;

export const CenteredPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;

export const makeGrid: MakeGrid = (options) => {
  const {
    wideWidth = 1224,
    wideGutterSize = 24,
    narrowMaxWidth = 984,
    narrowGutterSize = 20,
    columns = 12,
  } = options || {};

  const maxWidth = narrowMaxWidth + narrowGutterSize * 2;
  const width = wideWidth + wideGutterSize * 2;

  const wideGridMediaQuery = (first, ...interpolations) =>
    css`
      @media (min-width: ${width}px) {
        ${css(first, ...interpolations)}
      }
    ` as FlattenSimpleInterpolation;

  const ResponsiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    position: relative;
    width: 100%;
    min-width: 1024px;
    max-width: ${maxWidth}px;
    padding: 0 ${narrowGutterSize}px;

    ${wideGridMediaQuery`
    max-width: ${width}px;
    width: 100%;
    padding: 0 ${wideGutterSize}px;
    `}
  `;

  const ResponsiveColumn = styled.div<{
    column: number;
    includeOuterGutters?: boolean;
    styles?: SimpleInterpolation;
  }>`
    ${({ column, includeOuterGutters = false, styles }) => css`
      flex: none;
      width: calc(
        (100% + ${narrowGutterSize}px) / ${columns} * ${column} ${includeOuterGutters ? '' : ` - ${narrowGutterSize}px`}
      );

      ${wideGridMediaQuery`
      width: calc((100% + ${wideGutterSize}px) / ${columns} * ${column}${
        includeOuterGutters ? '' : ` - ${wideGutterSize}px`
      });
      `}

      && {
        ${styles}
      }
    `}
  `;

  return {
    ResponsiveContainer,
    wideGridMediaQuery,
    ResponsiveColumn,
  };
};
