import { useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from 'react-query';

import { fetchCurrentSubscription } from '@common/api/billing';
import type { SubscriptionProduct } from '@constants';

import useAuthorization from './useAuthorization';

const useCurrentSubscription = (product: SubscriptionProduct) => {
  const { isSelfService } = useAuthorization();
  const queryClient = useQueryClient();
  const key = useMemo(() => {
    return ['currentSubscription', { product }];
  }, [product]);
  const { isError, isLoading, status, data, refetch } = useQuery({
    queryKey: key,
    queryFn: () => fetchCurrentSubscription(product),
    enabled: isSelfService,
  });
  /**
   * if there is no current subscription, then the response is an empty string "",
   * which can be normalized to null using || operator.
   */

  const update = useCallback(
    (data: Subscription) => {
      queryClient.setQueryData(key, { ...queryClient.getQueryData<Subscription>(key), data });
    },
    [key, queryClient],
  );

  return {
    isError,
    isLoading,
    isLoaded: status === 'success' || status === 'error',
    currentSubscription: data?.data || null,
    update,
    refetch,
  };
};

export default useCurrentSubscription;
