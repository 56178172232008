import type { ReactNode } from 'react';

import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { DialogsActions } from '@common/redux/actions/dialogs';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import { updateTicketAssignment } from '@desk/api/conversation';
import {
  addToWIP,
  assignTicketToAgentGroup,
  closeTicket,
  forceAssignTicket,
  reopenTicket,
  transferTicket,
} from '@desk/api/tickets';
import { toast } from '@feather/components/notification';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { selectDeskProject } from '../selectors/desk';
import { TicketDetailActions } from './ticketDetail';

export const TicketsActions = {
  forceAssignTicket: createAsyncThunk<
    Ticket,
    { payload: ForceAssignTicketPayload; options: { origin: DeskOrigin; alert?: Exclude<ReactNode, undefined> } }
  >('desk/tickets/forceAssignTicket', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await forceAssignTicket(pid, region, {
        ticketId: payload.payload.ticketId,
        agentId: payload.payload.agentId,
      });

      dispatch(DialogsActions.hideDialog());
      if (payload.options?.origin === 'tickets' && payload.options.alert) {
        toast.success({ message: payload.options.alert });
      } else {
        dispatch(TicketDetailActions.fetchTicketDetailTicket(payload.payload.ticketId));
      }
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  transferTicket: createAsyncThunk<
    Assignment,
    TransferTicketPayload & { ticket: Ticket; options: { origin: DeskOrigin; alert?: Exclude<ReactNode, undefined> } }
  >('desk/tickets/transferTicket', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      dispatch(DialogsActions.setIsFetching(true));
      const { data } = await transferTicket(pid, region, { payload: payload.payload });

      dispatch(DialogsActions.hideDialog());
      if (payload.options?.origin === 'tickets' && payload.options.alert) {
        toast.success({ message: payload.options.alert });
      }
      if (payload.options?.origin !== 'conversation') {
        dispatch(TicketDetailActions.fetchTicketDetailTicket(payload.ticket.id));
      }
      return data.toAssignment;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    } finally {
      dispatch(DialogsActions.setIsFetching(false));
    }
  }),

  reopenTicket: createAsyncThunk<
    Ticket,
    { payload: ReopenTicketPayload; options: { alert?: Exclude<ReactNode, undefined> } }
  >('desk/tickets/reopenTicket', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      dispatch(DialogsActions.setIsFetching(true));
      const { data } = await reopenTicket(pid, region, { ticketId: payload.payload.ticketId });

      dispatch(DialogsActions.hideDialog());
      if (payload.options.alert) {
        toast.success({ message: payload.options.alert });
      }
      dispatch(TicketDetailActions.fetchTicketDetailTicket(payload.payload.ticketId));
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    } finally {
      dispatch(DialogsActions.setIsFetching(false));
    }
  }),

  moveTicketToWIP: createAsyncThunk<void, AddToWIPPayload>(
    'desk/tickets/moveTicketToWIP',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await addToWIP(pid, region, payload);

        dispatch(TicketDetailActions.ticketDetailUpdated(data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  moveTicketToIdle: createAsyncThunk<Assignment, { ticket: Ticket; origin: DeskOrigin }>(
    'desk/tickets/moveTicketToIdle',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      if (!payload.ticket.recentAssignment) {
        return rejectWithValue(`Undefined RecentAssignment: ${payload.ticket.channelName}`);
      }

      try {
        const { data } = await updateTicketAssignment(pid, region, {
          assignmentId: payload.ticket.recentAssignment.id,
          payload: { status: 'IDLE' },
        });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  closeTicket: createAsyncThunk<
    Ticket,
    { payload: CloseTicketPayload; options: { origin: DeskOrigin; alert?: Exclude<ReactNode, undefined> } }
  >('desk/tickets/closeTicket', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await closeTicket(pid, region, payload.payload);
      dispatch(DialogsActions.hideDialog());
      if (payload.options?.origin === 'tickets' && payload.options.alert) {
        toast.success({ message: payload.options.alert });
      }
      if (payload.options?.origin !== 'conversation') {
        dispatch(TicketDetailActions.fetchTicketDetailTicket(payload.payload.ticketId));
      }
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  assignTicketToAgentGroup: createAsyncThunk<
    Ticket,
    { payload: AssignTicketToGroupPayload; options: { origin: DeskOrigin; alert?: Exclude<ReactNode, undefined> } }
  >('desk/tickets/assignTicketToAgentGroup', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      dispatch(DialogsActions.setIsFetching(true));
      const { data } = await assignTicketToAgentGroup(pid, region, payload.payload);

      dispatch(DialogsActions.hideDialog());
      if (payload.options.alert) {
        toast.success({ message: payload.options.alert });
      }
      if (payload.options.origin !== 'conversation') {
        dispatch(TicketDetailActions.fetchTicketDetailTicket(payload.payload.ticketId));
      }
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    } finally {
      dispatch(DialogsActions.setIsFetching(false));
    }
  }),

  ticketsRefreshAutomaticSet: createAction<boolean>('desk/tickets/ticketsRefreshAutomaticSet'),
  ticketsRefreshAutomaticItemSet: createAction<{ label: string; seconds: number }>(
    'desk/tickets/ticketsRefreshAutomaticItemSet',
  ),
};
