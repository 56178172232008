import { useMemo } from 'react';

import useIsDeskLocale, { DeskLocale } from '..';
import useAuthorization from '../../useAuthorization';
import useFeatureFlags from '../../useFeatureFlags';

export const useIsDeskLocaleKoKR = () => {
  const { isPermitted } = useAuthorization();
  const isDeskLocaleKoKR = useIsDeskLocale(DeskLocale.ko_KR);
  const {
    flags: { deskIntlKo },
  } = useFeatureFlags();

  return useMemo(
    () => isPermitted(['desk.agent', 'desk.admin']) && (deskIntlKo || isDeskLocaleKoKR),
    [deskIntlKo, isDeskLocaleKoKR, isPermitted],
  );
};
