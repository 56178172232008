import { axios, getGateURL } from '@api/shared';

import type {
  CustomItems,
  DeletedRoom,
  OpenRoom,
  Participant,
  Room,
  RoomType,
  UserSummary,
  QueryParamsOnSever,
  GetDirectCallDataExportsAPI,
  PostDirectCallDataExportsAPI,
} from './types';

export const getDirectCalls: GetDirectCallsAPI = (appId, params) => {
  return axios.get(`${getGateURL()}/dashboard_api/v2oip/direct_calls`, {
    params,
    headers: {
      'App-Id': appId,
    },
  });
};

export const getDirectCall: GetDirectCallAPI = (appId, { call_id }) => {
  return axios.get(`${getGateURL()}/dashboard_api/v2oip/direct_calls/${call_id}`, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const getDirectCallDataExports: GetDirectCallDataExportsAPI = (appId, params) => {
  return axios.get(`${getGateURL()}/dashboard_api/v2oip/direct_calls/export-call-history`, {
    params,
    headers: {
      'App-Id': appId,
    },
  });
};

export const createDirectCallDataExports: PostDirectCallDataExportsAPI = (appId, payload) => {
  return axios.post(`${getGateURL()}/dashboard_api/v2oip/direct_calls/export-call-history`, payload, {
    headers: {
      'App-Id': appId,
    },
  });
};

export const fetchCallStats = (appId: string, call_id: string) => {
  return axios.get<CallStat[]>(`${getGateURL()}/dashboard_api/v2oip/direct_calls/${call_id}/stats`, {
    headers: { 'App-Id': appId },
  });
};

export const fetchUserSummary = (appId: string, userId: string) => {
  return axios.get<UserSummary>(
    `${getGateURL()}/dashboard_api/v2oip/users/${encodeURIComponent(userId)}/call_summary`,
    { headers: { 'App-Id': appId } },
  );
};

export type FetchRoomsResponse = {
  rooms: Room[];
  next: string | null | undefined;
  prev: string | null | undefined;
};

export const fetchRooms = (appId: string, params?: QueryParamsOnSever) => {
  return axios.get<FetchRoomsResponse>(`${getGateURL()}/dashboard_api/v2oip/rooms`, {
    params,
    headers: { 'App-Id': appId },
  });
};

export const fetchRoom = (
  appId: string,
  roomId: string,
  params?: Omit<QueryParamsOnSever, 'next' | 'prev' | 'room_ids'>,
) => {
  return axios.get<{ room: Room }>(`${getGateURL()}/dashboard_api/v2oip/rooms/${roomId}`, {
    params,
    headers: { 'App-Id': appId },
  });
};

export const createRoom = (appId: string, payload: { type: RoomType; custom_items?: CustomItems }) => {
  return axios.post<{ room: OpenRoom }>(`${getGateURL()}/dashboard_api/v2oip/rooms`, payload, {
    headers: { 'App-Id': appId },
  });
};

export const deleteRoom = (appId: string, roomId: string) => {
  return axios.delete<{ room: DeletedRoom }>(`${getGateURL()}/dashboard_api/v2oip/rooms/${roomId}`, {
    headers: { 'App-Id': appId },
  });
};

export const editCustomItems = (
  appId: string,
  roomId: string,
  payload: { custom_items: CustomItems; mode?: 'upsert' | 'insert' },
) => {
  return axios.put<{ custom_items: CustomItems }>(
    `${getGateURL()}/dashboard_api/v2oip/rooms/${roomId}/custom_items`,
    payload,
    { headers: { 'App-Id': appId } },
  );
};

export const deleteCustomItems = (appId: string, roomId: string, payload: { keys?: string[] }) => {
  return axios.delete<{ custom_items: CustomItems }>(
    `${getGateURL()}/dashboard_api/v2oip/rooms/${roomId}/custom_items`,
    {
      headers: { 'App-Id': appId },
      data: payload,
    },
  );
};

export const fetchParticipants = (
  appId: string,
  roomId: string,
  params?: { next?: string; prev?: string; limit?: number },
) => {
  return axios.get<{ participants: Participant[]; next: string | null; prev: string | null }>(
    `${getGateURL()}/dashboard_api/v2oip/rooms/${roomId}/participants`,
    { params, headers: { 'App-Id': appId } },
  );
};

export type WebhookSetting = {
  enabled_events: WebhookEvent[];
  url: string;
  enabled: boolean;
};

export const fetchWebhookSetting = (appId: string, params?: { display_all_webhook_categories?: boolean }) => {
  return axios.get<{ webhook: WebhookSetting & { all_webhook_categories: WebhookEvent[] } }>(
    `${getGateURL()}/dashboard_api/v2oip/applications/${appId}/settings/webhook`,
    { headers: { 'App-Id': appId }, params },
  );
};

export const updateWebhookSetting = (appId: string, payload: Partial<WebhookSetting>) => {
  return axios.put<{ webhook: WebhookSetting }>(
    `${getGateURL()}/dashboard_api/v2oip/applications/${appId}/settings/webhook`,
    payload,
    { headers: { 'App-Id': appId } },
  );
};

type CallsSettings = {
  general: {
    relay_method: RelayMethod;
    direct_call_cloud_recording_enabled: boolean;
    group_call_cloud_recording_enabled: boolean;
  };
};

export const fetchCallsSettings = ({ appId }: { appId: string }) => {
  return axios.get<CallsSettings>(`${getGateURL()}/dashboard_api/v2oip/applications/${appId}/settings/general`, {
    headers: { 'App-Id': appId },
  });
};

export type UpdateCallsSettingsPayload = {
  relay_method?: RelayMethod;
  group_call_cloud_recording_enabled?: boolean;
  direct_call_cloud_recording_enabled?: boolean;
  direct_call_recording_video?: 'dual' | 'callee';
};

export const updateCallsSettings = (appId: string, payload: UpdateCallsSettingsPayload) => {
  return axios.put<{ general: { relay_method: RelayMethod } }>(
    `${getGateURL()}/dashboard_api/v2oip/applications/${appId}/settings/general`,
    payload,
    { headers: { 'App-Id': appId } },
  );
};

export const createFreeVoucher = (appId: string) => {
  return axios.post<Voucher>(
    `${getGateURL()}/dashboard_api/billing/vouchers/start_free_trial`,
    {},
    { headers: { 'App-Id': appId } },
  );
};

export type ChatIntegrationEvent = 'direct_call:dial' | 'direct_call:end';

export type ChatIntegrationSetting = {
  enabled: boolean;
  enabled_events: ChatIntegrationEvent[];
  all_chat_integration_categories: ChatIntegrationEvent[];
};

export const fetchChatIntegration = (
  appId: string,
  params: { display_all_chat_integration_categories: boolean } = { display_all_chat_integration_categories: true },
) => {
  return axios.get<{ chat_integration: ChatIntegrationSetting }>(
    `${getGateURL()}/dashboard_api/v2oip/applications/${appId}/settings/chat_integration`,
    { params, headers: { 'App-Id': appId } },
  );
};

export const updateChatIntegration = (
  appId: string,
  payload: { enabled: boolean; enabled_events: ChatIntegrationEvent[] },
) => {
  return axios.put<{ chat_integration: ChatIntegrationSetting }>(
    `${getGateURL()}/dashboard_api/v2oip/applications/${appId}/settings/chat_integration`,
    payload,
    { headers: { 'App-Id': appId } },
  );
};
