import { axios as cancellableAxios, getDeskURL } from '@api/shared';

import DeskApiAxios from './DeskApiAxios';

export const facebookLoadPages: FacebookLoadPagesAPI = (pid, region = '', { accessToken }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('facebook_pages/load_pages/', { short_lived_user_access_token: accessToken });
};

export const facebookSubscribe: FacebookSubscribeAPI = (pid, region = '', { page_id }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post(`facebook_pages/${page_id}/subscribe`, {});
};

export const facebookUnsubscribe: FacebookUnsubscribeAPI = (pid, region = '', { page_id }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.delete(`facebook_pages/${page_id}/unsubscribe`);
};

export const facebookActivePages: GetFacebookActivePagesAPI = (pid, region = '') => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('projects/facebook_pages?limit=100');
};

export const facebookUpdatePageSettings: UpdateFacebookPageSettingsAPI = (pid, region = '', { pageId, payload }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`facebook_pages/${pageId}`, payload);
};

export const getProjectTwitterUsers: GetProjectTwitterUsersAPI = (pid, region = '') => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('projects/twitter_users');
};

export const getTwitterOauthToken: GetTwitterOauthTokenAPI = (pid, region = '') => {
  return cancellableAxios.get(`${getDeskURL(region)}/twitter/oauth/request_token/`, {
    headers: { pid },
  });
};

export const subscribeTwitter: SubscribeTwitterAPI = (pid, region = '', payload) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('twitter_users', payload);
};

export const patchTwitterUser: PatchTwitterUserAPI = (pid, region = '', { id, ...updates }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`twitter_users/${id}/`, updates);
};

export const getProjectInstagramAccounts: GetProjectInstagramAccountAPI = (pid, region = '') => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('projects/instagram_users');
};

export const instagramLoadAccounts: InstagramLoadAccountsAPI = (pid, region = '', { accessToken }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('instagram_users/load_accounts/', { shortLivedUserAccessToken: accessToken });
};

export const fetchInstagramUser: FetchInstagramUserAPI = (pid, region, { id }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`instagram_users/${id}/`);
};

export const patchInstagramUser: PatchInstagramUserAPI = (
  pid,
  region,
  { instagramUserId, isCommentEnabled, isMessageEnabled, status },
) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`instagram_users/${instagramUserId}/`, { isCommentEnabled, isMessageEnabled, status });
};

export const createIframeApp: CreateIframeAppAPI = (pid, region, { title, url, width }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('apps_iframes/', { title, url, width });
};

export const fetchIframeAppDetail: FetchIframeAppAPI = (pid, region, { id }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`apps_iframes/${id}`);
};

export const updateIframeApp: UpdateIframeAppAPI = (pid, region, { id, ...params }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`apps_iframes/${id}`, params);
};

export const createNexmoAccount: CreateNexmoAccountAPI = (pid, region, params) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('nexmo_accounts', params);
};

export const updateNexmoAccount: UpdateNexmoAccountAPI = (pid, region, { id, ...params }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`nexmo_accounts/${id}`, params);
};

export const verifyNexmoApp: VerifyNexmoAppAPI = (pid, region, params) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('nexmo_accounts/verify', params);
};

export const getProjectNexmoAccounts: GetProjectNexmoAccountsAPI = (pid, region) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('projects/subscribed_nexmo_accounts');
};

export const getNexmoAccountDetail: GetNexmoAccountDetailAPI = (pid, region, { id }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`nexmo_accounts/${id}`);
};
