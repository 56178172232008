import { useMemo } from 'react';

import useOrganization from '../useOrganization';

export enum DeskLocale {
  DEFAULT = 'en_US',
  /** @knipignore */
  en_US = 'en_US',
  ko_KR = 'ko_KR',
}

const DEFAULT_LOCALE = DeskLocale.DEFAULT;

/**
 * Retrieve language_COUNTRY string from Organization attributes
 * @param {string} [locale=en_US] - language_COUNTRY value (for example: en_US, ko_KR, en, ko)
 * @returns {boolean} whether Organization attributes has deskSettingsLocaleSet value, which equals to or starts with the value of locale
 */
const useIsDeskLocale = (locale: DeskLocale = DEFAULT_LOCALE) => {
  const { attributes } = useOrganization() || {};

  return useMemo(() => {
    const organizationLocaleName = attributes?.deskSettingsLocaleSet || DEFAULT_LOCALE;
    return organizationLocaleName.startsWith(locale);
  }, [attributes?.deskSettingsLocaleSet, locale]);
};

export default useIsDeskLocale;
