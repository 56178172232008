import type { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import defaultsDeep from 'lodash/defaultsDeep';

import { CancellableAxios, HttpMethod } from '@api/cancellableAxios';
import { getDeskURL } from '@api/shared';

export default class DeskApiAxios extends CancellableAxios {
  private baseURL: string;
  private defaultHeaders: AxiosRequestHeaders;

  constructor({ pid, region }: { pid: string; region: string }) {
    super();

    this.baseURL = `${getDeskURL(region)}/api`;
    this.defaultHeaders = {
      ...(pid && { pid }),
    };
  }

  private mergeConfigWithDefault = (config: AxiosRequestConfig): AxiosRequestConfig => {
    return defaultsDeep(config, {
      // FIXME(DFE-656): baseURL can't be used in test suites
      // baseURL: this.baseURL,
      headers: this.defaultHeaders,
      withCredentials: true,
    });
  };

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public get = <T = any>(url: string, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({
      method: HttpMethod.Get,
      url: `${this.baseURL}/${url}`,
      config: this.mergeConfigWithDefault(config),
    });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public delete = <T = any>(url: string, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({
      method: HttpMethod.Delete,
      url: `${this.baseURL}/${url}`,
      config: this.mergeConfigWithDefault(config),
    });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public head = <T = any>(url: string, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({
      method: HttpMethod.Head,
      url: `${this.baseURL}/${url}`,
      config: this.mergeConfigWithDefault(config),
    });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public post = <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({
      method: HttpMethod.Post,
      url: `${this.baseURL}/${url}`,
      data,
      config: this.mergeConfigWithDefault(config),
    });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public put = <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({
      method: HttpMethod.Put,
      url: `${this.baseURL}/${url}`,
      data,
      config: this.mergeConfigWithDefault(config),
    });

  /**
   * Don't forget to add trailing slash('/') our API server will expect
   * trailing slash(especially DRF related servers), Chrome automatically fix this but
   * Safari doesn't so please always add slash after URL.
   */
  public patch = <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.makeRequest<T>({
      method: HttpMethod.Patch,
      url: `${this.baseURL}/${url}`,
      data,
      config: this.mergeConfigWithDefault(config),
    });
}
