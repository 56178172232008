import type { RootState } from '@/store';

import { useShallowEqualSelector } from '.';

type LocalState = RootState['applicationState'];
type LocalSelector<T> = (state: LocalState) => T;

export function useApplicationState(): LocalState;
export function useApplicationState<T>(selector: LocalSelector<T>): T;

export function useApplicationState<T>(selector?: LocalSelector<T>) {
  return useShallowEqualSelector<T | LocalState>((state) =>
    selector ? selector(state.applicationState) : (state.applicationState as LocalState),
  );
}
