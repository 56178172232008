import type { TypedUseSelectorHook } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { useSelector as useReduxSelector, shallowEqual, useDispatch as useReduxDispatch } from 'react-redux';

import type { AppDispatch, RootState } from '@/store';

export const useDispatch: () => AppDispatch = useReduxDispatch;

/**
 * @deprecated It's not recommended to use this hook directly. Use instead derived hooks in `app/hooks/redux` folder.
 */
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

/**
 * @deprecated It's not recommended to use this hook directly. Use instead derived hooks in `app/hooks/redux` folder.
 */
export const useShallowEqualSelector = <T>(selector: (state: RootState) => T) => useSelector<T>(selector, shallowEqual);
