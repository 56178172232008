/*
 *
 *  Alert Messages
 *
 */

// auth
export const ALERT_EMAIL_INVALID = 'Your email address is invalid. Please enter a valid address.';

// application
export const ALERT_APPLICATION_NAME_CHANGED = 'Application name has been changed.';

// billing
export const ALERT_CARD_AGGREMENT_REQUIRED = 'You have to agree with the terms and privacy policy.';

// authorization
export const ALERT_NOT_ALLOWED_FEATURE =
  'The feature is not available on your current plan. Please consult the sales team through the website or dashboard.';

// channel
export const ALERT_CHANNEL_CREATED = 'Channel has been created.';
export const ALERT_CHANNEL_DELETED = 'Channel has been deleted.';
export const ALERT_CHANNEL_IS_REQUIRED = 'Please select at least one channel.';
export const ALERT_ADMIN_MESSAGE_SENT = 'Admin message has been sent successfully.';
export const ALERT_ADMIN_MESSAGE_LENGTH = 'Admin messages should not be longer than 1000 characters.';

// analytics
export const ALERT_ANALYTICS_DATE_RANGE_92 = 'Daily date selection can not exceed 92 days.';

// reports
export const ALERT_REPORTS_DATE_RANGE_31 = 'Daily date selection can not exceed 31 days.';

// settings
export const ALERT_SETTINGS_PUSH_ENABLED = 'Push notifications turned on.';
export const ALERT_SETTINGS_PUSH_DISABLED = 'Push notifications turned off.';
export const ALERT_SETTINGS_ACCESS_TOKEN_POLICY = 'Access token user policy has been changed.';
export const ALERT_SETTINGS_WEBHOOKS = 'Webhooks configuration has been changed.';
export const ALERT_SETTINGS_DISPLAY_PAST_MESSAGE_ON = 'Chat history turned on';
export const ALERT_SETTINGS_DISPLAY_PAST_MESSAGE_OFF = 'Chat history turned off';
export const ALERT_SETTINGS_AUTO_EVENT_MESSAGE = 'Auto message settings changed.';
export const ALERT_SETTINGS_CREDENTIAL_FILTER_ADDED = 'Allowed domain added.';
export const ALERT_SETTINGS_CREDENTIAL_FILTER_DELETED = 'Allowed domain removed.';
export const ALERT_SETTINGS_MAX_LENGTH_OF_MESSAGE = 'Max length has been changed.';
export const ALERT_SETTINGS_APPLICATION_DELETED = 'Application deleted.';
export const ALERT_SETTINGS_PUSH_NOTIFICATION_TEMPLATES = 'Push notification message template has been saved.';
export const ALERT_SETTINGS_APNS_CERT_ERROR = 'Failed to upload the cert file. Please check the file format again.';
// general
export const ALERT_COPY_SUCCESS = 'Copied.';
export const ALERT_BAD_REQUEST = 'Bad request';

// dialog
export const ALERT_DIALOG_ADMIN_MESSAGE_LIMITED = 'You need to upgrade your plan to use admin message.';

/*
 *
 *  Descriptions
 *
 */

export const DESC_DIALOG_ADMIN_MESSAGE =
  'Admin Message allows you to send administrative messages (such as announcements) to multiple channels at a time. Please select target channels and type your message.';
export const DESC_DIALOG_ADMIN_MESSAGE_LIMITED = 'You can only send a test message on Free Plan.';
export const DESC_DIALOG_ADMIN_MESSAGE_LENGTH = 'Admin message should not be longer than 1000 characters';
export const DESC_DIALOG_DELETE_CHANNELS = 'Do you want to delete these channels?';

/*
 *
 *  Placeholder
 *
 */
export const PH_SEARCH_CHANNELS = 'Search';
export const PH_DIALOG_ADMIN_MESSAGE_LIMITED = 'TEST MESSAGE : You can not modify this message on Free Plan';

// Converts a `snake_cased` string to a `Title case` string.
export const snakeCaseToTitleCase = (snakeCaseString: string) => {
  const words = snakeCaseString.split('_');
  const capitalizedFirstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  const remainingWords = words.slice(1);
  const titleCaseString = [capitalizedFirstWord, ...remainingWords].join(' ');
  return titleCaseString;
};
