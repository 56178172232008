import qs from 'qs';

import config from '@/config';
import { isStaging } from '@utils';
import { snakeCaseKeys } from '@utils/ObjectKeyConverter';

import { cancellableAxios } from './cancellableAxios';

let gateRegionSubdomain: string = '';
export const setGateRegionSubdomain = (region: string) => {
  gateRegionSubdomain = region;
};

export const getDeskIframeURL: GetDeskIframeURL = ({ id, region, params }) => {
  const path = `/api/apps_iframes/${id}/get_html?${qs.stringify(snakeCaseKeys(params))}`;
  if (isStaging()) {
    return `https://staging.desk-staging.sendbirdusercontent.com${path}`;
  }

  switch (config.BUILD_MODE) {
    case 'production':
      return `https://${region}.desk.sendbirdusercontent.com${path}`;
    default:
      return 'http://localhost:8000';
  }
};

export const getDeskURL = (region: string) => {
  if (isStaging()) {
    return 'https://desk-api-staging.sendbird.com';
  }

  switch (config.BUILD_MODE) {
    case 'production':
      return `https://desk-api-${region}.sendbird.com`;
    default:
      return 'http://localhost:8000';
  }
};

export const getDashboardURL = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const getDashboardAPIUrl = () => config.DASHBOARD_API_HOST;

/**
 * Returns Gate `protocol://hostname:port` based on import.meta.env.BUILD_MODE
 *
 * Gate endpoint URLs **end with a trailing slash** except when they are prepended with `/platform/v3` or `/dashboard_api/v2oip`
 * so that they are consistent with https://sendbird.com/docs
 *
 * e.g.
 *
 * ```
 * ${getGateURL()}/dashboard_api/billing/vouchers/start_free_trial/ (O)
 * ${getGateURL()}/dashboard_api/billing/vouchers/start_free_trial (X)
 * ```
 *
 * Exception:
 * ```
 * ${getGateURL()}/platform/v3/users/my_user_id (O)
 * ${getGateURL()}/platform/v3/users/my_user_id/ (X)
 *
 * ${getGateURL()}/dashboard_api/v2oip/direct_calls (O)
 * ${getGateURL()}/dashboard_api/v2oip/direct_calls/ (X)
 * ```
 */
export const getGateURL = () => config.GATE_URL;
export const getGateURLWithRegion = () => {
  let subdomain = gateRegionSubdomain;

  if (gateRegionSubdomain === 'staging') {
    subdomain = 'preprod';
  } else if (gateRegionSubdomain === 'ap-1') {
    subdomain = subdomain.replace('-', '');
  } else if (!['ap-1', 'paypay'].includes(gateRegionSubdomain)) {
    subdomain = '';
  }
  return config.GATE_URL_REGION.replace(/\{region\}\.?/, subdomain ? `${subdomain}.` : '');
};

export const axios = cancellableAxios;
