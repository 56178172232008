import { css } from 'styled-components';

import { cssVariables } from '@feather';

const ie9UnsupportedStyles = css`
  .unsupported {
    text-align: center;
    padding: 120px 0 0 0;
    &__logo {
      img {
        width: 250px;
      }
    }
    &__header {
      font-size: 32px;
      font-weight: 300;
      padding: 40px 0;
      color: ${cssVariables('purple-7')};
    }
    &__description {
      color: ${cssVariables('neutral-6')};
      font-size: 20px;
      strong {
        color: #f45477;
      }
    }
    &__footer {
      padding: 0;
      margin-top: 60px;
      li {
        font-size: 16px;
        font-weight: 300;
        color: ${cssVariables('neutral-3')};
        display: inline-block;
      }
      &__divider {
        margin: 0 10px;
      }
      &__link {
        a {
          color: #343840;
          &:hover {
            color: #343840;
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export default ie9UnsupportedStyles;
