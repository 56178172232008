import type { AriaAttributes, FC, ReactNode } from 'react';
import { useCallback } from 'react';

import FocusTrap from 'focus-trap-react';
import { useIntl } from 'react-intl';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { Body, cssVariables, elevation, Headings } from '@feather';
import { IconButton } from '@feather/components/button';
import * as Icons from '@feather/components/icons';
import { useTranslationOfLocale } from '@hooks/useTranslation';
import { getFocusTrapOptions } from '@utils/focusTrapOptions';

import type messages from './translation/en.json';

type Size = 'small' | 'large' | 'xlarge' | '2xlarge' | number;

const getDialogWidth = (size: Size) => {
  if (typeof size === 'number' && size > 0) {
    return size;
  }

  switch (size) {
    case 'large':
      return 640;
    case 'xlarge':
      return 760;
    case '2xlarge':
      return 800;
    case 'small':
    default:
      return 480;
  }
};

interface DialogProps {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  body?: ReactNode;
  size?: Size;
  isFullBody?: boolean;
  titleButtonComponent?: ReactNode;
  styles?: SimpleInterpolation;
  onClose?: () => any;
  className?: string;
  dialogClassName?: string;
  hideCloseIconButton?: boolean;
  extra?: ReactNode;

  /**
   * With this option you can specify a different element to receive that initial focus. Can be a selector string
   * (which will be passed to document.querySelector() to find the DOM node), or a function that returns a DOM node.
   */
  initialFocus?: string | (() => HTMLElement | false);
  'aria-busy'?: AriaAttributes['aria-busy'];
  shouldCloseOnOverlayClick?: boolean;
}

interface LocalizedDialogProps extends DialogProps {
  locale: string;
}

const DialogContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  user-select: text;
  overflow-y: auto;
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin: 0 auto;
  padding: 30px 0;
`;

const StyledDialog = styled.div<{
  size: 'small' | 'large' | 'xlarge' | '2xlarge' | number;
  styles: SimpleInterpolation;
}>`
  user-select: initial;
  display: flex;
  position: relative;
  flex-direction: column;
  outline: 0;
  border-radius: 5px;
  background: white;
  width: ${({ size }) => `${getDialogWidth(size)}px`};
  ${elevation.modal};

  ${(props) => props.styles};
`;

const DialogHeader = styled.div`
  padding: 16px 24px;
  padding-right: 56px;
  min-height: 64px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
`;

const DialogTitle = styled.h1`
  ${Headings['heading-04']}
  color: ${cssVariables('neutral-10')};
  word-break: break-word;
  white-space: pre-wrap;
  margin-top: 2px;
  padding-right: 32px;
`;

const DialogDescription = styled.div`
  ${Body['body-short-01']}
  color: ${cssVariables('neutral-10')};
  margin-top: 18px;
`;

const DialogHeaderInterfaceSection = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const DialogClose = styled(IconButton)`
  cursor: pointer;
`;

export const DialogBody = styled.div<{ isFullBody?: boolean }>`
  padding: ${(props) => (props.isFullBody ? '0' : '0 24px 24px')};
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
`;

export const LocalizedDialog: FC<LocalizedDialogProps> = ({
  title,
  description,
  titleButtonComponent,
  body,
  isFullBody,
  children,
  className,
  dialogClassName,
  onClose = () => {},
  size = 'small',
  styles = css``,
  extra,
  hideCloseIconButton = false,
  initialFocus = '[role="dialog"]',
  locale,
  'aria-busy': ariaBusy = false,
  shouldCloseOnOverlayClick = false,
}) => {
  const { t, isLoading } = useTranslationOfLocale<keyof typeof messages>({
    locale,
    importFn: useCallback((locale) => import(`./translation/${locale}.json`), []),
  });

  const renderHeader = () => {
    if (!title) {
      return null;
    }

    return (
      <DialogHeader>
        <DialogTitle data-test-id="DialogTitle" id="dialogTitle">
          {title}
          {titleButtonComponent}
        </DialogTitle>
        {description && <DialogDescription id="dialogDescription">{description}</DialogDescription>}
      </DialogHeader>
    );
  };

  const renderBody = () => {
    if (!body) {
      return children;
    }
    return <DialogBody isFullBody={isFullBody}>{body}</DialogBody>;
  };

  // Include toasts in the focus trap container
  const portalFocusTrapElement = document.getElementById('portal_focus_trap');

  const handleWrapperClick = (e) => {
    if (shouldCloseOnOverlayClick && e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <DialogContainer className={className} data-test-id="Dialog">
      <DialogWrapper onClick={handleWrapperClick}>
        <FocusTrap
          focusTrapOptions={getFocusTrapOptions({ initialFocus, fallbackFocus: '[role="dialog"]' })}
          containerElements={portalFocusTrapElement ? [portalFocusTrapElement] : undefined}
        >
          <StyledDialog
            role="dialog"
            size={size}
            className={dialogClassName}
            styles={styles}
            aria-labelledby="dialogTitle"
            aria-describedby={description ? 'dialogDescription' : undefined}
            aria-busy={String(ariaBusy) === 'true' ? 'true' : 'false'}
            tabIndex={-1}
          >
            {renderHeader()}
            {renderBody()}
            <DialogHeaderInterfaceSection>
              {extra}
              {!hideCloseIconButton && (
                <DialogClose
                  icon={Icons.Close}
                  buttonType="secondary"
                  size="small"
                  onClick={onClose}
                  data-test-id="CloseButton"
                  aria-label={isLoading ? '' : t('close')}
                />
              )}
            </DialogHeaderInterfaceSection>
          </StyledDialog>
        </FocusTrap>
      </DialogWrapper>
    </DialogContainer>
  );
};

export const Dialog: FC<DialogProps> = (props) => {
  const { locale } = useIntl();
  return <LocalizedDialog {...props} locale={locale} />;
};
