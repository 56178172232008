import DeskApiAxios from './DeskApiAxios';

export const fetchTickets: FetchTicketsAPI = (pid, region = '', params) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('projects/tickets/', { params });
};

export const searchTickets: SearchTicketsAPI = (pid, region = '', params) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get('projects/ticket_search/', { params });
};

export const fetchTicket: FetchTicketAPI = (pid, region = '', { ticketId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`tickets/${ticketId}/`);
};

export const updateTicket: UpdateTicketAPI = (pid, region = '', { ticketId, params }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`tickets/${ticketId}`, params, {});
};

export const transferTicket: TransferTicketAPI = (pid, region = '', { payload }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post('transfers/', payload, {});
};

export const closeTicket: CloseTicketAPI = (pid, region = '', { ticketId, closeComment }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`tickets/${ticketId}/close/`, { closeComment });
};

export const forceAssignTicket: ForceAssignTicketAPI = (pid, region = '', { ticketId, agentId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`tickets/${ticketId}/force_assign/`, { agent: agentId });
};

export const fetchTicketsCounts: FetchTicketsCountsAPI = (pid, region = '') => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`projects/${pid}/ticket_counts/`);
};

export const addToWIP: AddToWIPAPI = (pid, region = '', { ticketId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`tickets/${ticketId}/add_to_wip/`, {});
};

export const assignTicket: AssignTicketAPI = (pid, region = '', { ticketId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`tickets/${ticketId}/assign/`, {});
};

export const reopenTicket: ReopenTicketAPI = (pid, region = '', { ticketId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`tickets/${ticketId}/reopen/`, {});
};

export const assignTicketToAgentGroup: AssignTicketToAgentGroupAPI = (pid, region = '', { ticketId, groupId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.patch(`tickets/${ticketId}/assign_group/`, { group_id: groupId });
};

export const addTag: AddTagAPI = (pid, region = '', { ticketId, tagId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post(`tickets/${ticketId}/add_tag/`, { tag: tagId }, { headers: { pid } });
};

export const removeTag: RemoveTagAPI = (pid, region = '', { ticketId, tagId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.delete(`tickets/${ticketId}/delete_tag/`, { data: { tag: tagId } });
};

export const deskCallsDial: DeskCallsDialAPI = (pid, region = '', { ticketId, callId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post(`tickets/${ticketId}/call_dial/`, { call_id: callId });
};

export const deskCallsEnd: DeskCallsEndAPI = (pid, region = '', { ticketId, callId }) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.post(`tickets/${ticketId}/call_end/`, { call_id: callId });
};
