import { useEffect } from 'react';

import * as Sentry from '@sentry/react';

import useAppId from '@hooks/useAppId';

const useSentryApplicationTracker = () => {
  const appId = useAppId();

  useEffect(() => {
    if (!appId) return;

    Sentry.setTag('appId', appId);

    return () => {
      Sentry.setTag('appId', null);
    };
  }, [appId]);
};

export default useSentryApplicationTracker;
