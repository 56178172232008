import { createReducer } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { ApplicationActions } from '@core/redux/actions/application';

const patchApplicationName = <T extends Application | ApplicationSummary>(
  application: T | null,
  params: { app_id: string; app_name: string },
) => {
  if (application?.app_id === params.app_id) {
    return { ...application, app_name: params.app_name };
  }
  return application;
};

interface ApplicationState {
  isFetching: boolean;
  /**
   * contains ApplicationSummary object of the currently being fetched application.
   */
  applicationSummary: ApplicationSummary | null;
  data: Application | null;
}

const initialState: ApplicationState = {
  isFetching: false,
  applicationSummary: null,
  data: null,
};

export const applicationStateReducer = createReducer<ApplicationState>(initialState, (builder) => {
  // ApplicationActions
  builder
    .addCase(ApplicationActions.fetchApplication.pending, (state, { meta }) => {
      state.isFetching = true;
      state.applicationSummary = meta.arg.applicationSummary || state.applicationSummary;
    })
    .addCase(ApplicationActions.applicationFetched, (state, { payload }) => {
      state.isFetching = false;
      state.data = payload;
    })
    .addCase(ApplicationActions.fetchApplication.rejected, (state) => {
      state.isFetching = false;
      state.applicationSummary = null;
    })
    .addCase(ApplicationActions.applicationSet, (state, { payload }) => {
      state.data = payload;
    })
    .addCase(ApplicationActions.applicationAttributesUpdated, (state, { payload }) => {
      if (state.data) {
        state.data.attrs = { ...state.data.attrs, ...payload };
      }
    })
    .addCase(ApplicationActions.applicationUpdated, (state, { payload }) => {
      if (state.data) {
        state.data = { ...state.data, ...payload };
      }
    })
    .addCase(ApplicationActions.changeAppName.fulfilled, (state, { payload }) => {
      const updates = {
        applicationSummary: patchApplicationName(state.applicationSummary, payload),
        data: patchApplicationName(state.data, payload),
      };

      if (updates.applicationSummary !== state.applicationSummary || updates.data !== state.data) {
        state.applicationSummary = patchApplicationName(state.applicationSummary, payload);
        state.data = patchApplicationName(state.data, payload);
      }
    })
    .addCase(ApplicationActions.applicationReset, () => initialState);

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
