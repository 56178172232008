// eslint-disable-next-line no-restricted-imports
import { createAsyncThunk as createReduxAsyncThunk } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '@/store';

const createAsyncThunk = createReduxAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export default createAsyncThunk;
