import { createReducer } from '@reduxjs/toolkit';

import type { DialogType } from '@common/containers/dialogs/DialogType';
import { AuthenticationActions } from '@common/redux/actions/authentication';
import { DialogsActions } from '@common/redux/actions/dialogs';

interface DialogsState {
  dialogTypes: DialogType | '';
  dialogProps: ShowDialogPayload | {};
  isFetching: boolean;
}

const initialState: DialogsState = {
  dialogTypes: '',
  dialogProps: {},
  isFetching: false,
};

export const dialogsReducer = createReducer<DialogsState>(initialState, (builder) => {
  // DialogsActions
  builder
    .addCase(DialogsActions.showDialog, (state, { payload }) => {
      state.dialogTypes = payload.dialogTypes;
      state.dialogProps = 'dialogProps' in payload ? payload.dialogProps : {};
      state.isFetching = false;
    })
    .addCase(DialogsActions.hideDialog, () => initialState)
    .addCase(DialogsActions.setIsFetching, (state, { payload }) => {
      state.isFetching = payload;
    });

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
