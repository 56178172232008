import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { ApplicationActions } from '@core/redux/actions/application';
import { SDKUserActions } from '@core/redux/actions/sdkUser';

interface SdkUserState {
  isFetched: boolean;
  user: SDKUser | null;
  fetchRequestError: any;
  updateRequest: {
    isPending: boolean;
    error: string | null;
  };
  createRequest: {
    isPending: boolean;
    error: string | null;
  };
  removeRequest: {
    isPending: boolean;
    error: string | null;
  };
}

const initialState: SdkUserState = {
  isFetched: false,
  user: null,
  fetchRequestError: null,
  updateRequest: { isPending: false, error: null },
  createRequest: { isPending: false, error: null },
  removeRequest: { isPending: false, error: null },
};

export const sdkUserReducer = createReducer<SdkUserState>(initialState, (builder) => {
  builder
    .addCase(SDKUserActions.fetchSDKUser.fulfilled, (state, { payload }) => {
      state.isFetched = true;
      state.user = payload;
      state.fetchRequestError = null;
    })
    .addCase(SDKUserActions.fetchSDKUser.rejected, (state, { payload }) => {
      state.isFetched = true;
      state.user = null;
      state.fetchRequestError = payload;
    })
    .addCase(SDKUserActions.createSDKUser.pending, (state) => {
      state.createRequest = { isPending: true, error: null };
    })
    .addCase(SDKUserActions.createSDKUser.fulfilled, (state, { payload }) => {
      state.isFetched = true;
      state.user = payload;
      state.createRequest = { isPending: false, error: null };
      state.fetchRequestError = null;
    })
    .addCase(SDKUserActions.createSDKUser.rejected, (state, { payload }) => {
      state.createRequest = { isPending: false, error: payload ?? null };
    })
    .addCase(SDKUserActions.updateSDKUser.pending, (state) => {
      state.updateRequest = { isPending: true, error: null };
    })
    .addCase(SDKUserActions.sdkUserUpdated, (state, { payload }) => {
      state.user = { ...state.user, ...payload };
      state.updateRequest = { isPending: false, error: null };
      state.fetchRequestError = null;
    })
    .addCase(SDKUserActions.updateSDKUser.rejected, (state, { payload }) => {
      state.updateRequest = { isPending: false, error: payload ?? null };
    })
    .addCase(SDKUserActions.removeSDKUser.pending, (state) => {
      state.removeRequest = { isPending: true, error: null };
    })
    .addCase(SDKUserActions.removeSDKUser.fulfilled, (state) => {
      state.isFetched = false;
      state.user = null;
      state.removeRequest = { isPending: false, error: null };
      state.fetchRequestError = null;
    })
    .addCase(SDKUserActions.removeSDKUser.rejected, (state, { payload }) => {
      state.removeRequest = { isPending: false, error: payload ?? null };
    });

  // Other actions
  builder
    .addCase(ApplicationActions.applicationCreated, (state) => {
      state.isFetched = false;
      state.user = null;
    })
    .addMatcher(
      isAnyOf(ApplicationActions.applicationReset, AuthenticationActions.unauthenticated),
      () => initialState,
    );
});
