import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import isMatch from 'lodash/isMatch';

import config from '@/config';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from '@/router';

const { SENTRY_TAGS_BRANCH, SENTRY_RELEASE, SENTRY_DIST, NODE_ENV, BUILD_MODE } = config;

const includesIgnoredFiles = (frame: { filename?: string }) => {
  const ignoredFiles = ['/gtm.js', '/bat.js', 'chrome-extension://'];
  return ignoredFiles.some((file) => frame.filename?.includes(file));
};

const isIncludingIgnoredFile = (event: Sentry.Event) => {
  const exceptions = event.exception?.values ?? [];

  return exceptions.some((exception) => {
    const frames = exception.stacktrace?.frames ?? [];
    return frames.some((frame) => includesIgnoredFiles(frame));
  });
};

const isIgnoredError = (event: Sentry.Event) => {
  return [
    /* SSO error from E2E tests */
    { extra: { response: { data: { message: 'Redirection canceled during E2E tests' } } } },
  ].some((target) => isMatch(event, target));
};

/** @knipignore */
export const eventProcessor = (event: Sentry.Event) => {
  try {
    // returning null will drop the event
    return isIncludingIgnoredFile(event) || isIgnoredError(event) ? null : event;
  } catch {
    return event;
  }
};

const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://048dab1986cf4d80a3a214d1ab5dca16@o31416.ingest.sentry.io/89426',
    integrations: [
      new Sentry.Integrations.Breadcrumbs({ console: true }),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    enabled: NODE_ENV === 'production', // set to `true` to capture exceptions in your local development environment
    environment: BUILD_MODE,
    release: SENTRY_RELEASE,
    dist: SENTRY_DIST,
    ignoreErrors: ['event_value should be a number', 'MktoForms2 is not defined', "Can't find variable: MktoForms2"],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // google analytics
      /www\.google-analytics\.com\/j\/collect/i,
      /stats\.g\.doubleclick\.net\/j\/collect/i,
      // trafficguard
      /api\.trafficguard\.ai/i,
      // clarity.me
      /a\.clarity\.ms\/collect/i,
      /b\.clarity\.ms\/collect/i,
      // hotjar
      /in\.hotjar\.com/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch') {
        return breadcrumb;
      }
      const thirdPartyBreadCrumbs =
        /(google-analytics|trafficguard|clarity|hotjar|pendo|doubleclick|heapanalytics|aptrinsic)/i;
      if (thirdPartyBreadCrumbs.test(breadcrumb.data?.url)) {
        return null;
      }
      return breadcrumb;
    },

    initialScope: (scope) => {
      scope.setTags({ branch: SENTRY_TAGS_BRANCH });
      scope.addEventProcessor(eventProcessor);
      return scope;
    },
    tracesSampleRate: 0.2,
  });
};

export default initializeSentry;
