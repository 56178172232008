import type { QueryFunction } from 'react-query';

import type { SubscriptionProduct } from '@constants';
import type QueryKeyPrefix from '@constants/queryKey';

import { fetchSubscriptionInfo } from './billing';
import { fetchApplicationMonthlyUsageWithOrgUsages } from './usage';

export const fetchApplicationMonthlyUsageWithOrgUsagesQueryFn: QueryFunction<
  ApplicationMonthlyUsageWithOrgUsage,
  [
    (typeof QueryKeyPrefix)['ApplicationMonthlyUsageWithOrgUsages'],
    { appId: string; target_month: string; fields?: string },
  ]
> = async ({ queryKey: [, { appId, ...params }] }) => {
  const { data } = await fetchApplicationMonthlyUsageWithOrgUsages(appId, params);
  return data.data;
};

export const fetchSubscriptionInfoQueryFn: QueryFunction<
  FetchSubscriptionInfoResponse<Subscription>,
  [(typeof QueryKeyPrefix)['OrgSubscriptionInfo'], { product: SubscriptionProduct }]
> = async ({ queryKey: [, { product }] }) => {
  const { data } = await fetchSubscriptionInfo(product);
  return data;
};

export const fetchSupportPlanSubscriptionInfoQueryFn: QueryFunction<
  FetchSubscriptionInfoResponse<SupportPlanSubscription>,
  [(typeof QueryKeyPrefix)['OrgSubscriptionInfo'], { product: SubscriptionProduct.Support }]
> = async ({ queryKey: [, { product }] }) => {
  const { data } = await fetchSubscriptionInfo(product);
  return data;
};
