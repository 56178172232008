import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { getDashboardAppRecentVersion } from '@common/api/app';
import { currentDashboardAppVersion, isProduction } from '@constants';
import QueryKeyPrefix from '@constants/queryKey';
import { toast } from '@feather/components/notification';

import useAppIntl from './useAppIntl';

const APP_UPDATE_NOTIFICATION_INTERVAL = 10 * 60 * 1000;

const useAppUpdateToast = (intervalMs: number = APP_UPDATE_NOTIFICATION_INTERVAL) => {
  const intl = useAppIntl();
  const [enabled, setEnabled] = useState(false);

  const { data: { version = null } = {} } = useQuery({
    queryKey: [QueryKeyPrefix.DashboardAppRecentVersion],
    queryFn: async () => {
      try {
        const { data } = await getDashboardAppRecentVersion();
        return data;
      } catch {
        return { version: currentDashboardAppVersion };
      }
    },
    enabled: enabled && isProduction,
    refetchInterval: intervalMs,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setEnabled(false);
    const timeout = setTimeout(() => {
      setEnabled(true);
    }, intervalMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [intervalMs]);

  useEffect(() => {
    const message = intl.formatMessage({ id: 'common.toast.appUpdateAvailable' });
    if (!message || !version || version === currentDashboardAppVersion) {
      return;
    }

    toast
      .info({
        message,
        actions: [
          {
            label: 'Refresh',
            onClick: () => {
              location.reload();
            },
          },
        ],
        autoHide: false,
      })
      .catch(() => {});
    setEnabled(false);
  }, [intl, version]);
};

export default useAppUpdateToast;
