import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { OrganizationsActions } from '@common/redux/actions/organizations';

const currentOrganizationSelector = createSelector(
  (state: ReadonlyArray<Organization>) => state,
  (organizations) => {
    return organizations.length > 0 ? organizations[0] : ({} as Organization);
  },
);

interface OrganizationsState {
  isFetching: boolean;
  updateOrganizationName: {
    isFetching: boolean;
    error: string | null;
  };
  updateOrganizationSlugName: {
    isFetching: boolean;
    error: string | null;
    timestamp?: number;
  };
  samlConfigurationForm: {
    isFetching: boolean;
    isSaving: boolean;
    error: string | null;
    lastUpdatedTimestamp?: number;
  };
  current: Organization;
}

const initialState: OrganizationsState = {
  isFetching: false,
  updateOrganizationName: {
    isFetching: false,
    error: null,
  },
  updateOrganizationSlugName: {
    isFetching: false,
    error: null,
  },
  samlConfigurationForm: {
    isFetching: false,
    isSaving: false,
    error: null,
  },
  current: {} as Organization,
};

export const organizationsReducer = createReducer<OrganizationsState>(initialState, (builder) => {
  // OrganizationsActions
  builder
    .addCase(OrganizationsActions.organizationCreated, (state, { payload }) => {
      state.current = currentOrganizationSelector(payload.organizations);
      state.isFetching = false;
    })
    .addCase(OrganizationsActions.organizationUpdated, (state, { payload }) => {
      state.isFetching = false;
      state.current = {
        ...state.current,
        ...payload,
      };
    })
    .addCase(OrganizationsActions.updateOrganizationName.pending, (state) => {
      state.updateOrganizationName = {
        isFetching: true,
        error: null,
      };
    })
    .addCase(OrganizationsActions.updateOrganizationName.fulfilled, (state) => {
      state.updateOrganizationName = {
        isFetching: false,
        error: null,
      };
    })
    .addCase(OrganizationsActions.updateOrganizationName.rejected, (state, { payload }) => {
      state.updateOrganizationName = {
        isFetching: false,
        error: payload ?? null,
      };
    })
    .addCase(OrganizationsActions.updateOrganizationSlugName.pending, (state) => {
      state.updateOrganizationSlugName.isFetching = true;
    })
    .addCase(OrganizationsActions.updateOrganizationSlugName.fulfilled, (state, { payload }) => {
      state.current = payload;
      state.updateOrganizationSlugName = {
        isFetching: false,
        error: null,
        timestamp: Date.now(),
      };
    })
    .addCase(OrganizationsActions.updateOrganizationSlugName.rejected, (state, { payload }) => {
      state.updateOrganizationSlugName = {
        isFetching: false,
        error: payload ?? null,
      };
    })
    .addCase(OrganizationsActions.updateSamlConfiguration.pending, (state) => {
      state.samlConfigurationForm.isFetching = false;
      state.samlConfigurationForm.isSaving = true;
    })
    .addCase(OrganizationsActions.updateSamlConfiguration.fulfilled, (state, { payload }) => {
      const { sso_entity_id, sso_idp_url, sso_idp_cert, sso_enforcing, sso_jit_provisioning, sso_default_role } =
        payload;
      state.current.sso_entity_id = sso_entity_id;
      state.current.sso_idp_url = sso_idp_url;
      state.current.sso_idp_cert = sso_idp_cert;
      state.current.sso_enforcing = sso_enforcing;
      state.current.sso_jit_provisioning = sso_jit_provisioning;
      state.current.sso_default_role = sso_default_role;
      state.samlConfigurationForm = {
        isFetching: false,
        isSaving: false,
        error: null,
        lastUpdatedTimestamp: Date.now(),
      };
    })
    .addCase(OrganizationsActions.updateSamlConfiguration.rejected, (state, { payload }) => {
      state.samlConfigurationForm = {
        isFetching: false,
        isSaving: false,
        error: payload ?? null,
      };
    })
    .addCase(OrganizationsActions.updateSamlConfigurationReset, (state) => {
      state.samlConfigurationForm = initialState.samlConfigurationForm;
    });

  // Other actions
  builder
    .addCase(AuthenticationActions.authenticated, (state, { payload }) => {
      state.current = currentOrganizationSelector(payload.organizations);
    })
    .addCase(AuthenticationActions.unauthenticated, () => initialState);
});
