import { createReducer } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { TicketDetailActions } from '@desk/redux/actions/ticketDetail';

interface TicketDetailState {
  isFetching: boolean;
  isFetchingMessages: boolean;
  ticket?: Ticket;
  messages: PlatformAPITicketMessage[];
  initialOrNextFetchedTimestamp?: number;
}

const initialState: TicketDetailState = {
  isFetching: false,
  isFetchingMessages: false,
  messages: [],
  ticket: undefined,
};

export const ticketDetailReducer = createReducer<TicketDetailState>(initialState, (builder) => {
  // TicketDetailActions
  builder
    .addCase(TicketDetailActions.fetchTicketDetailTicket.pending, (state, { payload }) => {
      state.isFetching = true;
      if (state.ticket && payload !== state.ticket.id) {
        state.messages = [];
      }
    })
    .addCase(TicketDetailActions.fetchTicketDetailHeader.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(TicketDetailActions.fetchTicketDetailTicket.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.ticket = payload;
    })
    .addCase(TicketDetailActions.fetchTicketDetailHeader.fulfilled, (state, { payload }) => {
      if (state.ticket) {
        state.isFetching = false;
        state.ticket.group = payload.group;
        state.ticket.recentAssignment = payload.recentAssignment;
        state.ticket.status = payload.status;
        state.ticket.status2 = payload.status2;
        state.ticket.closeStatus = payload.closeStatus;
      } else {
        return initialState;
      }
    })
    .addCase(TicketDetailActions.fetchTicketDetailMessages.pending, (state, { meta }) => {
      state.isFetchingMessages = meta.arg.types === 'initial';
    })
    .addCase(TicketDetailActions.fetchTicketDetailMessages.fulfilled, (state, { payload }) => {
      const { messages, initialOrNextFetchedTimestamp } = payload;
      state.isFetchingMessages = false;
      state.messages = messages || state.messages;
      if (initialOrNextFetchedTimestamp) {
        state.initialOrNextFetchedTimestamp = initialOrNextFetchedTimestamp;
      }
    })
    .addCase(TicketDetailActions.fetchTicketDetailMessages.rejected, (state) => {
      state.isFetchingMessages = false;
    })
    .addCase(TicketDetailActions.ticketDetailSendbirdAPIMessageUpdated, (state, { payload }) => {
      state.messages = state.messages.map((message: SendbirdChatPlatformAPI.AllMessageType) => {
        if (message.message_id === payload.message_id) {
          return payload;
        }
        return message;
      });
    })
    .addCase(TicketDetailActions.ticketDetailUpdated, (state, { payload }) => {
      if (state.ticket) {
        state.ticket = { ...state.ticket, ...payload };
      }
    })
    .addCase(TicketDetailActions.ticketDetailReset, () => initialState);

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
