import { createReducer } from '@reduxjs/toolkit';

import { AuthenticationActions } from '@common/redux/actions/authentication';
import { BillingActions } from '@common/redux/actions/billing';

interface BillingState {
  fetchingCardInfo: boolean;

  /**
   * null if it has never been fetched, {} (empty object) if there's no registered card
   */
  cardInfo: CreditCardInfo | {} | null;
}

const initialState: BillingState = {
  fetchingCardInfo: false,
  cardInfo: null,
};

export const billingReducer = createReducer<BillingState>(initialState, (builder) => {
  // BillingActions
  builder
    .addCase(BillingActions.fetchCardInfo.pending, (state) => {
      state.fetchingCardInfo = true;
    })
    .addCase(BillingActions.fetchCardInfo.fulfilled, (state, { payload }) => {
      state.cardInfo = payload;
      state.fetchingCardInfo = false;
    });

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
