import { axios, getGateURL } from '@api/shared';

export const createApplication: CreateApplicationAPI = (payload: {}) => {
  return axios.post(`${getGateURL()}/dashboard/applications/`, payload);
};

export const fetchAPITokens = ({ appId, role }: { appId: string; role?: ChatAPITokenRoles }) => {
  return axios.get<{ api_tokens: ChatAPIToken[] }>(`${getGateURL()}/platform/v3/applications/api_tokens`, {
    params: { role },
    headers: {
      'App-Id': appId,
    },
  });
};

export const createAPIToken = ({
  appId,
  password,
  role,
  label,
}: {
  appId: string;
  password?: string;
  role?: ChatAPITokenRoles;
  label?: string;
}) => {
  return axios.post<ChatAPIToken>(`${getGateURL()}/dashboard_api/applications/${appId}/secondary_api_token`, {
    password,
    role,
    label,
  });
};

export const revokeAPITokens = ({ appId, apiToken }) => {
  return axios.delete(`${getGateURL()}/platform/v3/applications/api_tokens/${apiToken}`, {
    headers: {
      'App-Id': appId,
    },
  });
};
export const getApplicationSummary = ({ app_id }: { app_id: string }) =>
  axios.get<{
    id: number;
    app_id: string;
    region: string;
    is_moderator_info_in_admin_message: boolean;
    is_calls_enabled: boolean;
  }>(`${getGateURL()}/dashboard/application/${app_id}/`, {
    headers: { 'App-Id': app_id },
  });

export const getApplication: GetApplicationAPI = ({ app_id }) =>
  axios.get(`${getGateURL()}/dashboard/applications/${app_id}/get_detail/`, {});

export const fetchEnabledFeatures: FetchEnabledFeaturesAPI = (app_id) => {
  return axios.get(`${getGateURL()}/dashboard_api/applications/${app_id}/enabled_features/`, {});
};

export const updateEnabledFeatures: UpdateEnabledFeaturesAPI = ({ app_id, payload }) => {
  return axios.put(`${getGateURL()}/dashboard_api/applications/${app_id}/enabled_features/`, payload, {});
};

/**
 * This API is used to display the status of the pipeline execution on Application Settings > Features.
 */
export const getMessageSearchPipeline: GetMessageSearchPipelineAPI = (app_id) => {
  return axios.get(`${getGateURL()}/message_search_api/migration_pipeline/`, {
    headers: { 'App-Id': app_id },
  });
};
