import { createReducer } from '@reduxjs/toolkit';

import { ChannelsActions } from '@chat/redux/actions/channels';
import { ModerationsActions } from '@chat/redux/actions/moderations';
import { AuthenticationActions } from '@common/redux/actions/authentication';
import { OpenChannelSearchOperator } from '@constants';

interface OpenChannelsModerationState {
  isFetchingChannel: boolean; // fetch one channel for current
  current: SendbirdChatChannel.OpenChannel | null;
}

interface OpenChannelsState extends OpenChannelsModerationState {
  channels: readonly SendbirdChatChannel.OpenChannel[];
  next: string;

  isFetching: boolean;
  isFetchingLoadMore: boolean;

  search: {
    option: OpenChannelSearchOperator;
    query: string;
    isSearching: boolean;
    isSuccess: boolean;
  };
}

const initialModerationState: OpenChannelsModerationState = {
  isFetchingChannel: true,
  current: null,
};

const initialState: OpenChannelsState = {
  channels: [],
  next: '',

  isFetching: true,
  isFetchingLoadMore: false,

  search: {
    option: OpenChannelSearchOperator.nameContains,
    query: '',
    isSearching: false,
    isSuccess: false,
  },

  // moderation
  ...initialModerationState,
};

export const openChannelsReducer = createReducer<OpenChannelsState>(initialState, (builder) => {
  // ChannelsActions
  builder
    .addCase(ChannelsActions.fetchOpenChannels.pending, (state, { meta }) => {
      if (meta.arg.init) {
        state.isFetching = true;
      } else {
        state.isFetchingLoadMore = true;
      }
    })
    .addCase(ChannelsActions.fetchOpenChannels.fulfilled, (state, { payload }) => {
      state.channels = payload.init ? payload.channels : state.channels.concat(payload.channels);
      state.next = payload.next;
      state.isFetching = false;
      state.isFetchingLoadMore = false;
    })
    .addCase(ChannelsActions.searchOpenChannels.pending, (state, { meta }) => {
      state.isFetching = true;
      state.search.query = meta.arg.query;
      state.search.option = meta.arg.option;
      state.search.isSearching = true;
      state.search.isSuccess = false;
    })
    .addCase(ChannelsActions.searchOpenChannels.fulfilled, (state, { payload }) => {
      state.channels = payload.init ? payload.channels : state.channels.concat(payload.channels);
      state.next = payload.next;
      state.isFetching = false;
      state.isFetchingLoadMore = false;
      state.search.query = payload.query;
      state.search.isSearching = false;
      state.search.isSuccess = true;
    })
    .addCase(ChannelsActions.setOpenChannelSearchOption, (state, { payload }) => {
      state.search.option = payload;
    })
    .addCase(ChannelsActions.setOpenChannelSearchQuery, (state, { payload }) => {
      state.search.query = payload;
      state.search.isSuccess = false;
    })
    .addCase(ChannelsActions.setOpenChannelSearchState, (state, { payload }) => {
      state.search.isSearching = payload;
    })
    .addCase(ChannelsActions.setOpenChannelSearchSuccess, (state, { payload }) => {
      state.search.isSearching = false;
      state.search.isSuccess = payload;
    })
    .addCase(ChannelsActions.setCurrentOpenChannel, (state, { payload }) => {
      state.isFetchingChannel = false;
      state.current = payload;
    })
    .addCase(ChannelsActions.updateOpenChannelInList, (state, { payload }) => {
      state.channels = state.channels.map((channel) => {
        if (payload && channel.channel_url === payload.channel_url) {
          return payload;
        }
        return channel;
      });
    })
    .addCase(ChannelsActions.deleteOpenChannelInList, (state, { payload }) => {
      state.channels = state.channels.filter((channel) =>
        payload.every((deleteTargetChannel) => channel.channel_url !== deleteTargetChannel.channel_url),
      );
    });

  // ModerationsActions
  builder
    .addCase(ModerationsActions.resetOpenChannels, () => initialState)
    .addCase(ModerationsActions.resetOpenChannelsModerationData, (state) => {
      return {
        ...state,
        ...initialModerationState,
      };
    });

  // Other actions
  builder.addCase(AuthenticationActions.unauthenticated, () => initialState);
});
