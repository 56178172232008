import type { ReactNode } from 'react';
import { Children, useCallback, useMemo } from 'react';

import type { IntlShape } from '@formatjs/intl';
import { createIntl } from '@formatjs/intl';
import type { FormatXMLElementFn } from 'intl-messageformat';
import type { PrimitiveType } from 'react-intl';
import { useIntl } from 'react-intl';

import type { TranslationProps } from './useTranslation';

interface TFunction<K> {
  (key: K): string;
  (key: K, values: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string;
  (key: K, values: Record<string, PrimitiveType | ReactNode | FormatXMLElementFn<ReactNode, ReactNode>>): ReactNode;
}

const useTranslationSync = <
  M extends Record<string, string> = Record<string, string>,
  K extends string = keyof M extends string ? keyof M : string,
>(
  getMessages: (locale: string) => M,
) => {
  const { locale } = useIntl();

  const intl = useMemo(() => {
    const translatedMessages = getMessages(locale);
    // Beware that this may impact the performance if there are many instances of the component that calls this hook rendered at the same time.
    return createIntl<ReactNode>({ locale, messages: translatedMessages });
  }, [getMessages, locale]);

  return {
    t: useCallback(
      (key: K, values?: Parameters<IntlShape['formatMessage']>[1]) => {
        if (!values) {
          return intl.formatMessage({ id: key }) as string;
        }

        const result = intl.formatMessage({ id: key }, values);
        return Array.isArray(result) ? Children.toArray(result) : (result as string);
      },
      [intl],
    ) as TFunction<K>,
    intl,
  };
};

export default useTranslationSync;
export type { TranslationProps };
