import { useAuth } from '@/redux/hooks/auth';
import type { RootState } from '@/store';

type LocalState = RootState['auth']['user'];
type LocalSelector<T> = (state: LocalState) => T;

function useUser(): LocalState;
function useUser<T>(selector: LocalSelector<T>): T;

function useUser<T>(selector?: LocalSelector<T>) {
  return useAuth((state) => (selector ? selector(state.user) : state.user));
}

export default useUser;
