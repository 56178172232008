export const ZIndexes = {
  coachMark: 999998,
  tooltipOverNavigation: 12000,
  callsWidget: 12000,
  navigation: 11000,
  portal: 9999,
  previewBackground: 12000,
  timePickerPanel: 9999,
  stickyHeader: 9999,
  drawer: 8000,
  drawerBackground: 7000,
  chartHoverTooltip: 99999,
  sidebar: 900,
};
