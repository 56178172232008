export enum FeatureType {
  Core = 'Core',
  Premium = 'Premium',
  // Support = 'Support',
  // EAP = 'EAP',
}

// key is dynamically generated like `FeatureTypeLozengeColors[feature.type]`
export enum FeatureTypeLozengeColors {
  /** @knipignore */
  Core = 'neutral',
  /** @knipignore */
  Premium = 'purple',
  /** @knipignore */
  Support = 'orange',
  /** @knipignore */
  EAP = 'green',
}

export enum ChatFeatureName {
  MonthlyActiveUser = 'monthly_active_user',
  PeakConnection = 'peak_connection',
  FileStorage = 'file_storage',
  UploadTraffic = 'upload_traffic',
  BotInterface = 'bot_interface',
  Webhook = 'webhook',
  AutoThumbnail = 'auto_thumbnail',
  TranslationTools = 'translation_tools',
  ImageModeration = 'image_moderation',
  AdvancedModeration = 'advanced_moderation',
  HiveIntegration = 'hive_integration',
  ModeratorMessages = 'moderator_messages',
  DomainFilter = 'domain_filter',
  ProfanityFilter = 'profanity_filter',
  DataExport = 'data_export',
  ModerationTools = 'moderation_tools',
  DeliveryReceipt = 'delivery_receipt',
  Announcement = 'announcement',
  Supergroup = 'supergroup',
  AdvancedAnalytics = 'advanced_analytics',
  MessageSearch = 'message_search',
  DisappearingMessages = 'disappearing_messages',
  TextModeration = 'text_moderation',
  Polls = 'poll',
  AiEngine = 'ai_engine',
}

export const LegacyPremiumFeatureMap = {
  bot_interface: ChatFeatureName.BotInterface,
  auto_thumbnail: ChatFeatureName.AutoThumbnail,
  moderation_group: ChatFeatureName.ModerationTools,
  data_export: ChatFeatureName.DataExport,
  moderation_open: ChatFeatureName.ModerationTools,
  auto_trans: ChatFeatureName.TranslationTools,
  delivery_receipt: ChatFeatureName.DeliveryReceipt,
  announcement: ChatFeatureName.Announcement,
  analytics: ChatFeatureName.AdvancedAnalytics,
  text_moderation: ChatFeatureName.TextModeration,
  auto_partitioning: 'auto_partitioning',
  spam_flood_protection: 'spam_flood_protection',
  desk: 'desk',

  message_search: 'message_search',
} as const;

export const OnlyUsageBarFeatures: ChatFeatureName[] = [
  ChatFeatureName.BotInterface,
  ChatFeatureName.FileStorage,
  ChatFeatureName.MessageSearch,
];

export const LineChartOnlyFeatures: string[] = [ChatFeatureName.MonthlyActiveUser];
export const GigabyteUsageFeatures: string[] = [
  'avg_file_storage',
  ChatFeatureName.FileStorage,
  ChatFeatureName.UploadTraffic,
];

export const ModerationFeatureList: ChatFeature[] = [
  {
    key: ChatFeatureName.ImageModeration,
    plans: [
      {
        planKey: 'image_moderation',
        usageField: 'image_moderation',
      },
    ],
    type: FeatureType.Premium,
    trackable: true,
  },
  {
    key: ChatFeatureName.AdvancedModeration,
    plans: [
      {
        planKey: 'advanced_moderator_seat',
        usageField: 'advanced_moderator_seat',
      },
      {
        planKey: 'moderation_rule_creation_limit',
        usageField: 'moderation_rule_creation_limit',
      },
      {
        planKey: 'moderation_rule_triggered_event',
        usageField: 'moderation_rule_triggered_event',
      },
    ],
    type: FeatureType.Premium,
    trackable: true,
  },
  {
    key: ChatFeatureName.HiveIntegration,
    plans: [
      {
        planKey: 'hive_integration',
        usageField: 'hive_integration',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.ModeratorMessages,
    plans: [
      {
        planKey: 'moderator_messages',
        usageField: 'moderator_messages',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
];

export const ChatFeatureList: ChatFeature[] = [
  {
    key: ChatFeatureName.MonthlyActiveUser,
    plans: [
      {
        planKey: 'mau',
        usageField: 'mau',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
  {
    key: ChatFeatureName.PeakConnection,
    plans: [
      {
        planKey: 'pc',
        usageField: 'pc',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
  {
    key: ChatFeatureName.FileStorage,
    plans: [
      {
        planKey: 'file_storage',
        usageField: 'avg_file_storage',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
  {
    key: ChatFeatureName.UploadTraffic,
    plans: [
      {
        planKey: 'upload_traffic',
        usageField: 'upload_traffic',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
  {
    key: ChatFeatureName.BotInterface,
    plans: [
      {
        planKey: 'bot_interface',
        usageField: 'avg_bot_interface',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
  {
    key: ChatFeatureName.Webhook,
    plans: [
      {
        planKey: 'webhook',
        usageField: 'webhook',
      },
    ],
    type: FeatureType.Core,
    trackable: false,
  },
  {
    key: ChatFeatureName.AutoThumbnail,
    plans: [
      {
        planKey: 'auto_thumbnail',
        usageField: 'auto_thumbnail',
      },
    ],
    type: FeatureType.Premium,
    trackable: true,
  },
  {
    key: ChatFeatureName.TranslationTools,
    plans: [
      {
        planKey: 'translation_tools',
        usageField: 'auto_translation',
      },
    ],
    type: FeatureType.Premium,
    trackable: true,
  },
  {
    key: ChatFeatureName.DomainFilter,
    plans: [
      {
        planKey: 'domain_filter',
        usageField: 'domain_filter',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.ProfanityFilter,
    plans: [
      {
        planKey: 'profanity_filter',
        usageField: 'profanity_filter',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.DataExport,
    plans: [
      {
        planKey: 'data_export',
        usageField: 'data_export',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.ModerationTools,
    plans: [
      {
        planKey: 'moderation_tools',
        usageField: 'moderation_tools',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.DeliveryReceipt,
    plans: [
      {
        planKey: 'delivery_receipt',
        usageField: 'delivery_receipt',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.Announcement,
    plans: [
      {
        planKey: 'announcement',
        usageField: 'announcement_sent_user_count',
      },
    ],
    type: FeatureType.Premium,
    trackable: true,
  },
  {
    key: ChatFeatureName.Supergroup,
    plans: [
      {
        planKey: 'supergroup',
        usageField: 'supergroup',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.Polls,
    plans: [
      {
        planKey: 'poll',
        usageField: 'poll',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.AdvancedAnalytics,
    plans: [
      {
        planKey: 'advanced_analytics',
        usageField: 'advanced_analytics',
      },
    ],
    type: FeatureType.Premium,
    trackable: false,
  },
  {
    key: ChatFeatureName.MessageSearch,
    plans: [
      {
        planKey: 'message_search_index',
        usageField: 'message_search_index_total',
      },
      {
        planKey: 'message_search_query',
        usageField: 'message_search_query_total',
      },
    ],
    type: FeatureType.Premium,
    trackable: true,
  },
];

export const AiFeaturesList: ChatFeature[] = [
  {
    key: ChatFeatureName.AiEngine,
    plans: [
      {
        planKey: 'ai_engine',
        usageField: 'ai_engine',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
  {
    key: ChatFeatureName.MonthlyActiveUser,
    plans: [
      {
        planKey: 'mau',
        usageField: 'mau',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
  {
    key: ChatFeatureName.PeakConnection,
    plans: [
      {
        planKey: 'pc',
        usageField: 'pc',
      },
    ],
    type: FeatureType.Core,
    trackable: true,
  },
];

export const AllFeaturesList = [
  ...ChatFeatureList.concat(AiFeaturesList)
    .concat(ModerationFeatureList)
    .reduce(
      (previous, current) => previous.set(current.key, Object.assign(previous.get(current.key) || {}, current)),
      new Map(),
    )
    .values(),
];

export type AllPremiumFeatures =
  | Omit<
      keyof PremiumFeatures,
      'announcement_price' | 'auto_trans_basic' | 'auto_trans_premium' | 'auto_partitioning' | 'spam_flood_protection'
    >
  | Omit<keyof EnabledFeatures, 'message_search_v3'>
  | 'advanced_analytics'
  | 'message_search';

export const CardBrandsNew = ['amex', 'diners', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa', 'unknown'];
export const CardBrands = CardBrandsNew.concat([
  'Visa',
  'American Express',
  'MasterCard',
  'Discover',
  'JCB',
  'Diners Club',
  'Unknown',
]);

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Archived = 'ARCHIVED',
  // Deleted = 'DELETED',
}

export enum FullScreenModalIDs {
  CallsVoucher = 'callsVoucher',
  ChannelDetailFocusMode = 'channelDetailFocusMode',
}

export const PASSWORD_VALIDATION_REGEX = '^[a-zA-Z0-9-+(){|}\\]\\[\\\\<=>/_~\'":;`!@#$%^&*.,?]*$';
export const NAME_VALIDATION_REGEX = /[+/<>="]/gi;

export const REGION_STATUS_PAGES = {
  'ap-1': 'https://status-tokyo.sendbird.com',
  'ap-2': 'https://status-seoul.sendbird.com',
  'ap-5': 'https://status-singapore.sendbird.com',
  'ap-8': 'https://status-mumbai.sendbird.com',
  'ap-9': 'https://status-sydney.sendbird.com',
  'eu-1': 'https://status-frankfurt.sendbird.com',
  'us-1': 'https://status-oregon.sendbird.com',
  'us-2': 'https://status-nvirginia1.sendbird.com',
  'us-3': 'https://status-nvirginia2.sendbird.com',
  'ca-1': 'https://status-canada.sendbird.com/',
};

// space for the easy ui formatting
export const USAGE_GB_UNIT = ' GB';

export const SIGN_OUT_TYPE_TIMEOUT = 'timeOut';

export enum FeatureAvailability {
  FeatureOff = 'feature_off',
  NotSupportedPlan = 'not_supported_plan',
}
