import type { ComponentType, FC, ReactNode } from 'react';
import { Children, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { IntlShape } from '@formatjs/intl';
import { createIntl } from '@formatjs/intl';
import type { FormatXMLElementFn } from 'intl-messageformat';
import type { PrimitiveType } from 'react-intl';
import { useIntl } from 'react-intl';

export interface TFunction<K> {
  (key: K): string;
  (key: K, values: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string;
  (key: K, values: Record<string, PrimitiveType | ReactNode | FormatXMLElementFn<ReactNode, ReactNode>>): ReactNode;
}

export type TranslationProps<K> = { t: TFunction<K>; intl: IntlShape<ReactNode> };

type ImportFunction = (locale: string) => Promise<any>;

export function useTranslationOfLocale<K extends string>({
  locale,
  importFn,
}: {
  locale: string;
  importFn: ImportFunction;
}): TranslationProps<K> & { isLoading: boolean } {
  const [translation, setTranslation] = useState<{ isLoading: boolean; data?: Record<string, string> }>({
    isLoading: true,
  });

  const isUnmountedRef = useRef(false);

  useEffect(() => {
    // TODO: check required
    isUnmountedRef.current = false;
    return () => {
      isUnmountedRef.current = true;
    };
  }, []);

  useEffect(() => {
    const loadTranslation = async () => {
      try {
        setTranslation({ isLoading: true });
        const data = (await importFn(locale)).default;

        if (!isUnmountedRef.current) {
          setTranslation({ isLoading: false, data });
        }
      } catch (error) {
        if (!isUnmountedRef.current) {
          setTranslation((state) => ({ ...state, isLoading: false }));
        }
      }
    };
    loadTranslation();
  }, [importFn, locale]);

  const intl = useMemo(() => {
    // Beware that this may impact the performance if there are many instances of the component that calls this hook rendered at the same time.
    return createIntl<ReactNode>({ locale, messages: translation.data });
  }, [locale, translation.data]);

  return {
    isLoading: translation.isLoading,
    t: useCallback(
      (key, values) => {
        if (!values) {
          return intl.formatMessage({ id: key }) as string;
        }

        const result = intl.formatMessage({ id: key }, values);
        return Array.isArray(result) ? Children.toArray(result) : (result as string);
      },
      [intl],
    ) as TFunction<K>,
    intl,
  };
}

function useTranslation<K extends string>(importFn: ImportFunction) {
  const { locale } = useIntl();
  return useTranslationOfLocale<K>({ locale, importFn });
}

export function withTranslation<K extends string, P = {}>(
  Component: ComponentType<P & TranslationProps<K>>,
  importFn: Parameters<typeof useTranslation>[0],
) {
  const Translated: FC<P> = (props) => {
    const { isLoading, t, intl } = useTranslation<K>(importFn);

    if (isLoading) {
      return null;
    }
    return <Component {...props} t={t} intl={intl} />;
  };
  Translated.displayName = 'Translated';

  return Translated;
}

export default useTranslation;
