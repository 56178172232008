import { useEffect } from 'react';

import type { CalendarDate } from '@internationalized/date';
import qs from 'qs';

import { fetchOrganizationMonthlyUsage } from '@common/api/usage';
import { getCalendarDateYearMonthString } from '@utils/InternationalizedDate';

import useAsync from './useAsync';
import useOrganization from './useOrganization';

const useMonthlyUsage = (date: CalendarDate) => {
  const { uid } = useOrganization();
  const [{ status, data }, load] = useAsync(async () => {
    if (uid) {
      const params = qs.stringify({
        start_month: getCalendarDateYearMonthString(date),
        end_month: getCalendarDateYearMonthString(date),
      });
      return fetchOrganizationMonthlyUsage(uid, params);
    }
  }, [date, uid]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    isLoadingUsage: status === 'loading',
    monthlyUsage: data?.data?.data[0],
  };
};

export default useMonthlyUsage;
