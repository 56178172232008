import { createAction } from '@reduxjs/toolkit';
import is from '@sindresorhus/is';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { DialogsActions } from '@common/redux/actions/dialogs';
import { createSDKUser, fetchSDKUser, unregisterSDKUser, updateSDKUser } from '@core/api/sdkUser';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import getErrorMessage from '@utils/getErrorMessage';
import logException from '@utils/logException';

export const SDKUserActions = {
  fetchSDKUser: createAsyncThunk<SDKUser | null>(
    'core/sdkUser/fetchSDKUser',
    async (_, { getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;

      try {
        const { data } = await fetchSDKUser({ appId });

        return data.sdk_user;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return rejectWithValue(getErrorMessage(error));
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  createSDKUser: createAsyncThunk<
    SDKUser,
    {
      data: {
        sdk_user_id?: string;
        nickname: string;
        profile_url?: string;
        profile_file?: File | null;
        issue_access_token: boolean;
      };
    },
    { rejectValue: string | null }
  >('core/sdkUser/createSDKUser', async (payload, { dispatch, getState, rejectWithValue }) => {
    const appId = selectApplicationData(getState()).app_id;

    try {
      const { data } = await createSDKUser({ appId, data: payload.data });

      dispatch(DialogsActions.hideDialog());
      return data;
    } catch (error) {
      if (!error) {
        return rejectWithValue(getErrorMessage(error));
      }

      if (axios.isAxiosError(error)) {
        const { status, data } = error.response ?? {};
        const { code, message } = (data ?? {}) as { code: string; message: string };

        if (status === 403) {
          return rejectWithValue(
            window.intl.formatMessage({ id: 'chat.moderation.createModeratorDialog.error.permissionDenied' }),
          );
        }
        if (status === 400 && code === 'gate400102' && is.string(message)) {
          return rejectWithValue(
            window.intl.formatMessage(
              { id: 'chat.moderation.createModeratorDialog.error.withReason' },
              { reason: message },
            ),
          );
        }
        if (status === 400 || status === 500) {
          return rejectWithValue(
            window.intl.formatMessage({ id: 'chat.moderation.createModeratorDialog.error.general' }),
          );
        }

        return rejectWithValue(getErrorMessage(error));
      }

      logException(error);
      return rejectWithValue(getErrorMessage(error));
    }
  }),

  updateSDKUser: createAsyncThunk<void, Omit<UpdateSDKUserParams, 'appId'>, { rejectValue: string | null }>(
    'core/sdkUser/updateSDKUser',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const appId = selectApplicationData(getState()).app_id;

      try {
        const { data } = await updateSDKUser({ appId, userId: payload.userId, data: payload.data });

        dispatch(DialogsActions.hideDialog());
        dispatch(SDKUserActions.sdkUserUpdated(data));
      } catch (error) {
        logException(error);
        return rejectWithValue(getErrorMessage(error));
      }
    },
  ),
  sdkUserUpdated: createAction<SDKUser>('core/sdkUser/sdkUserUpdated'),

  removeSDKUser: createAsyncThunk<void, { appId: string; userId: string }, { rejectValue: string | null }>(
    'core/sdkUser/removeSDKUser',
    async (payload, { rejectWithValue }) => {
      try {
        await unregisterSDKUser(payload);
      } catch (error) {
        logException(error);
        return rejectWithValue(getErrorMessage(error));
      }
    },
  ),
};
