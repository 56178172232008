import { css } from 'styled-components';

const reactDomContainerStyles = css`
  // React DOM container styles
  body {
    position: relative;
  }

  #root {
    display: flex;
    flex-direction: column;

    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  #portal_popup {
    position: absolute;
    top: 0%;
    left: 0;
    width: 0;
    top: 0;
    overflow: visible;
    z-index: 80;
  }
`;

export default reactDomContainerStyles;
