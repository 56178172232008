import type { RootState } from '@/store';

import { useShallowEqualSelector } from '.';

type LocalState = RootState['dialogs'];
type LocalSelector<T> = (state: LocalState) => T;

export function useDialogs(): LocalState;
export function useDialogs<T>(selector: LocalSelector<T>): T;

export function useDialogs<T>(selector?: LocalSelector<T>) {
  return useShallowEqualSelector<T | LocalState>((state) =>
    selector ? selector(state.dialogs) : (state.dialogs as LocalState),
  );
}
